import DashboardLayout from '../layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../pages/NotFoundPage.vue'

// Admin pages
import UserProfile from 'src/pages/UserProfile.vue'
import Typography from 'src/pages/Typography.vue'
import Icons from 'src/pages/Icons.vue'
import Maps from 'src/pages/Maps.vue'
import Notifications from 'src/pages/Notifications.vue'
import Upgrade from 'src/pages/Upgrade.vue'
import Subscription from 'src/pages/Subscription'
import Login from 'src/pages/Login'

// Admin pages : create owner
import CreateOwner from 'src/components/OwnerManagement/CreateOwner.vue'
import OwnerList from 'src/components/OwnerManagement/OwnerList.vue'
import MenuManagement from 'src/components/MenuManagement/MenuManagement.vue'
import ContentManagement from 'src/components/MenuManagement/ContentManagement.vue'
import DealSet from 'src/components/MenuManagement/DealSet'
import HookItem from 'src/components/MenuManagement/HookItem'

//Report
import Report from '@/pages/Report/Index'
import RestaurantReport from "@/pages/Report/RestaurantReport";

//Booking
import Booking from "@/pages/Booking/Index";
import CreateBooking from "@/pages/Booking/Create"

const routes = [
  {
    path: '/',
    component: Login,
    redirect: '/login'
  },
  {
    path: '/login',
    component: Login,
  },
  // {
  //   path: '/',
  //   component: DashboardLayout,
  //   redirect: '/admin/report'
  // },
  {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/overview',
    children: [
      {
        path: 'create-owner',
        name: 'CreateOwner',
        component: CreateOwner
      },
      {
        path: 'owner-list',
        name: 'OwnerList',
        component: OwnerList
      },
      {
        path: 'menu-management',
        name: 'MenuManagement',
        component: MenuManagement
      },
      {
        path: 'content-management',
        name: 'ContentManagement',
        component: ContentManagement
      },
      {
        path: 'deal-set',
        name: 'DealSet',
        component: DealSet
      },
      {
        path: 'hook-items',
        name: 'HookItem',
        component: HookItem
      },
      {
        path: 'user',
        name: 'User',
        component: UserProfile
      },
      {
        path: 'subscription',
        name: 'Subscription',
        component: Subscription
      },
      {
        path: 'report',
        name: 'Report',
        component: Report
      },
      {
        path: 'report/restaurant/:id',
        name: 'RestaurantReport',
        component: RestaurantReport
      },
      {
        path: 'booking',
        name: 'Booking',
        component: Booking
      },
      {
        path: 'booking/create',
        name: 'CreateBooking',
        component: CreateBooking
      }
    ]
  },
  { path: '*', component: NotFound }
]

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes
