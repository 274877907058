<template>
    <div class="content">
        <div class="container" style="padding-left: 10px">

            <div class="row" style="margin-bottom: 10% !important;" v-if="!isToppingsClicked && !isModifierClicked && !isPrintOptionClicked && !isFixedMealDeal && !isCustomMealDealSetItem && !isCustomMealDealSet">
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-header" style="background-color: #555555; color: white; padding: 15px;">
                            <span>Category</span>
                            <span v-bind:class="['dropdown', {'show': categoryOption}]" style="float: right; cursor: pointer" @click="categoryClick()">
                                <i class="fa fa-ellipsis-v"></i>
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                                    <a class="dropdown-item" href="#" @click="addNewCategory()">Add Category</a>
                                    <!-- <a class="dropdown-item" href="#" @click="addNewItem()">Add Item</a> -->
                                    <a class="dropdown-item" href="#">Delete All Category</a>
                                </div>
                            </span>
                        </div>
                        <ul class="list-group list-group-flush" style="overflow: auto; height: 50%;">
                            <li class="list-group-item" style="cursor: pointer;" :id="'categoryChoosenId'+index" v-for="(item, index) in categoryDataList" @click="chooseCategory(item.id, index)">
                                <span>
                                    <span> {{item.label}} </span>
                                    <i class="fa fa-chevron-down" style="float: right; color: black; display: block;" :id="'categoryHide'+index" @click="categoryListShow(index)"></i>
                                    <i class="fa fa-chevron-up" style="float: right; color: black; display: none;" :id="'categoryShow'+index" @click="categoryListShow(index)"></i>

                                    <div style="text-align: center; background-color: #FFF6EE; padding: 11px; display: none;" :id="'categoryOptions'+index">
                                        <span style="padding-right: 5px;"> <button type="button" class="btn-sm editBtn" @click="editCategory(item.id, item.label, item.description, 0, 0, 0, item.mealAssignFor, item.printOptionId, item.mealAssignFor, item.status)"><i class="fa fa-edit"></i> Edit</button> </span>
                                        <span> <button type="button" class="btn-sm deleteBtn" @click="deleteCategory(item.id)"><i class="fa fa-trash" ></i> Delete</button> </span>
                                    </div>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card-header" style="background-color: #555555; color: white; padding: 15px;">
                        <span>Items</span>
                        <span v-bind:class="['dropdown', {'show': itemOption}]" style="float: right; cursor: pointer" @click="itemClick()">
                            <i class="fa fa-ellipsis-v"></i>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                                <!-- <a class="dropdown-item" @click="addMealDeal()">Add Meal Deal</a> -->
                                <a class="dropdown-item" href="#" @click="addNewItem()">Add Item</a>
                                <a class="dropdown-item" @click="addNewFixedMealDeal()">Add Fixed Meal Deal</a>
                                <a class="dropdown-item" @click="addNewCustomMealDeal()">Add Custom Meal Deal</a>
                                <a class="dropdown-item" href="#">View Section List</a>
                                <a class="dropdown-item" href="#" @click="addNewSelection()">Add Selection With Price</a>
                                <a class="dropdown-item" href="#">Delete All Items</a>
                            </div>
                        </span>
                    </div>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item item-list" style="cursor: pointer;padding: unset" :id="'itemChoosenId'+index" v-for="(item, index) in selectedItemsAccordingToCategory">
                          <div class="row mx-0">
                              <div class="col-md-10 px-4" style="padding: 10px" @click="ChooseItem(item.id, index, item.dataType, item.mealDealType)">
                                <span> {{item.label}} </span>
                                <div style="text-align: center; background-color: #FFF6EE; padding: 11px; display: none;" :id="'itemOptions'+index">
                                  <span style="padding-right: 5px;"> <button type="button" class="btn-sm editBtn"
                                   @click="editItems(item.id, item.label, item.description, item.basePrice, item.priceDifference, item.inPrice, item.outPrice, item.categoryId, 0, item.mealDealType, item.terminalOption, item.printOptionId, item.mealAssignFor, item.status)">
                                   <i class="fa fa-edit"></i> Edit</button>
                                  </span>
                                  <span> <button type="button" class="btn-sm deleteBtn" @click="deleteItem(item.id)"><i class="fa fa-trash"></i> Delete</button> </span>
                                </div>
                              </div>
                              <div class="col-md-2 pt-2 pr-2">
                                  <i class="fa fa-chevron-down w-100 h-100" style="float: right; color: black; display: block;" :id="'itemHide'+index" @click="itemListShow(index)"></i>
                                  <i class="fa fa-chevron-up w-100 h-100" style="float: right; color: black; display: none;" :id="'itemShow'+index" @click="itemListShow(index)"></i>
                              </div>
                          </div>
                        </li>
                    </ul>
                </div>
                <div class="col-md-4">
                    <div class="card-header" style="background-color: #555555; color: white; padding: 15px;">
                        <span>Selections</span>
                    </div>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item" style="cursor: pointer;" v-for="(item, index) in selectedSelectionAccordingToItem">
                            <span>
                                {{item.label}}
                                <i class="fa fa-chevron-down" style="float: right; color: black; display: block;" :id="'selectionHide'+index"  @click="selectionListShow(index)"></i>
                                <i class="fa fa-chevron-up" style="float: right; color: black; display: none;" :id="'selectionShow'+index"  @click="selectionListShow(index)"></i>

                                <div style="text-align: center; background-color: #FFF6EE; padding: 11px; display: none;" :id="'selectionOption'+index">
                                    <span style="padding-right: 5px;"> <button type="button" class="btn-sm editBtn" @click="editSelections(item.id, item.label, item.description, item.basePrice, item.priceDifference, item.inPrice, item.outPrice, item.categoryId, item.itemId, item.terminalOption, item.printOptionId, item.mealAssignFor, item.status)"><i class="fa fa-edit"></i> Edit</button> </span>
                                    <span> <button type="button" class="btn-sm deleteBtn" @click="deleteSelection(item.id)"><i class="fa fa-trash"></i> Delete</button> </span>
                                </div>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>

            <MenuManagementModal
            ref="MenuManagementModal"
            v-bind:modalTitle="modalTitle"
            v-bind:isCategoryModal="isCategoryModal"
            v-bind:isItemModal="isItemModal"
            v-bind:isSelectionModal="isSelectionModal"
            v-bind:isToppingsModal="isToppingsModal"
            v-bind:categoryDataList="categoryDataList"
            v-bind:itemDataList="itemDataList"
            v-bind:printOptionDataList="printOptionDataList"
            @toppingsSubmitted="toppingsSubmitted"
            @categoryAndItem="categoryList"
            />

            <MenuToppings ref="MenuToppings" v-if="isToppingsClicked"  @toppingsEvent="addNewToppings" @editToppingsEvent="editToppings" />

            <MenuModifier v-if="isModifierClicked" />

            <MenuPrintOption v-if="isPrintOptionClicked" />

            <FixedMealDeal v-if="isFixedMealDeal"
            ref="FixedMealDeal"
            v-bind:categoryId="selectedCategoryId"
            v-bind:itemId="selectedItemId"
            v-bind:itemLabel="itemLabel"
            v-bind:itemDataList="itemDataList"
            />

            <CustomMealDealSetItem v-if="isCustomMealDealSetItem"
            v-bind:itemDataList="itemDataList"
            v-bind:itemId="selectedItemId"
            v-bind:categoryId="selectedCategoryId"
            @checkEditClickForSetItem="checkEditClickForSetItem"
            />

            <CustomMealDealSet v-if="isCustomMealDealSet"
            v-bind:categoryId="selectedCategoryId"
            v-bind:itemId="selectedItemId"
            v-bind:itemLabel="itemLabel"
            v-bind:itemDataList="itemDataList"
            v-bind:idOfSetItem="idOfSetItem"
            v-bind:labelOfSetItem="labelOfSetItem"
            @showSetList="showSetList"
            />



            <div class="row bottomOption">
                <div class="col-md-3" style="text-align: center;">
                    <div class="row" style="background: #FFFFFF; padding-top: 18px; padding-bottom: 18px; margin-left: 1px;">
                        <div class="col-md-12">
                            <button type="button" class="btn menuMgmtBtn" @click="showMenuDiv()">Menu</button>
                        </div>
                    </div>

                </div>
                <div class="col-md-3" style="text-align: center;">
                    <div class="row" style="background: #FFFFFF; padding-top: 18px; padding-bottom: 18px; margin-left: 1px;">
                        <div class="col-md-12">
                            <button type="button" class="btn menuMgmtBtn" @click="showToppingsDiv()">Toppings</button>
                        </div>
                    </div>

                </div>
                <div class="col-md-3" style="text-align: center;">
                    <div class="row" style="background: #FFFFFF; padding-top: 18px; padding-bottom: 18px; margin-left: 1px;">
                        <div class="col-md-12">
                            <button type="button" class="btn menuMgmtBtn" @click="showModifierDiv()">Modifier</button>
                        </div>
                    </div>

                </div>
                <div class="col-md-3" style="text-align: center;">
                    <div class="row" style="background: #FFFFFF; padding-top: 18px; padding-bottom: 18px; margin-left: 1px; margin-right: 1px;">
                        <div class="col-md-12">
                            <button type="button" class="btn menuMgmtBtn" @click="showPrintOptionDiv()">Print Option Title</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import MenuManagementModal from './MenuManagementModal.vue'
import MenuToppings from './MenuToppings.vue'
import MenuModifier from './MenuModifier.vue'
import MenuPrintOption from './MenuPrintOption.vue'
import FixedMealDeal from './MealDeal/FixedMealDeal.vue'
import CustomMealDealSetItem from './MealDeal/CustomMealDealSet.vue'
import CustomMealDealSet from './MealDeal/CustomMealDeal.vue'
import logout from './../../mixins/logoutMixins.js'

import axios from 'axios'

export default {
    components: {
        MenuManagementModal,
        MenuToppings,
        MenuModifier,
        MenuPrintOption,
        FixedMealDeal,
        CustomMealDealSetItem,
        CustomMealDealSet
    },
    data () {
        return {
            categoryOption: false,
            itemOption: false,
            categoryCollapse: false,
            itemCollapse: false,
            selectionCollapse: false,
            showModal: false,
            modalTitle: '',
            isCategoryModal: false,
            isItemModal: false,
            isSelectionModal: false,
            isToppingsModal: false,
            isToppingsClicked: false,
            isModifierClicked: false,
            isPrintOptionClicked: false,
            categoryDataList: [],
            itemDataList: [],
            selectionDataList: [],
            restaurantId: parseInt(localStorage.getItem('restaurantId')),
            selectedItemsAccordingToCategory: [],
            selectedSelectionAccordingToItem: [],
            isFixedMealDeal: false,
            isCustomMealDealSetItem: false,
            isCustomMealDealSet: false,
            addFixedMealDeal: false,
            addCustomMealDeal: false,
            addGeneralMealDeal: false,
            selectedItemId: 0,
            selectedCategoryId: 0,
            itemLabel: '',
            printOptionDataList: [],
            idOfSetItem: 0,
            labelOfSetItem: '',
            jwtToken: this.$cookies.get('token') || null
        }
    },
    created () {
        this.categoryList()
        this.printOptionList()

        localStorage.removeItem('selectedCategoryId')
        localStorage.removeItem('selectedItemId')
        localStorage.removeItem('itemLabel')
        localStorage.removeItem('editableToppingsData')
    },
    mixins: [logout],
    methods: {
        chooseCategory (val, index) {
            console.log('category choosen : '+ val);
            console.log('category choosen index : '+ index);

            let categoryBackground = 'categoryChoosenId'+index
            console.log(categoryBackground);

            let x = document.getElementById(categoryBackground)
            x.style.background = "#FFF5FF"
            this.selectedItemsAccordingToCategory = []
            this.selectedSelectionAccordingToItem = []

            localStorage.setItem('selectedCategoryId', val)
            this.selectedCategoryId = val

            for (let j = 0; j < this.itemDataList.length; j++) {
                console.log('check categoryId : '+this.itemDataList[j].categoryId);
                if (this.itemDataList[j].categoryId == val) {
                  console.log('Different price')
                  console.log(this.itemDataList[j].inPrice)
                  console.log(this.itemDataList[j].outPrice)
                  console.log(this.itemDataList[j].priceDifference)
                    let itemObj = {
                        id: this.itemDataList[j].id,
                        label: this.itemDataList[j].label,
                        basePrice: this.itemDataList[j].basePrice,
                        description: this.itemDataList[j].description,
                        priceDifference: this.itemDataList[j].priceDifference,
                        inPrice: this.itemDataList[j].inPrice,
                        outPrice: this.itemDataList[j].outPrice,
                        categoryId: this.itemDataList[j].categoryId,
                        dataType: this.itemDataList[j].dataType,
                        mealDealType: this.itemDataList[j].mealDealType,
                        mealAssignFor: this.itemDataList[j].mealAssignFor,
                        printOptionId: this.itemDataList[j].printOptionId,
                        status: this.itemDataList[j].status,
                        terminalOption: this.itemDataList[j].terminalOption
                    }
                    console.log('meal deal type : '+this.itemDataList[j].mealDealType);
                    console.log('selected item.... : ');
                    console.log(itemObj)
                    console.log(this.itemDataList[j])

                    this.selectedItemsAccordingToCategory.push(itemObj)
                }
            }

            for (let i = 0; i < this.categoryDataList.length; i++) {
                if (i != index) {
                    let categoryBackgroundRestOf = 'categoryChoosenId'+i
                    document.getElementById(categoryBackgroundRestOf).style.background = "#FFF"
                }
            }
            console.log(this.selectedItemsAccordingToCategory);
        },
        ChooseItem (val, index, dataType, mealDealType) {
            // remove other list bg color
            let catItemList = document.getElementsByClassName('item-list')
            for (var i = 0; i < catItemList.length; i++) {
              catItemList[i].style.backgroundColor = '#ffffff'
            }
            //set selected item bg color
            let categoryBackground = 'itemChoosenId'+index
            let x = document.getElementById(categoryBackground)
            x.style.background = "#FFF5FF"

            localStorage.setItem('selectedItemId', val)
            this.selectedItemId = val

            this.selectedSelectionAccordingToItem = []

            if (dataType == 'Item') {
                for (let j = 0; j < this.selectionDataList.length; j++) {
                    if (this.selectionDataList[j].itemId == val) {
                        let itemObj = {
                            id: this.selectionDataList[j].id,
                            itemId: this.selectionDataList[j].itemId,
                            label: this.selectionDataList[j].label,
                            basePrice: this.selectionDataList[j].basePrice,
                            description: this.selectionDataList[j].description,
                            priceDifference: this.selectionDataList[j].priceDifference,
                            inPrice: this.selectionDataList[j].inPrice,
                            outPrice: this.selectionDataList[j].outPrice,
                            categoryId: this.selectionDataList[j].categoryId,
                            dataType: this.selectionDataList[j].dataType,
                            mealAssignFor: this.selectionDataList[j].mealAssignFor,
                            printOptionId: this.selectionDataList[j].printOptionId,
                            status: this.selectionDataList[j].status,
                            terminalOption: this.selectionDataList[j].terminalOption
                        }
                        this.selectedSelectionAccordingToItem.push(itemObj)
                    }
                }

            }
            else if (dataType == 'MealDeal' && mealDealType == 'Fixed') {
                this.isFixedMealDeal = true
                this.isCustomMealDealSetItem = false
                this.isCustomMealDealSet = false
                this.isToppingsClicked = false
                this.isModifierClicked = false
                this.isPrintOptionClicked = false

                // this.$refs.FixedMealDeal.assignedFixedItemSet()
            }
            else if (dataType == 'MealDeal' && mealDealType == 'Custom') {
                this.isFixedMealDeal = false
                this.isToppingsClicked = false
                this.isModifierClicked = false
                this.isPrintOptionClicked = false
                this.isCustomMealDealSetItem = true
                this.isCustomMealDealSet = false

                console.log('Custom meal set condition applied.');
            }

            for (let i = 0; i < this.selectedItemsAccordingToCategory.length; i++) {
                // if (i != index) {
                //     let itemBackgroundRestOf = 'itemChoosenId'+i
                //     console.log(itemBackgroundRestOf);
                //     document.getElementById(itemBackgroundRestOf).style.background = "GREEN"
                // }
                if (i == index) {
                    localStorage.setItem('itemLabel', this.selectedItemsAccordingToCategory[i].label)
                    this.itemLabel = this.selectedItemsAccordingToCategory[i].label
                }
            }
        },
        categoryList () {
            console.log('categoryList called');
            let url = process.env.VUE_APP_API_URL+`/api/menu?restaurantId=${this.restaurantId}`

            // request header
            let requestHeader = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.jwtToken}`
            }

            axios
            .get(url,
            {
                headers: requestHeader
            })
            .then((data)=> {
                console.log(data);

                if (data.status === 403) {
                    let logoutResponse = this.logout()
                    if (logoutResponse === 1) {
                        this.$router.push({ path: '/login' })
                    }
                }

                this.itemDataList = []
                this.selectionDataList = []

                this.categoryDataList = data.data

                this.itemList(data.data)
                this.selectionList(data.data)
            })
        },
        printOptionList () {
            console.log('Print option list...');
            let url = process.env.VUE_APP_API_URL+`/api/printOption?restaurantId=${this.restaurantId}`

            // request header
            let requestHeader = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.jwtToken}`,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Headers": "*",
                'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            }

            axios
            .get(url,
            {
                headers: requestHeader
            })
            .then((data)=> {
                console.log(data);

                if (data.status === 403) {
                    let logoutResponse = this.logout()
                    if (logoutResponse === 1) {
                        this.$router.push({ path: '/login' })
                    }
                }

                this.printOptionDataList = data.data
            })
        },
        itemList (val) {
            console.log('Item List executed ....')
            for (let i = 0; i < val.length; i++) {
                if (val[i].children.length > 0) {
                    let children = val[i].children

                    console.log(children);

                    for (let j = 0; j < children.length; j++) {
                        console.log('label : ' +children[j].label+ ' | data type : ' +children[j].dataType+ ' | mealDealType : ' +children[j].mealDealType);
                        let child = {
                            id: children[j].id,
                            categoryId: val[i].id,
                            label: children[j].label,
                            description: children[j].description,
                            priceDifference: children[j].priceDifference,
                            inPrice:children[j].inPrice,
                            outPrice: children[j].outPrice,
                            basePrice: children[j].basePrice,
                            dataType: children[j].dataType,
                            mealDealType: children[j].mealDealType,
                            mealAssignFor: children[j].mealAssignFor,
                            printOptionId: children[j].printOptionId,
                            status: children[j].status,
                            terminalOption: children[j].terminalOption
                        }
                        this.itemDataList.push (child)
                    }
                }
            }
            console.log('Item List');
            console.log(this.itemDataList);

            this.selectedItemsAccordingToCategory = []

            if (localStorage.getItem('selectedCategoryId')) {
                console.log('Item List executed .... category selected')
                setTimeout(()=> {
                    for (let j = 0; j < this.itemDataList.length; j++) {
                        console.log('check categoryId : '+this.itemDataList[j].categoryId);
                        if (this.itemDataList[j].categoryId == localStorage.getItem('selectedCategoryId')) {
                            let itemObj = {
                                id: this.itemDataList[j].id,
                                label: this.itemDataList[j].label,
                                basePrice: this.itemDataList[j].basePrice,
                                description: this.itemDataList[j].description,
                                categoryId: this.itemDataList[j].categoryId,
                                dataType: this.itemDataList[j].dataType,
                                mealDealType: this.itemDataList[j].mealDealType,
                                mealAssignFor: this.itemDataList[j].mealAssignFor,
                                printOptionId: this.itemDataList[j].printOptionId,
                                status: this.itemDataList[j].status,
                                terminalOption: this.itemDataList[j].terminalOption,
                                priceDifference: this.itemDataList[j].priceDifference,
                                inPrice: this.itemDataList[j].inPrice,
                                outPrice: this.itemDataList[j].outPrice,
                            }

                            console.log('mealDealType : '+this.itemDataList[j].mealDealType);
                            console.log('mealAssignFor : '+this.itemDataList[j].mealAssignFor);
                            console.log('mealAssignFor itemObj : ');
                            console.log(itemObj);

                            this.selectedItemsAccordingToCategory.push(itemObj)
                        }
                    }
                }, 1000)
            }


            console.log('items list')
            console.log(this.itemDataList);
        },
        selectionList (val) {
            for (let i = 0; i < val.length; i++) {
                if (val[i].children.length > 0) {
                    let children = val[i].children

                    for (let j = 0; j < children.length; j++) {
                        let child = children[j].children

                        if (child.length > 0) {
                            console.log('selections child[x] ......')
                            console.log(child)

                            for (let x = 0; x < child.length; x++) {
                                let selections = {
                                    id: child[x].id,
                                    categoryId: val[i].id,
                                    itemId: children[j].id,
                                    label: child[x].label,
                                    description: child[x].description,
                                    basePrice: child[x].basePrice,
                                    mealAssignFor: child[x].mealAssignFor,
                                    printOptionId: child[x].printOptionId,
                                    status: child[x].status,
                                    terminalOption: child[x].terminalOption,
                                    priceDifference: child[x].priceDifference,
                                    inPrice:child[x].inPrice,
                                    outPrice: child[x].outPrice
                                }
                                this.selectionDataList.push (selections)
                            }
                        }
                    }
                }
            }

            console.log('jjjjjjjjjjjjjjjjjj');

            this.selectedSelectionAccordingToItem = []

            setTimeout(()=> {
                if (localStorage.getItem('selectedCategoryId') && localStorage.getItem('selectedItemId')) {

                    for (let j = 0; j < this.selectionDataList.length; j++) {
                        console.log('check categoryId : '+this.selectionDataList[j].itemId);
                        if (this.selectionDataList[j].itemId == this.selectedItemId) {
                            let itemObj = {
                                id: this.selectionDataList[j].id,
                                id: this.selectionDataList[j].itemId,
                                label: this.selectionDataList[j].label,
                                basePrice: this.selectionDataList[j].basePrice,
                                description: this.selectionDataList[j].description,
                                categoryId: this.selectionDataList[j].categoryId,
                                dataType: this.selectionDataList[j].dataType,
                                mealAssignFor: this.selectionDataList[j].mealAssignFor,
                                printOptionId: this.selectionDataList[j].printOptionId,
                                status: this.selectionDataList[j].status,
                                terminalOption: this.selectionDataList[j].terminalOption,
                                priceDifference: this.selectionDataList[j].priceDifference,
                                inPrice: this.selectionDataList[j].inPrice,
                                outPrice: this.selectionDataList[j].outPrice
                            }

                            this.selectedSelectionAccordingToItem.push(itemObj)
                        }
                    }
                }
            }, 1000)


            console.log('selectionDataList')
            console.log(this.selectionDataList)
        },
        categoryClick () {
            this.categoryOption = !this.categoryOption
        },
        itemClick () {
            console.log('item optns');
            this.itemOption = !this.itemOption
        },
        categoryListShow(val) {
            let idShow = 'categoryShow'+val
            let idHide = 'categoryHide'+val
            let idOption = 'categoryOptions'+val

            if (this.categoryCollapse) {
                let x = document.getElementById(idHide)
                let y = document.getElementById(idShow)
                let z = document.getElementById(idOption)
                x.style.display = "block"
                y.style.display = "none"
                z.style.display = "none"
                this.categoryCollapse = !this.categoryCollapse
            }
            else {
                let x = document.getElementById(idHide)
                let y = document.getElementById(idShow)
                let z = document.getElementById(idOption)
                x.style.display = "none"
                y.style.display = "block"
                z.style.display = "block"
                this.categoryCollapse = !this.categoryCollapse
            }
        },
        itemListShow (val) {
            let idShow = 'itemShow'+val
            let idHide = 'itemHide'+val
            let idOption = 'itemOptions'+val

            if (this.itemCollapse) {
                let x = document.getElementById(idHide)
                let y = document.getElementById(idShow)
                let z = document.getElementById(idOption)
                x.style.display = "block"
                y.style.display = "none"
                z.style.display = "none"
                this.itemCollapse = !this.itemCollapse
            }
            else {
                let x = document.getElementById(idHide)
                let y = document.getElementById(idShow)
                let z = document.getElementById(idOption)
                x.style.display = "none"
                y.style.display = "block"
                z.style.display = "block"
                this.itemCollapse = !this.itemCollapse
            }
        },
        selectionListShow (val) {
            let idShow = 'selectionShow'+val
            let idHide = 'selectionHide'+val
            let idOption = 'selectionOption'+val

            if (this.selectionCollapse) {
                let x = document.getElementById(idHide)
                let y = document.getElementById(idShow)
                let z = document.getElementById(idOption)
                x.style.display = "block"
                y.style.display = "none"
                z.style.display = "none"
                this.selectionCollapse = !this.selectionCollapse
            }
            else {
                let x = document.getElementById(idHide)
                let y = document.getElementById(idShow)
                let z = document.getElementById(idOption)
                x.style.display = "none"
                y.style.display = "block"
                z.style.display = "block"
                this.selectionCollapse = !this.selectionCollapse
            }
        },
        addNewCategory () {
            this.modalTitle = 'Add New Category'
            this.isCategoryModal = true
            this.isItemModal = false
            this.isSelectionModal = false
            this.isToppingsModal = false

            this.addGeneralMealDeal = false
            this.addFixedMealDeal = false
            this.addCustomMealDeal = false
            // this.bus.$emit('categoryModal')
            this.$refs.MenuManagementModal.categoryItemModal(this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal)
        },
        editCategory (val, title, description, basePrice, categoryId, itemId, terminal, print, assign, status) {
            this.modalTitle = 'Edit Category'
            this.isCategoryModal = true
            this.isItemModal = false
            this.isSelectionModal = false
            this.isToppingsModal = false

            this.addGeneralMealDeal = false
            this.addFixedMealDeal = false
            this.addCustomMealDeal = false
            // this.bus.$emit('categoryModal')
            this.$refs.MenuManagementModal.categoryItemModal(this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal)
            console.log(terminal + ' | ' + print + ' | ' + assign + ' | ' + status)
            // this.$refs.MenuManagementModal.editCategoryInfo(val, title, description, 0, 0, 0, this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal, terminal, print, assign, status)
            this.$refs.MenuManagementModal.editCategoryInfo(val, title, description, 0, 0, 0, 0, 0, 0, this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal, terminal, print, assign, status)
        },
        addNewItem () {
            if (localStorage.getItem('selectedCategoryId')) {
                console.log('ok');
                this.modalTitle = 'Add New Item'
                this.isCategoryModal = false
                this.isItemModal = true
                this.isSelectionModal = false
                this.isToppingsModal = false
                this.addGeneralMealDeal = true
                this.addFixedMealDeal = false
                this.addCustomMealDeal = false
                // this.bus.$emit('categoryModal')
                this.$refs.MenuManagementModal.categoryItemModal(this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal)
            }
            else {
                console.log('Warning');
                this.$toastr.w("Please select a category first!", "Warning");
            }

        },
        addNewFixedMealDeal () {
            this.modalTitle = 'Add New Item'
            this.isCategoryModal = false
            this.isItemModal = true
            this.isSelectionModal = false
            this.isToppingsModal = false
            this.addGeneralMealDeal = false
            this.addFixedMealDeal = true
            this.addCustomMealDeal = false
            // this.bus.$emit('categoryModal')
            this.$refs.MenuManagementModal.categoryItemModal(this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal)
        },
        addNewCustomMealDeal () {
            this.modalTitle = 'Add New Item'
            this.isCategoryModal = false
            this.isItemModal = true
            this.isSelectionModal = false
            this.isToppingsModal = false
            this.addGeneralMealDeal = false
            this.addFixedMealDeal = false
            this.addCustomMealDeal = true
            // this.bus.$emit('categoryModal')
            this.$refs.MenuManagementModal.categoryItemModal(this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal)
        },
        editItems (val, title, description, basePrice, priceDifference, inPrice, outPrice, categoryId, itemId, mealDealType, terminal, print, assign, status) {
            console.log('item detailsssss');
            console.log(priceDifference, inPrice, outPrice);
            console.log(basePrice);
            this.modalTitle = 'Edit Item'
            this.isCategoryModal = false
            this.isItemModal = true
            this.isSelectionModal = false
            this.isToppingsModal = false
            // this.bus.$emit('categoryModal')
            if (mealDealType == 'Custom') {
                this.addCustomMealDeal = true
            }
            else if (mealDealType == 'Fixed') {
                this.addFixedMealDeal = true
            }
            else {
                this.addGeneralMealDeal = true
            }

            this.$refs.MenuManagementModal.categoryItemModal(this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal)
            // this.$refs.MenuManagementModal.editCategoryInfo(val, title, description, basePrice, priceDifference, inPrice, outPrice, categoryId, 0, this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal, terminal, print, assign, status)
            this.$refs.MenuManagementModal.editCategoryInfo(val, title, description, basePrice, priceDifference, inPrice, outPrice, categoryId, 0, this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal, terminal, print, assign, status)
        },
        addNewSelection () {
            if (localStorage.getItem('selectedCategoryId') && localStorage.getItem('selectedItemId')) {
                this.modalTitle = 'Add New Selection'
                this.isCategoryModal = false
                this.isItemModal = false
                this.isSelectionModal = true
                this.isToppingsModal = false

                this.addGeneralMealDeal = false
                this.addFixedMealDeal = false
                this.addCustomMealDeal = false
                // this.bus.$emit('categoryModal')
                this.$refs.MenuManagementModal.categoryItemModal(this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal)
            }
            else {
                this.$toastr.w("Please select a category and an item first!", "Warning");
            }

        },
        editSelections (val, title, description, basePrice, priceDifference, inPrice, outPrice, categoryId, itemId, terminal, print, assign, status) {
            console.log(itemId);
            console.log('selection detailsssss');
            console.log(priceDifference, inPrice, outPrice);
            this.modalTitle = 'Edit Selection'
            this.isCategoryModal = false
            this.isItemModal = false
            this.isSelectionModal = true
            this.isToppingsModal = false

            this.addGeneralMealDeal = false
            this.addFixedMealDeal = false
            this.addCustomMealDeal = false
            // this.bus.$emit('categoryModal')
            this.$refs.MenuManagementModal.categoryItemModal(this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal)
            console.log(terminal + ' | ' + print + ' | ' + assign + ' | ' + status)
            // this.$refs.MenuManagementModal.editCategoryInfo(val, title, description, basePrice, categoryId, itemId, this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal, terminal, print, assign, status)
            this.$refs.MenuManagementModal.editCategoryInfo(val, title, description, basePrice, priceDifference, inPrice, outPrice, categoryId, itemId, this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal, terminal, print, assign, status)
        },
        addNewToppings () {
            this.modalTitle = 'Add New Toppings'
            this.isCategoryModal = false
            this.isItemModal = false
            this.isSelectionModal = false
            this.isToppingsModal = true

            this.addGeneralMealDeal = false
            this.addFixedMealDeal = false
            this.addCustomMealDeal = false
            // this.bus.$emit('categoryModal')
            this.$refs.MenuManagementModal.categoryItemModal(this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal)
        },
        editToppings () {
            this.modalTitle = 'Edit Toppings'
            this.isCategoryModal = false
            this.isItemModal = false
            this.isSelectionModal = false
            this.isToppingsModal = true

            this.$refs.MenuManagementModal.editToppingsInfo()
            this.addGeneralMealDeal = false
            this.addFixedMealDeal = false
            this.addCustomMealDeal = false
            // this.bus.$emit('categoryModal')
            this.$refs.MenuManagementModal.categoryItemModal(this.addGeneralMealDeal, this.addFixedMealDeal, this.addCustomMealDeal)
        },
        toppingsSubmitted () {
            this.$refs.MenuToppings.toppingsList()
        },
        checkEditClickForSetItem (data) {
            console.log(data);
            this.idOfSetItem = parseInt(data[0])
            this.labelOfSetItem = data[1]

            console.log(this.idOfSetItem+' - '+this.labelOfSetItem);

            setTimeout(()=> {
                this.isToppingsClicked = false
                this.isModifierClicked = false
                this.isPrintOptionClicked = false
                this.isFixedMealDeal = false
                this.isCustomMealDealSetItem = false
                this.isCustomMealDealSet = true
            }, 500)

        },
        showSetList () {
            this.isFixedMealDeal = false
            this.isToppingsClicked = false
            this.isModifierClicked = false
            this.isPrintOptionClicked = false
            this.isCustomMealDealSetItem = true
            this.isCustomMealDealSet = false

            console.log('Custom meal set condition applied. Back to set list.');
        },
        showMenuDiv () {
            this.isToppingsClicked = false
            this.isModifierClicked = false
            this.isPrintOptionClicked = false
            this.isFixedMealDeal = false
            this.isCustomMealDealSetItem = false
            this.isCustomMealDealSet = false

            this.selectedItemsAccordingToCategory = []
            this.selectedSelectionAccordingToItem = []
        },
        showToppingsDiv () {
            this.isToppingsClicked = true
            this.isModifierClicked = false
            this.isPrintOptionClicked = false
            this.isFixedMealDeal = false
            this.isCustomMealDealSetItem = false
            this.isCustomMealDealSet = false
        },
        showModifierDiv () {
            this.isToppingsClicked = false
            this.isModifierClicked = true
            this.isPrintOptionClicked = false
            this.isFixedMealDeal = false
            this.isCustomMealDealSetItem = false
            this.isCustomMealDealSet = false
        },
        showPrintOptionDiv () {
            this.isToppingsClicked = false
            this.isModifierClicked = false
            this.isPrintOptionClicked = true
            this.isFixedMealDeal = false
            this.isCustomMealDealSetItem = false
            this.isCustomMealDealSet = false
        },
        addMealDeal() {
          this.$router.push({ name: 'DealSet' })
        },
        deleteCategory (val) {
            console.log('deleteCategory');
            let url = process.env.VUE_APP_API_URL+`/api/menu/delete`

            // request header
            let requestHeader = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.jwtToken}`,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Headers": "*",
                'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            }

            let dataToSubmit = {
                "id": val,
                "restaurantId": this.restaurantId,
                "printOptionTitle": "Starter",
                "toppingsCategoryId": 0,
                "terminalOption": "",
                "modifierId": 0,
                "displayOrder": 0,
                "description": "",
                "baseQty": 1,
                "printOptionId": 1,
                "createdAt": "3 Sep 2020 11:05:30",
                "mealAssignFor": "Kitchen",
                "children": [],
                "setId": 0,
                "setItemId": 0,
                "setTitle": "",
                "iconURL": "",
                "toppingsId": 0,
                "basePrice": "",
                "updatedAt": "30 Sep 2020 11:05:30",
                "selectionId": 0,
                "dataType": "Category",
                "label": "",
                "resourceRef": "",
                "imgURL": "",
                "itemId": 0,
                "mealDealId": "",
                "toppingsCategory": "",
                "maxQty": 0,
                "mealDealType": "",
                "refCode": 0,
                "categoryId": val,
                "status": "Active",
                "minQty": 0,
                "total": ""
            }

            if (confirm('Are you sure to delete this category?')) {
                axios
                .post(url, dataToSubmit,
                {
                    headers: requestHeader
                })
                .then((data)=> {
                    console.log(data);

                    if (data.status == 200) {
                        this.$toastr.s("Deleted Successfully", "Success");
                    }

                    if (data.status === 403) {
                        let logoutResponse = this.logout()
                        if (logoutResponse === 1) {
                            this.$router.push({ path: '/login' })
                        }
                    }

                    this.categoryList()
                })
            }
        },
        deleteItem (val) {
            console.log('deleteCategory');
            let url = process.env.VUE_APP_API_URL+`/api/menu/delete`

            // request header
            let requestHeader = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.jwtToken}`,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Headers": "*",
                'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            }

            let dataToSubmit = {
                "id": val,
                "restaurantId": this.restaurantId,
                "printOptionTitle": "Starter",
                "toppingsCategoryId": 0,
                "terminalOption": "",
                "modifierId": 0,
                "displayOrder": 0,
                "description": "",
                "baseQty": 1,
                "printOptionId": 1,
                "createdAt": "3 Sep 2020 11:05:30",
                "mealAssignFor": "Kitchen",
                "children": [],
                "setId": 0,
                "setItemId": 0,
                "setTitle": "",
                "iconURL": "",
                "toppingsId": 0,
                "basePrice": "",
                "updatedAt": "30 Sep 2020 11:05:30",
                "selectionId": 0,
                "dataType": "Item",
                "label": "",
                "resourceRef": "",
                "imgURL": "",
                "itemId": 0,
                "mealDealId": "",
                "toppingsCategory": "",
                "maxQty": 0,
                "mealDealType": "",
                "refCode": 0,
                "categoryId": this.selectedCategoryId,
                "status": "Active",
                "minQty": 0,
                "total": ""
            }

            if (confirm('Are you sure to delete this item?')) {
                axios
                .post(url, dataToSubmit,
                {
                    headers: requestHeader
                })
                .then((data)=> {
                    console.log(data);

                    if (data.status == 200) {
                        this.$toastr.s("Deleted Successfully", "Success");
                    }

                    if (data.status === 403) {
                        let logoutResponse = this.logout()
                        if (logoutResponse === 1) {
                            this.$router.push({ path: '/login' })
                        }
                    }

                    this.selectedItemsAccordingToCategory = []
                    this.categoryList()
                })
            }
        },
        deleteSelection (val) {
            console.log('deleteSelection : '+val)
            console.log('this.selectedItemId : '+this.selectedItemId);
            console.log('this.selectedCategoryId : '+this.selectedCategoryId);
            let url = process.env.VUE_APP_API_URL+`/api/menu/delete`

            // request header
            let requestHeader = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.jwtToken}`,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Headers": "*",
                'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            }

            let dataToSubmit = {
                "id": val,
                "restaurantId": this.restaurantId,
                "printOptionTitle": "Starter",
                "toppingsCategoryId": 0,
                "terminalOption": "",
                "modifierId": 0,
                "displayOrder": 0,
                "description": "",
                "baseQty": 1,
                "printOptionId": 1,
                "createdAt": "3 Sep 2020 11:05:30",
                "mealAssignFor": "Kitchen",
                "children": [],
                "setId": 0,
                "setItemId": this.selectedItemId,
                "setTitle": "",
                "iconURL": "",
                "toppingsId": 0,
                "basePrice": "",
                "updatedAt": "30 Sep 2020 11:05:30",
                "selectionId": 0,
                "dataType": "Selection",
                "label": "",
                "resourceRef": "",
                "imgURL": "",
                "itemId": this.selectedItemId,
                "mealDealId": "",
                "toppingsCategory": "",
                "maxQty": 0,
                "mealDealType": "",
                "refCode": 0,
                "categoryId": this.selectedCategoryId,
                "status": "Active",
                "minQty": 0,
                "total": ""
            }

            if (confirm('Are you sure to delete this selection?')) {
                axios
                .post(url, dataToSubmit,
                {
                    headers: requestHeader
                })
                .then((data)=> {
                    console.log(data);

                    if (data.status == 200) {
                        this.$toastr.s("Deleted Successfully", "Success");
                    }

                    if (data.status === 403) {
                        let logoutResponse = this.logout()
                        if (logoutResponse === 1) {
                            this.$router.push({ path: '/login' })
                        }
                    }

                    this.selectedItemsAccordingToCategory = []
                    this.selectedSelectionAccordingToItem = []

                    this.categoryList()
                })
            }
        }
    }
}
</script>

<style scoped>
.content{padding: 10px 0}
.menuMgmtBtn {
    background: #FFF5FF;
    border: 1px solid #E317E3;
    color: #E317E3;
}

.bottomOption {
    /* position: -webkit-sticky; */
    position: fixed;
    bottom: 0;
    width: inherit;
}

@media (min-width: 1440px) {
    .bottomOption {
        max-width: 1140px;
    }
}
@media (min-width: 1200px) and (max-width: 1439px) {
    .bottomOption {
        max-width: 80%;
    }
}
@media (min-width: 1024px) and (max-width: 1199px) {
    .bottomOption {
        max-width: 75%;
    }
}
@media (min-width: 992px) and (max-width: 1023px) {
    .bottomOption {
        max-width: 75%;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .bottomOption {
        max-width: 720px;
    }
}
@media (min-width: 576px) and (max-width: 767px) {
    .bottomOption {
        max-width: 540px;
    }
}

.editBtn {
    background-color: #E317E3;
    border: 1px solid  #E317E3;
    color: white;
}
.deleteBtn {
    background-color: #FF646F;
    border: 1px solid #FF646F;
    color: white;
}
</style>
