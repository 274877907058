<template>
  <div class="content ">
    <div class="container" style="padding-left: 10px">
      <!--table-->
      <div class="row">
        <div class="col-12">
          <card class="strpied-tabled-with-hover"
                body-classes="table-responsive"
          >
            <content-header>
              <p class="mb-0" style="color: #FE750F;">Menu Management - Hook Items at Set</p>
            </content-header>

            <div class="row mx-auto mt-3 pb-3" style="width: 96%">
              <div class="col-md-4">
                <select class="form-control mb-3">
                  <option value="">Set 1</option>
                </select>
                <select class="form-control mb-3">
                  <option value="">Set 2</option>
                </select>
                <select class="form-control mb-3">
                  <option value="">Set 3</option>
                </select>
                <select class="form-control mb-3">
                  <option value="">Set 4</option>
                </select>
                <select class="form-control mb-3">
                  <option value="">Set 5</option>
                </select>
              </div>
              <div class="col-md-4">
                <select class="form-control mb-3">
                  <option value="">Menu Category 1</option>
                </select>
                <select class="form-control mb-3">
                  <option value="">Menu Category 2</option>
                </select>
                <select class="form-control mb-3">
                  <option value="">Menu Category 3</option>
                </select>
                <select class="form-control mb-3">
                  <option value="">Menu Category 4</option>
                </select>
                <select class="form-control mb-3">
                  <option value="">Menu Category 5</option>
                </select>
              </div>
              <div class="col-md-4 all-items px-0">
                <div class="p-3">
                  <input type="checkbox" class="mb-3"> Item Name 1 <br>
                  <input type="checkbox" class="mb-3"> Item Name 1 <br>
                  <input type="checkbox" class="mb-3"> Item Name 1 <br>
                  <input type="checkbox" class="mb-3"> Item Name 1 <br>
                  <input type="checkbox" class="mb-3"> Item Name 1 <br>
                  <input type="checkbox" class="mb-3"> Item Name 1 <br>
                  <input type="checkbox" class="mb-3"> Item Name 1 <br>
                  <input type="checkbox" class="mb-3"> Item Name 1 <br>
                  <input type="checkbox" class="mb-3"> Item Name 1 <br>
                  <input type="checkbox" class="mb-3"> Item Name 1 <br>
                </div>
                <div class="item-selected">
                  <p>Menu Item Selected - 5</p>
                  <button class="btn btn-done float-right position-relative">Done</button>
                </div>
              </div>
            </div>
          </card>
        </div>

      </div>

      <MenuManagementModal
        ref="MenuManagementModal"
        v-bind:modalTitle="modalTitle"
        v-bind:isCategoryModal="isCategoryModal"
        v-bind:isItemModal="isItemModal"
        v-bind:isSelectionModal="isSelectionModal"
        v-bind:isToppingsModal="isToppingsModal"
      />
      <MenuToppings v-if="isToppingsClicked"  @toppingsEvent="addNewToppings" />

      <MenuModifier v-if="isModifierClicked" />

      <MenuPrintOption v-if="isPrintOptionClicked" />

      <div class="row bottomOption">
        <div class="col-md-3" style="text-align: center;">
          <div class="row" style="background: #FFFFFF; padding-top: 18px; padding-bottom: 18px; margin-left: 1px;">
            <div class="col-md-12">
              <button type="button" class="btn menuMgmtBtn" @click="addNewSelection()">Selection</button>
            </div>
          </div>

        </div>
        <div class="col-md-3" style="text-align: center;">
          <div class="row" style="background: #FFFFFF; padding-top: 18px; padding-bottom: 18px; margin-left: 1px;">
            <div class="col-md-12">
              <button type="button" class="btn menuMgmtBtn" @click="showToppingsDiv()">Toppings</button>
            </div>
          </div>

        </div>
        <div class="col-md-3" style="text-align: center;">
          <div class="row" style="background: #FFFFFF; padding-top: 18px; padding-bottom: 18px; margin-left: 1px;">
            <div class="col-md-12">
              <button type="button" class="btn menuMgmtBtn" @click="showModifierDiv()">Modifier</button>
            </div>
          </div>

        </div>
        <div class="col-md-3" style="text-align: center;">
          <div class="row" style="background: #FFFFFF; padding-top: 18px; padding-bottom: 18px; margin-left: 1px; margin-right: 1px;">
            <div class="col-md-12">
              <button type="button" class="btn menuMgmtBtn" @click="showPrintOptionDiv()">Print Option Title</button>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>
<script>
import Card from 'src/components/Cards/Card.vue'
import ContentHeader from "src/components/ContentHeader";
import MenuManagementModal from './MenuManagementModal.vue'
import MenuToppings from './MenuToppings.vue'
import MenuModifier from './MenuModifier.vue'
import MenuPrintOption from './MenuPrintOption.vue'

export default {
  components: {
    Card,
    ContentHeader,
    MenuManagementModal,
    MenuToppings,
    MenuModifier,
    MenuPrintOption
  },
  data () {
    return {
      categoryOption: false,
      itemOption: false,
      categoryCollapse: false,
      itemCollapse: false,
      showModal: false,
      modalTitle: '',
      isCategoryModal: false,
      isItemModal: false,
      isSelectionModal: false,
      isToppingsModal: false,
      isToppingsClicked: false,
      isModifierClicked: false,
      isPrintOptionClicked: false
    }
  },
  methods: {
    addNewSelection () {
      this.modalTitle = 'Add New Selection'
      this.isCategoryModal = false
      this.isItemModal = false
      this.isSelectionModal = true
      this.isToppingsModal = false
      // this.bus.$emit('categoryModal')
      this.$refs.MenuManagementModal.categoryItemModal()
    },
    addNewToppings () {
      this.modalTitle = 'Add New Toppings'
      this.isCategoryModal = false
      this.isItemModal = false
      this.isSelectionModal = false
      this.isToppingsModal = true
      // this.bus.$emit('categoryModal')
      this.$refs.MenuManagementModal.categoryItemModal()
    },
    showToppingsDiv () {
      this.isToppingsClicked = true
      this.isModifierClicked = false
      this.isPrintOptionClicked = false
    },
    showModifierDiv () {
      this.isToppingsClicked = false
      this.isModifierClicked = true
      this.isPrintOptionClicked = false
    },
    showPrintOptionDiv () {
      this.isToppingsClicked = false
      this.isModifierClicked = false
      this.isPrintOptionClicked = true
    },
  }
}
</script>
<style scoped>
.content{padding: 10px 0}
select{background-color: #F5F5F5}
.all-items{background-color: #FFF5FF}
.item-selected{background-color: #FFDEFF; padding: 10px}
.item-selected p{display: inline-block; color: #E317E3; margin-bottom: unset}
.btn-done{
  top: -2px;
  color: #FFFFFF;
  padding: 3px 10px;
  border: unset;
  background-color: #E317E3;
}
.bottomOption {
  position: absolute;
  bottom: 7%;
  width: 100%;
}
.menuMgmtBtn {
  background: #FFF5FF;
  border: 1px solid #E317E3;
  color: #E317E3;
}
</style>
