<template>

    <div class="content">
        <div class="container" style="padding-left: 10px">
            <div class="row">

                <div class="col-md-12" >
                    <content-header>
                        <p class="mb-0" style="color: #FE750F;">Owner List</p>
                    </content-header>

                    <div style="background-color: #FFFFFF; padding-top: 2%; padding-left: 15px; padding-right: 15px; padding-bottom: 31px;">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Owner Name</th>
                                    <th>Restaurant Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Subscription End</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in ownerDataList" :key="index">
                                    <td>{{item.owner.firstName+' '+item.owner.middleName+' '+item.owner.lastName}}</td>
                                    <td>{{item.restaurantName}}</td>
                                    <td>{{item.email}}</td>
                                    <td>{{item.phone}}</td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <i class="fa fa-edit" @click="editItem(item.id)" style="cursor: pointer;"></i> &nbsp;
                                        <i class="fa fa-plus" style="cursor: pointer; color: #F4522D;" @click="goToMenuManagement(item.id)"></i>
                                        <i class="fa fa-picture-o" style="cursor: pointer;" @click="goToContentManagement(item.id)"></i>
                                        <i class="fa fa-trash" @click="deleteOwner(item.id)" style="cursor: pointer; color: #F4522D;"></i> &nbsp;
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import ContentHeader from "@/components/ContentHeader"
import axios from 'axios'
import logout from './../../mixins/logoutMixins.js'

export default {
    components: {
      ContentHeader
    },
    data () {
        return {
            ownerDataList: [],
            jwtToken: this.$cookies.get('token') || null
        }
    },
    created () {
        this.ownerList()
    },
    methods: {
        deleteOwner(val) {
            if (confirm('Are you sure to delete this owner?')) {
                let url = process.env.VUE_APP_API_URL+`/api/restaurant/delete?id=${val}`

                axios
                .get(url,
                {
                    headers: {
                        Authorization: `Bearer ${this.jwtToken}`,
                    }
                })
                .then((data)=> {
                    console.log(data);

                    if (data.status == 200) {
                        this.$toastr.s("deleted Successfully", "Success");

                        this.ownerList()
                    }

                    if (data.status === 403) {
                        let logoutResponse = this.logout()
                        if (logoutResponse === 1) {
                            this.$router.push({ path: '/login' })
                        }
                    }
                })
            }

        },
        ownerList () {
            let url = process.env.VUE_APP_API_URL+'/api/restaurant'

            axios
            .get(url,
            {
                headers: {
                    Authorization: `Bearer ${this.jwtToken}`,
                }
            })
            .then((data) => {
                this.ownerDataList = data.data

                if (data.status === 403) {
                    let logoutResponse = this.logout()
                    if (logoutResponse === 1) {
                        this.$router.push({ path: '/login' })
                    }
                }
            })
        },
        editItem(val) {
            let editableData = []
            for (var i = 0; i < this.ownerDataList.length; i++) {
                if (this.ownerDataList[i].id == val) {
                    editableData = JSON.stringify(this.ownerDataList[i])

                    console.log(editableData);
                }
            }

            localStorage.setItem('editableData', editableData)

            window.location = '/admin/create-owner'
        },
        goToMenuManagement (val) {
            localStorage.setItem('restaurantId', val)
            this.$router.push('/admin/menu-management')
        },
        goToContentManagement (val) {
            localStorage.setItem('restaurantId', val)
            this.$router.push('/admin/content-management')
        }
    }
}
</script>

<style scoped>
.table > thead > tr > th {
    color: black;
    font-size: .8rem;
    font-weight: bold;
}
.table > thead > tr > th:last-child, .table > tbody > tr > th:last-child, .table > tfoot > tr > th:last-child, .table > thead > tr > td:last-child, .table > tbody > tr > td:last-child, .table > tfoot > tr > td:last-child {
    width: auto;
}
</style>
