<template>
    <div class="content">
        <div class="container" style="padding-left: 10px">

            <div class="row">

                <div class="col-md-12" >
                  <content-header>
                    <p class="mb-0" style="color: #FE750F;" v-if="!isEditClicked">Create owner</p>
                    <p class="mb-0" style="color: #FE750F;" v-if="isEditClicked">Edit owner</p>
                  </content-header>
                  <div style="background-color: #FFFFFF; padding-left: 31px; padding-right: 31px; padding-bottom: 31px;">
                      <div class="row">
                          <div class="col-md-12">
                              <div class="btnGroup">
                                  <button type="button" v-bind:class="['btn', 'btnGroupMargin', {'optionBtn': ownerDetails}]" @click="ownerDetailsFun()">Owner Details</button>
                                  <button type="button" v-bind:class="['btn', 'btnGroupMargin', {'optionBtn': businessInfo}]" @click="businessInfoFun()">Business Info</button>
                                  <button type="button" v-bind:class="['btn', {'optionBtn': bankDetails}]" @click="bankDetailsFun()">Bank Details</button>
                              </div>
                          </div>
                      </div>

                      <div class="row">
                          <div class="col-md-12">
                              <div class="line"></div>
                          </div>
                      </div>

                      <div class="row" style="margin-top: 49px;" v-if="ownerDetails">
                          <div class="col-md-12">

                              <form>

                                  <div class="row">

                                      <div class="col-md-4">
                                          <label for="customRadio1">Name Title</label>
                                          <div class="row">
                                              <div class="col-md-6">
                                                  <div class="form-group custom-control custom-radio">
                                                      <input type="radio" id="customRadio1" name="customRadio" v-model="isMr" class="custom-control-input">
                                                      <label class="custom-control-label" for="customRadio1">Mr</label>
                                                  </div>
                                              </div>
                                              <div class="col-md-6">
                                                  <div class="form-group custom-control custom-radio">
                                                      <input type="radio" id="customRadio1" name="customRadio" v-model="isMrs" class="custom-control-input">
                                                      <label class="custom-control-label" for="customRadio1">Mrs</label>
                                                  </div>
                                              </div>
                                          </div>

                                          <div class="form-group">
                                              <label for="exampleInputEmail1" :class="{requiredCls : isLnameGiven}" >Last Name *</label>
                                              <input type="text" class="form-control" id="exampleInputEmail1" v-model="lastName" aria-describedby="emailHelp" placeholder="Enter your last name">
                                          </div>
                                          <div class="form-group">
                                              <label for="exampleInputEmail1">House/Flat No</label>
                                              <input type="text" class="form-control" id="exampleInputEmail1" v-model="house" aria-describedby="emailHelp" placeholder="Enter your house no">
                                          </div>
                                          <div class="form-group">
                                              <label for="exampleInputEmail1">Street Name</label>
                                              <input type="text" class="form-control" id="exampleInputEmail1" v-model="street" aria-describedby="emailHelp" placeholder="Enter your street name">
                                          </div>
                                          <div class="form-group">
                                              <label for="exampleInputEmail1">Username</label>
                                              <input type="text" class="form-control" id="exampleInputEmail1" v-model="username" aria-describedby="emailHelp" disabled>
                                          </div>
                                      </div>

                                      <div class="col-md-4">
                                          <div class="form-group">
                                              <label for="exampleInputEmail1" :class="{requiredCls : isFnameGiven}">First Name *</label>
                                              <input type="text" class="form-control" id="exampleInputEmail1" v-model="firstName" aria-describedby="emailHelp" placeholder="Enter your first name">
                                          </div>
                                          <div class="form-group">
                                              <label for="exampleInputEmail1" :class="{requiredCls : isDOBgiven}">DOB *</label>
                                              <datepicker v-model="dob" placeholder="Select Date"></datepicker>
                                              <!-- <input type="text" class="form-control" id="exampleInputEmail1" v-model="dob" aria-describedby="emailHelp" placeholder="DOB"> -->
                                          </div>
                                          <div class="form-group">
                                              <label for="exampleInputEmail1">Town</label>
                                              <input type="text" class="form-control" id="exampleInputEmail1" v-model="town" aria-describedby="emailHelp" placeholder="Enter your town">
                                          </div>
                                          <div class="form-group">
                                              <label for="exampleInputEmail1" :class="{requiredCls : isPostCodeGiven}">Post code *</label>
                                              <input type="text" class="form-control" id="exampleInputEmail1"  v-model="postCode" aria-describedby="emailHelp" placeholder="Enter your post code">
                                          </div>
                                          <div class="form-group">
                                              <label for="exampleInputEmail1">Password</label>
                                              <input type="text" class="form-control" id="exampleInputEmail1"  v-model="password" aria-describedby="emailHelp" disabled>
                                          </div>
                                      </div>

                                      <div class="col-md-4">
                                          <div class="form-group">
                                              <label for="exampleInputEmail1" :class="{requiredCls : isMnameGiven}">Middle Name *</label>
                                              <input type="text" class="form-control" id="exampleInputEmail1" v-model="middleName" aria-describedby="emailHelp" placeholder="Enter your middle name">
                                          </div>
                                          <div class="form-group">
                                              <label for="exampleInputEmail1" :class="{requiredCls : isEmailGiven}">Email address *</label>
                                              <input type="email" class="form-control" id="exampleInputEmail1" v-model="email" aria-describedby="emailHelp" placeholder="Enter your email ID">
                                          </div>
                                          <div class="form-group">
                                              <label for="exampleInputEmail1">City</label>
                                              <input type="text" class="form-control" id="exampleInputEmail1" v-model="city" aria-describedby="emailHelp" placeholder="Enter your city name">
                                          </div>
                                          <div class="form-group">
                                              <label for="exampleInputPassword1" :class="{requiredCls : isTelephoneGiven}">Telephone *</label>
                                              <input type="text" class="form-control" id="exampleInputPassword1" v-model="telephone" placeholder="Enter telephone no">
                                          </div>
                                      </div>

                                  </div>

                                  <div class="custom-control custom-checkbox">
                                      <input type="checkbox" class="custom-control-input" id="customCheck1" v-model="isLivingTheCityLessYears" @click="previousAddressFun()">
                                      <label class="custom-control-label" for="customCheck1">Please select this box, if you are living this adress less than 3 years.</label>
                                  </div>

                                  <div class="row" v-if="previousAddress">
                                      <div class="col-md-12">
                                          <div class="row">
                                              <div class="col-md-12">
                                                  <div class="line"></div>
                                              </div>
                                          </div>

                                          <br>
                                          <h4 style="margin-bottom: 0px;">Please Provide Your Previous Address</h4>

                                          <div class="row">
                                              <div class="col-md-12">
                                                  <div class="line"></div>
                                              </div>
                                          </div>

                                          <div class="row" style="padding-top: 41px;">
                                              <div class="col-md-4">
                                                  <div class="form-group">
                                                      <label for="exampleInputEmail1">Door No</label>
                                                      <input type="text" class="form-control" id="exampleInputEmail1" v-model="prevDoor" aria-describedby="emailHelp" placeholder="Enter your door no">
                                                  </div>
                                                  <div class="form-group">
                                                      <label for="exampleInputEmail1">Town</label>
                                                      <input type="text" class="form-control" id="exampleInputEmail1" v-model="prevTown" aria-describedby="emailHelp" placeholder="Enter your town">
                                                  </div>
                                              </div>
                                              <div class="col-md-4">
                                                  <div class="form-group">
                                                      <label for="exampleInputEmail1">House/Flat No</label>
                                                      <input type="text" class="form-control" id="exampleInputEmail1" v-model="prevHouse" aria-describedby="emailHelp" placeholder="Enter your house/flat no">
                                                  </div>
                                                  <div class="form-group">
                                                      <label for="exampleInputEmail1">City</label>
                                                      <input type="text" class="form-control" id="exampleInputEmail1" v-model="prevCity" aria-describedby="emailHelp" placeholder="Enter your city">
                                                  </div>
                                              </div>
                                              <div class="col-md-4">
                                                  <div class="form-group">
                                                      <label for="exampleInputEmail1">Post Code</label>
                                                      <input type="text" class="form-control" id="exampleInputEmail1" v-model="prevPostCode" aria-describedby="emailHelp" placeholder="Enter your post code">
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>

                                  <br>
                                  <button type="button" class="btn submitBtn" v-if="!isEditClicked" @click="submitDetailsInfo">Submit</button>&nbsp;
                                  <button type="button" class="btn cancelBtn" v-if="!isEditClicked" @click="cancelDetailsInfo">Cancel</button>

                                  <button type="button" class="btn submitBtn" v-if="isEditClicked" @click="submitEditDetailsInfo">Submit</button>&nbsp;
                                  <button type="button" class="btn cancelBtn" v-if="isEditClicked" @click="cancelEditDetailsInfo">Cancel</button>

                              </form>
                          </div>

                      </div>

                      <div class="row" style="margin-top: 49px;" v-if="businessInfo">
                          <div class="col-md-12">

                              <form>

                                  <div class="row">

                                      <div class="col-md-4">

                                          <div class="form-group">
                                              <label for="customRadio1">Logo</label>
                                              <div class="uploadDiv">
                                                  <img src="/img/upload.png" alt=""> Upload Your Logo
                                                  <input type="file" name="file" class="uploadBtn"/>
                                              </div>
                                          </div>

                                          <div class="form-group">
                                              <label for="exampleInputEmail1">Company Name</label>
                                              <input type="text" class="form-control" id="exampleInputEmail1" v-model="companyName" aria-describedby="emailHelp" placeholder="Enter company name">
                                          </div>
                                          <div class="form-group">
                                              <label for="exampleInputEmail1">Town</label>
                                              <input type="text" class="form-control" id="exampleInputEmail1" v-model="bTown" aria-describedby="emailHelp" placeholder="Enter Town">
                                          </div>
                                      </div>

                                      <div class="col-md-4">
                                          <div class="form-group">
                                              <label for="exampleInputEmail1" :class="{requiredCls : isBusinessNameGiven}">Business Name *</label>
                                              <input type="text" class="form-control" id="exampleInputEmail1" v-model="businessName" aria-describedby="emailHelp" placeholder="Enter your bussiness name">
                                          </div>
                                          <div class="form-group">
                                              <label for="exampleInputEmail1">House/Flat no</label>
                                              <input type="text" class="form-control" id="exampleInputEmail1" v-model="bHouse" aria-describedby="emailHelp" placeholder="Enter house/flat no">
                                          </div>
                                          <div class="form-group">
                                              <label for="exampleInputEmail1">City</label>
                                              <input type="text" class="form-control" id="exampleInputEmail1" v-model="bCity" aria-describedby="emailHelp" placeholder="Enter your city">
                                          </div>
                                      </div>

                                      <div class="col-md-4">
                                          <div class="form-group">
                                              <label for="exampleInputEmail1">Sole/Limited</label>
                                              <input type="text" class="form-control" id="exampleInputEmail1" v-model="soloOrLimited" aria-describedby="emailHelp" placeholder="Enter sole/limited">
                                          </div>
                                          <div class="form-group">
                                              <label for="exampleInputEmail1" :class="{requiredCls : isBstreetGiven}">Street *</label>
                                              <input type="text" class="form-control" id="exampleInputEmail1" v-model="bStreet" aria-describedby="emailHelp" placeholder="Enter street">
                                          </div>
                                          <div class="form-group">
                                              <label for="exampleInputEmail1" :class="{requiredCls : isBpostCodeGiven}">Post Code *</label>
                                              <input type="text" class="form-control" id="exampleInputEmail1" v-model="bPostCode" aria-describedby="emailHelp" placeholder="Enter post code">
                                          </div>
                                      </div>

                                  </div>

                                  <div class="row">
                                      <div class="col-md-6">
                                          <div class="form-group">
                                              <label for="exampleFormControlSelect1">Business Type</label>
                                              <select class="form-control" id="exampleFormControlSelect1">
                                                  <option>Select business type</option>
                                                  <option>Takeway</option>
                                                  <option>Restaurant</option>
                                              </select>
                                          </div>
                                      </div>
                                      <div class="col-md-6">
                                          <div class="form-group">
                                              <label for="exampleFormControlSelect1">Service Type</label>
                                              <select class="form-control" id="exampleFormControlSelect1">
                                                  <option>Select service type</option>
                                                  <option>Online</option>
                                                  <option>Epos</option>
                                                  <option>Other</option>
                                              </select>
                                          </div>
                                      </div>
                                  </div>

                                  <div class="row">
                                      <div class="col-md-4">
                                          <div class="form-group">
                                              <label for="exampleInputEmail1" :class="{requiredCls : isBtelephoneGiven}">Telephone *</label>
                                              <input type="text" class="form-control" id="exampleInputEmail1" v-model="bTelephone" aria-describedby="emailHelp" placeholder="Enter telephone">
                                          </div>
                                      </div>
                                      <div class="col-md-4">
                                          <div class="form-group">
                                              <label for="exampleInputEmail1">Subscription end date</label>
                                              <datepicker v-model="subscriptionEndDate" placeholder="Select Date"></datepicker>
                                              <!-- <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter subscription end date"> -->
                                          </div>
                                      </div>
                                      <div class="col-md-4">
                                          <div class="form-group">
                                              <label for="exampleFormControlSelect1">Status Action</label>
                                              <select class="form-control" id="exampleFormControlSelect1">
                                                  <option>Select service type</option>
                                                  <option>Active</option>
                                                  <option>Inactive</option>
                                              </select>
                                          </div>
                                      </div>
                                  </div>

                                  <br>
                                  <button type="button" class="btn submitBtn" v-if="!isEditClicked" @click="submitDetailsInfo">Submit</button>&nbsp;
                                  <button type="button" class="btn cancelBtn" v-if="!isEditClicked" @click="cancelDetailsInfo">Cancel</button>

                                  <button type="button" class="btn submitBtn" v-if="isEditClicked" @click="submitEditDetailsInfo">Submit</button>&nbsp;
                                  <button type="button" class="btn cancelBtn" v-if="isEditClicked" @click="cancelEditDetailsInfo">Cancel</button>

                              </form>
                          </div>


                      </div>

                      <div class="row" style="margin-top: 49px;" v-if="bankDetails">
                          <div class="col-md-12">

                              <form>

                                  <div class="row">

                                      <div class="col-md-4">

                                          <div class="form-group">
                                              <label for="exampleInputEmail1">Bank Name</label>
                                              <input type="text" class="form-control" id="exampleInputEmail1" v-model="bankName" aria-describedby="emailHelp" placeholder="Enter your bank name">
                                          </div>
                                          <div class="form-group">
                                              <label for="exampleInputEmail1">Account Details</label>
                                              <input type="text" class="form-control" id="exampleInputEmail1" v-model="accountDetails" aria-describedby="emailHelp" placeholder="Enter your account no">
                                          </div>
                                      </div>

                                      <div class="col-md-4">
                                          <div class="form-group">
                                              <label for="exampleInputEmail1">Account Holder Name</label>
                                              <input type="text" class="form-control" id="exampleInputEmail1" v-model="accountHolderName" aria-describedby="emailHelp" placeholder="Enter account holder name">
                                          </div>

                                          <div class="custom-control custom-checkbox">
                                              <input type="checkbox" class="custom-control-input" v-model="isTermsAccepted" id="customCheck1">
                                              <label class="custom-control-label" for="customCheck1">Select checkbox to accept our terms</label>
                                          </div>
                                      </div>

                                      <div class="col-md-4">
                                          <div class="form-group">
                                              <label for="exampleInputEmail1">Sort Code</label>
                                              <input type="text" class="form-control" id="exampleInputEmail1" v-model="sortCode" aria-describedby="emailHelp" placeholder="Enter your sort code">
                                          </div>
                                          <div class="form-group">
                                              <label for="exampleInputEmail1">Signature (write your name)</label>
                                              <input type="email" class="form-control" id="exampleInputEmail1" v-model="signature" aria-describedby="emailHelp" placeholder="Write down your name here">
                                          </div>
                                      </div>

                                  </div>

                                  <br>
                                  <button type="submit" class="btn submitBtn" v-if="!isEditClicked" @click="submitDetailsInfo">Submit</button>&nbsp;
                                  <button type="submit" class="btn cancelBtn" v-if="!isEditClicked" @click="cancelDetailsInfo">Cancel</button>

                                  <button type="submit" class="btn submitBtn" v-if="isEditClicked" @click="submitEditDetailsInfo">Submit</button>&nbsp;
                                  <button type="submit" class="btn cancelBtn" v-if="isEditClicked" @click="cancelEditDetailsInfo">Cancel</button>

                              </form>
                          </div>


                      </div>
                  </div>

                </div>

            </div>

        </div>
    </div>

</template>

<script>
import axios from 'axios'
import ContentHeader from "@/components/ContentHeader";

import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
import * as lang from "vuejs-datepicker/src/locale";

import logout from './../../mixins/logoutMixins.js'

export default {
    components: {
      ContentHeader,
      Datepicker
    },
    data () {
        return {
            format: "d MMMM yyyy",
            ownerDetails: true,
            businessInfo: false,
            bankDetails: false,
            previousAddress: false,

            // owner info
            isMr: false,
            isMrs: false,
            firstName: '',
            username: '',
            password: '',
            middleName: '',
            lastName: '',
            dob: '',
            email: '',
            house: '',
            town: '',
            city: '',
            street: '',
            postCode: '',
            telephone: '',
            isLivingTheCityLessYears: false,
            prevCity: '',
            prevDoor: '',
            prevTown: '',
            prevHouse: '',
            prevPostCode: '',

            // business details
            logo: '',
            businessName: '',
            soloOrLimited: '',
            companyName: '',
            bHouse: '',
            bStreet: '',
            bTown: '',
            bCity: '',
            bPostCode: '',
            businessType: '',
            serviceType: '',
            bTelephone: '',
            subscriptionEndDate: '',
            statusAction: '',

            // bank details
            bankName: '',
            accountHolderName: '',
            sortCode: '',
            accountDetails: '',
            isTermsAccepted: false,
            signature: '',

            // required fields
            isFnameGiven: false,
            isLnameGiven: false,
            isMnameGiven: false,
            isPostCodeGiven: false,
            isDOBgiven: false,
            isEmailGiven: false,
            isTelephoneGiven: false,
            isBusinessNameGiven: false,
            isBstreetGiven: false,
            isBpostCodeGiven: false,
            isBtelephoneGiven: false,

            // edit.
            isEditClicked: false,
            editId: '',

            jwtToken: this.$cookies.get('token') || null
        }
    },
    created () {
        this.ownerDetailsFun()
        this.isEditDataClicked()

        console.log('owner create state...');
        console.log(process.env.VUE_APP_API_URL);
    },
    methods: {
        isEditDataClicked() {

            if (localStorage.getItem('editableData')) {
                this.isEditClicked = true

                let editableData = localStorage.getItem('editableData')

                editableData = JSON.parse(editableData)

                this.editId = editableData.id

                this.username = editableData.owner.userName
                this.password = editableData.owner.password
                this.firstName = editableData.owner.firstName
                this.middleName = editableData.owner.middleName
                this.lastName = editableData.owner.lastName
                this.telephone = editableData.owner.phone
                this.email = editableData.owner.email
                this.dob = editableData.owner.dateOfBirth
                this.postCode = editableData.address.postcode
                console.log('post code : ', editableData.owner.userName);

                this.businessName = editableData.restaurantName
                this.bStreet = editableData.address.streetNo
                this.bPostCode = editableData.address.postcode
                this.bTelephone = editableData.phone

                setTimeout(()=>{ localStorage.removeItem('editableData') },1000)

            }
        },
        ownerDetailsFun () {
            console.log('1')
            this.ownerDetails = true
            this.businessInfo = false
            this.bankDetails = false

            let url = process.env.VUE_APP_API_URL+'/api/restaurant'

            axios
            .get(url,
            {
                headers: {
                    Authorization: `Bearer ${this.jwtToken}`,
                }
            })
            .then((response) => {
                console.log(response)

                if (response.status === 403) {
                    let logoutResponse = this.logout()
                    if (logoutResponse === 1) {
                        this.$router.push({ path: '/login' })
                    }
                }
            })
        },
        businessInfoFun () {
            console.log('2')
            this.ownerDetails = false
            this.businessInfo = true
            this.bankDetails = false
        },
        bankDetailsFun () {
            console.log('3')
            this.ownerDetails = false
            this.businessInfo = false
            this.bankDetails = true
        },
        previousAddressFun () {
            this.previousAddress = !this.previousAddress
        },
        // edit details
        submitEditDetailsInfo() {
            console.log(this.dob)
            // console.log(new Date(this.dob))
            let dateObj = new Date(this.dob)
            console.log(dateObj.getMonth()+1);
            console.log(dateObj.getDate());
            console.log(dateObj.getFullYear());

            let month = dateObj.getMonth()+1

            let birthDate = dateObj.getDate()+'/'+month+'/'+dateObj.getFullYear()

            console.log(birthDate);

            if (this.firstName && this.middleName && this.lastName && this.dob && this.email && this.postCode && this.telephone &&
                this.businessName && this.bStreet && this.bPostCode && this.bTelephone) {

                    console.log('every required field is given by user.');

                    // request url
                    let url = process.env.VUE_APP_API_URL+'/api/restaurant/update'

                    // request data
                    let dataToSubmit = {
                        "id": this.editId,
                        "createdAt": "",
                        "updatedAt": "",
                        "img": "",
                        "restaurantName": this.businessName,
                        "phone": this.bTelephone,
                        "email": this.email,
                        "webAddress": "",
                        "landLine": "",
                        "logo": "",
                        "TIN": "",
                        "termsCond": "",
                        "address": {
                            "type": "",
                            "country": "BD",
                            "region": "",
                            "city": this.city,
                            "area": this.town,
                            "door": "",
                            "building": this.house,
                            "streetNo": this.bStreet,
                            "postcode": this.postCode,
                            "isPrevious": ""
                        },
                        "tableProcessingTime": "",
                        "expressProcessingTime": "",
                        "waitingProcessingTime": "",
                        "collectionProcessingTime": "",
                        "deliveryProcessingTime": "",
                        "defaultVAT": "",
                        "vatRegNo": "",
                        "defaultDiscount": "",
                        "serviceCharge": "",
                        "defaultCCFee": "",
                        "bagFee": "",
                        "owner": {
                            "createdAt": "",
                            "updatedAt": "",
                            "userName": this.firstName,
                            "firstName": this.firstName,
                            "middleName": this.middleName,
                            "lastName": this.lastName,
                            "dateOfBirth": birthDate,
                            "phone": this.telephone,
                            "email": this.email
                        },
                        "bankDetails": {
                            "bankName": this.bankName,
                            "holderName": this.accountHolderName,
                            "sortCode": "",
                            "acceptTerms": this.isTermsAccepted,
                            "signature": this.signature
                        }
                    }

                    // request header
                    let requestHeader = {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.jwtToken}`,
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Credentials": true,
                        "Access-Control-Allow-Headers": "*",
                        'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                    }

                    console.log(dataToSubmit)
                    console.log(requestHeader)

                    // send request
                    axios
                    .post(url, dataToSubmit, {
                        headers: requestHeader
                    })
                    .then((data) => {
                        console.log(data)

                        if (data.status == 200) {
                            this.$toastr.s("Updated Successfully", "Success");
                            setTimeout(()=> {window.location = '/admin/owner-list'}, 1000)
                        }

                        if (data.status === 403) {
                            let logoutResponse = this.logout()
                            if (logoutResponse === 1) {
                                this.$router.push({ path: '/login' })
                            }
                        }
                    })

            }
            else {

                console.log('fields are required.');
                console.log(this.firstName +' || '+ this.middleName +' || '+ this.lastName +' || '+ this.dob +' || '+ this.email +' || '+ this.postCode +' || '+ this.telephone +' || '+
                    this.businessName +' || '+ this.bStreet +' || '+ this.bPostCode +' || '+ this.bTelephone);

                if (this.lastName) {
                    this.isLnameGiven = false
                }
                else {
                    this.isLnameGiven = true
                }

                if (this.firstName) {
                    this.isFnameGiven = false
                }
                else {
                    this.isFnameGiven = true
                }

                if (this.middleName) {
                    this.isMnameGiven = false
                }
                else {
                    this.isMnameGiven = true
                }

                if (this.dob) {
                    this.isDOBgiven = false
                }
                else {
                    this.isDOBgiven = true
                }

                if (this.email) {
                    this.isEmailGiven = false
                }
                else {
                    this.isEmailGiven = true
                }

                if (this.postCode) {
                    this.isPostCodeGiven = false
                }
                else {
                    this.isPostCodeGiven = true
                }

                if (this.telephone) {
                    this.isTelephoneGiven = false
                }
                else {
                    this.isTelephoneGiven = true
                }

                if (this.businessName) {
                    this.isBusinessNameGiven = false
                }
                else {
                    this.isBusinessNameGiven = true
                }

                if (this.bStreet) {
                    this.isBstreetGiven = false
                }
                else {
                    this.isBstreetGiven = true
                }

                if (this.bPostCode) {
                    this.isBpostCodeGiven = false
                }
                else {
                    this.isBpostCodeGiven = true
                }

                if (this.bTelephone) {
                    this.isBtelephoneGiven = false
                }
                else {
                    this.isBtelephoneGiven = true
                }

            }

        },
        cancelEditDetailsInfo() {
            window.location = './admin/owner-list'
        },
        // owner details
        submitDetailsInfo() {
            console.log(this.dob)
            // console.log(new Date(this.dob))
            let dateObj = new Date(this.dob)
            console.log(dateObj.getMonth()+1);
            console.log(dateObj.getDate());
            console.log(dateObj.getFullYear());

            let month = dateObj.getMonth()+1

            let birthDate = dateObj.getDate()+'/'+month+'/'+dateObj.getFullYear()

            console.log(birthDate);

            if (this.firstName && this.middleName && this.lastName && this.dob && this.email && this.postCode && this.telephone &&
                this.businessName && this.bStreet && this.bPostCode && this.bTelephone) {

                    // console.log('every required field is given by user.');
                    // console.log('Business Name : '+this.businessName);

                    // request url
                    let url = process.env.VUE_APP_API_URL+'/api/restaurant/create'

                    // request data
                    let dataToSubmit = {
                        "createdAt": "",
                        "updatedAt": "",
                        "restaurantName": this.businessName,
                        "img": "",
                        "phone": this.bTelephone,
                        "email": this.email,
                        "webAddress": "",
                        "landLine": "",
                        "logo": "",
                        "TIN": "",
                        "termsCond": "",
                        "address": {
                            "type": "",
                            "country": "BD",
                            "region": "",
                            "city": this.city,
                            "area": this.town,
                            "door": "",
                            "building": this.house,
                            "streetNo": this.street,
                            "postcode": this.postCode,
                            "isPrevious": ""
                        },
                        "tableProcessingTime": "",
                        "expressProcessingTime": "",
                        "waitingProcessingTime": "",
                        "collectionProcessingTime": "",
                        "deliveryProcessingTime": "",
                        "defaultVAT": "",
                        "vatRegNo": "",
                        "defaultDiscount": "",
                        "serviceCharge": "",
                        "defaultCCFee": "",
                        "bagFee": "",
                        "owner": {
                            "createdAt": "",
                            "updatedAt": "",
                            "userName": this.firstName,
                            "firstName": this.firstName,
                            "middleName": this.middleName,
                            "lastName": this.lastName,
                            "dateOfBirth": birthDate,
                            "phone": this.telephone,
                            "email": this.email
                        },
                        "bankDetails": {
                            "bankName": this.bankName,
                            "holderName": this.accountHolderName,
                            "sortCode": "",
                            "acceptTerms": this.isTermsAccepted,
                            "signature": this.signature
                        }
                    }

                    // request header
                    let requestHeader = {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.jwtToken}`,
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Credentials": true,
                        "Access-Control-Allow-Headers": "*",
                        'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                    }

                    console.log(dataToSubmit)
                    console.log(requestHeader)

                    // send request
                    axios
                    .post(url, dataToSubmit, {
                        headers: requestHeader
                    })
                    .then((data) => {
                        console.log(data)

                        if (data.status == 200) {
                            this.$toastr.s("Successfully Created.", "Success");
                            setTimeout(()=> {window.location = '/admin/owner-list'}, 1000)
                        }

                        if (data.status === 403) {
                            let logoutResponse = this.logout()
                            if (logoutResponse === 1) {
                                this.$router.push({ path: '/login' })
                            }
                        }
                    })

            }
            else {

                console.log('fields are required.');
                console.log(this.firstName +' || '+ this.middleName +' || '+ this.lastName +' || '+ this.dob +' || '+ this.email +' || '+ this.postCode +' || '+ this.telephone +' || '+
                    this.businessName +' || '+ this.bStreet +' || '+ this.bPostCode +' || '+ this.bTelephone);

                if (this.lastName) {
                    this.isLnameGiven = false
                }
                else {
                    this.isLnameGiven = true
                }

                if (this.firstName) {
                    this.isFnameGiven = false
                }
                else {
                    this.isFnameGiven = true
                }

                if (this.middleName) {
                    this.isMnameGiven = false
                }
                else {
                    this.isMnameGiven = true
                }

                if (this.dob) {
                    this.isDOBgiven = false
                }
                else {
                    this.isDOBgiven = true
                }

                if (this.email) {
                    this.isEmailGiven = false
                }
                else {
                    this.isEmailGiven = true
                }

                if (this.postCode) {
                    this.isPostCodeGiven = false
                }
                else {
                    this.isPostCodeGiven = true
                }

                if (this.telephone) {
                    this.isTelephoneGiven = false
                }
                else {
                    this.isTelephoneGiven = true
                }

                if (this.businessName) {
                    this.isBusinessNameGiven = false
                }
                else {
                    this.isBusinessNameGiven = true
                }

                if (this.bStreet) {
                    this.isBstreetGiven = false
                }
                else {
                    this.isBstreetGiven = true
                }

                if (this.bPostCode) {
                    this.isBpostCodeGiven = false
                }
                else {
                    this.isBpostCodeGiven = true
                }

                if (this.bTelephone) {
                    this.isBtelephoneGiven = false
                }
                else {
                    this.isBtelephoneGiven = true
                }

            }

        },
        cancelDetailsInfo() {
            // owner detailsthis.
            this.isMr = false
            this.isMrs = false
            this.firstName = ''
            this.middleName = ''
            this.lastName = ''
            this.dob = ''
            this.email = ''
            this.house = ''
            this.town = ''
            this.city = ''
            this.street = ''
            this.postCode = ''
            this.telephone = ''
            this.isLivingTheCityLessYears = false
            this.prevCity = ''
            this.prevDoor = ''
            this.prevTown = ''
            this.prevHouse = ''
            this.prevPostCode = ''

            // business details
            this.logo = ''
            this.businessName = ''
            this.soloOrLimited = ''
            this.companyName = ''
            this.bHouse = ''
            this.bStreet = ''
            this.bTown = ''
            this.bCity = ''
            this.bPostCode = ''
            this.businessType = ''
            this.serviceType = ''
            this.bTelephone = ''
            this.subscriptionEndDate = ''
            this.statusAction = ''

            // bank details
            this.bankName = ''
            this.accountHolderName = ''
            this.sortCode = ''
            this.accountDetails = ''
            this.isTermsAccepted = false
            this.signature = ''
        }
    }
}
</script>

<style scoped>
.content{padding: 10px 0}
.header {
    position: absolute;
    width: 100%;
    height: 69px;

    background: #FFEDE3;
    border: 1px solid #FFD8BC;
    box-sizing: border-box;
    border-radius: 5px;
}

.headerText {
    position: absolute;
    width: 96px;
    height: 19px;
    left: 28px;
    top: 20px;

    color: #FE750F;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
}

.btnGroup {
    margin-top: 91px;
    text-align: center;
}

.btnGroupMargin {
    margin-right: 20px;
}

.line {
    position: relative;
    width: 100%;
    height: 0px;
    top: 25px;

    border: 1px solid #FFE9FF;
}

.uploadDiv {
  position: relative;
  overflow: hidden;
  color: black;
  border: none;
}
.uploadBtn {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}

.submitBtn {
    background-color: #E317E3;
    color: white;
    border-color:  #E317E3;
}

.cancelBtn {
    background-color: #797979;
    color: white;
    border-color:  #797979;
}

.optionBtn {
    background: #FFF5FF;
    border: 1px solid #E317E3;
    color: #E317E3;
}

.requiredCls {
    color: red;
}

</style>

<style>
    .vdp-datepicker > div > input {
        background-color: #FFFFFF;
        border: 1px solid #E3E3E3;
        border-radius: 4px;
        color: #565656;
        padding: 8px 12px;
        height: 40px;
        -webkit-box-shadow: none;
        box-shadow: none;
        width: 100%;
    }
</style>
