<template>
    <div class="container">
        <div class="row justify-content-center" style="margin-top: 20%;">
            <div class="col-md-1">

            </div>
            <div class="col-md-10">
                <div class="card">
                    <div class="card-body" style="padding: 3% !important;">
                        <div class="logo" style="text-align: center; margin-top: 15px !important; margin-bottom: 20px !important;">
                            <a href="#" class="simple-text logo__container">
                                <div class="logo-img">
                                    <img src="/img/logo.jpeg" width="100%" alt="">
                                </div>
                            </a>
                        </div>
                        <form>
                            <div class="form-group">
                                <label for="exampleInputEmail1">User Name</label>
                                <input type="text" class="form-control" v-model="getUserName" placeholder="Enter user name" required>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputPassword1">Password</label>
                                <input type="password" class="form-control" v-model="getPassword" placeholder="Enter password" required>
                            </div>
                            <center>
                                <button type="button" class="btn" style="color: #FE750F; border-color: #FE750F;" @click="checkRequirements()">Login</button>
                            </center>


                        </form>
                    </div>
                </div>

            </div>
            <div class="col-md-1">

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data () {
        return {
            userName: 'ADMIN_0909_@#_super',
            password: '307378',
            getUserName: '',
            getPassword: ''
        }
    },
    created () {
        this.checkIsLogin()
    },
    methods: {
        checkIsLogin () {
            if (localStorage.getItem('isLoggedIn') == 'true') {
                this.$router.push({ path: '/admin/owner-list' })
            }
        },
        checkRequirements () {
            if (this.getUserName !== '' && this.getPassword !== '') {

                const url = process.env.VUE_APP_API_URL+'/api/user/loginAsAdmin'

                const requestHeader = {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": true,
                    "Access-Control-Allow-Headers": "*",
                    'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                }

                const dataToSubmit = {
                    userName: this.getUserName,
                    password: this.getPassword
                }

                axios({
                    method: 'post',url: url,data: dataToSubmit
                },
                {
                    headers: requestHeader
                })
                .then((data) => {
                    localStorage.setItem('isLoggedIn', true)
                    this.$cookies.set('token', data.data.token, '1Y')
                    setTimeout(() => {
                        this.$router.push({ path: '/admin/owner-list' })
                    }, 500)
                })
            }
            else {
                this.$toastr.w("Something went wrong! Please try again.", "Warning");
            }
        }
    }
}
</script>

<style scoped>
</style>
