<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <a class="home-link" href="#">
        <i class="fa fa-home" aria-hidden="true"></i>
        <span class="d-lg-block">&nbsp;Home</span>
      </a>
      <div class="collapse navbar-collapse justify-content-end">
        <ul class="navbar-nav ml-auto">
          <!-- <li class="nav-item">
            <a class="nav-link" href="#">
              <i class="fa fa-question-circle" aria-hidden="true"></i>
              <span class="d-lg-block">Support</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
              <i class="fa fa-user-circle" aria-hidden="true"></i>
              <span class="d-lg-block">My Profile</span>
            </a>
          </li> -->
          <li class="nav-item">
            <a href="#" class="nav-link" @click="logoutUser()">
              <i class="fa fa-sign-out" aria-hidden="true"></i>
              <span class="d-lg-block">Log out</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
  import logout from './../mixins/logoutMixins.js'

  export default {
    computed: {
      routeName () {
        const {name} = this.$route
        return this.capitalizeFirstLetter(name)
      }
    },
    data () {
      return {
        activeNotifications: false
      }
    },
    mixins: [logout],
    methods: {
        logoutUser() {
            // localStorage.setItem('isLoggedIn', false)

            // this.$cookies.remove('token')

            // this.$toastr.s("You have successfully logged out!", "Success");

            // this.$router.push({ path: '/login' })

            let logoutResponse = this.logout()
            
            if (logoutResponse === 1) {
                this.$router.push({ path: '/login' })
            }
        },
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleNotificationDropDown () {
        this.activeNotifications = !this.activeNotifications
      },
      closeDropDown () {
        this.activeNotifications = false
      },
      toggleSidebar () {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar () {
        this.$sidebar.displaySidebar(false)
      }
    }
  }

</script>
<style scoped>
nav{
  width: calc(100% - 10px);
  height: 71px;
  top: 0;
  font-family: Roboto;
  background: #555555;
  margin-left: 10px;
  border-radius: 0 0 5px 5px;
}
nav .home-link .fa-home{
  position: absolute;
  width: 28px;
  height: 28px;
  left: 16px;
  top: 20px;
  font-size: 28px;
  line-height: 28px;
  display: flex;
  align-items: flex-end;
  color: #FFFFFF;
}
nav .home-link span{
  position: absolute;
  width: 49px;
  height: 21px;
  left: 50px;
  top: 25px;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: flex-end;
  color: #FFFFFF;
}
nav .nav-link .fa-question-circle,
nav .nav-link .fa-user-circle,
nav .nav-link .fa-sign-out{
  height: 28px;
  margin-left: 3rem;
  font-size: 28px;
  line-height: unset !important;
  color: #FFFFFF;
}
nav .nav-link span{
  height: 21px;
  padding-left: 10px;
  font-size: 18px;
  color: #FFFFFF;
}
</style>
