<template>
  <div class="content">
    <div class="container" style="padding-left: 10px">
      <div class="row" v-if="!isToppingsClicked && !isModifierClicked && !isPrintOptionClicked">
      <div class="col-md-12">
          <card class="strpied-tabled-with-hover"
                body-classes="table-responsive"
          >
            <content-header>
              <p class="mb-0" style="color: #FE750F;">Menu Management - Custom Deal Set</p>
            </content-header>

            <table class="table mt-5">
              <thead>
              <tr>
                <th>Title</th>
                <th>Minimum</th>
                <th>Maximum</th>
                <th>Rel</th>
                <th>Qty</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <tr class="main-row">
                <td>Set 1 - Item</td>
                <td>2</td>
                <td>4</td>
                <td>Poly</td>
                <td></td>
                <td>Status</td>
                <td>
                  <a class="delete-btn text-center">
                    <img src="img/delete.png" alt="Image" title="Delete">
                  </a>
                  <a class="edit-btn text-center ml-3">
                    <img src="img/edit.png" alt="Image" title="Edit">
                  </a>
                    <i class="fa fa-chevron-down arrow-down ml-3 pt-1" v-if="!setItemCollapse" @click="setItemListShow()"></i>
                    <i class="fa fa-chevron-up arrow-up ml-3 pt-1" v-if="setItemCollapse" @click="setItemListShow()"></i>
                </td>
              </tr>
              <tr class="hidden-row" v-if="showHiddenRow">
                <td>
                  <ul>
                    <li>Coke 330ml bottle</li>
                    <li>Coke 330ml bottle</li>
                    <li>Coke 330ml bottle</li>
                    <li>Coke 330ml bottle</li>
                  </ul>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <ul>
                    <li>1</li>
                    <li>1</li>
                    <li>1</li>
                    <li>1</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Active</li>
                    <li>Active</li>
                    <li>Active</li>
                    <li>Active</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>
                      <a class="cross-btn text-center ml-3">
                        <img src="img/cross.png" alt="Image" title="Remove">
                      </a>
                    </li>
                    <li>
                      <a class="cross-btn text-center ml-3">
                        <img src="img/cross.png" alt="Image" title="Remove">
                      </a>
                    </li>
                    <li>
                      <a class="cross-btn text-center ml-3">
                        <img src="img/cross.png" alt="Image" title="Remove">
                      </a>
                    </li>
                    <li>
                      <a class="cross-btn text-center ml-3">
                        <img src="img/cross.png" alt="Image" title="Remove">
                      </a>
                    </li>
                  </ul>
                </td>
              </tr>
              </tbody>
            </table>
            <form class="form-inline" action="" style="width: 96%; margin: 0 auto">
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Enter set name" id="">
                </div>
                <div class="form-group">
                  <select class="form-control">
                    <option selected disabled>Item</option>
                    <option value="Item">Item</option>
                    <option value="Item">Item 1</option>
                    <option value="Item">Item 2</option>
                    <option value="Item">Item 3</option>
                  </select>
                </div>
                <div class="form-group ml-3">
                  <input type="text" placeholder="minimum" class="form-control">
                </div>
                <div class="form-group ml-3">
                  <input type="text" placeholder="maximum" class="form-control">
                </div>
                <div class="form-group">
                  <a class="tick-btn text-center ml-5">
                    <img src="img/tick.png" alt="Image" title="Add">
                  </a>
                  <a class="cross-btn text-center ml-3">
                    <img src="img/cross.png" alt="Image" title="Remove">
                  </a>
                  <a class="plus-btn text-center ml-3">
                    <img src="img/plus.png" alt="Image" title="Add">
                  </a>
                </div>
            </form>

          </card>
        </div>

      </div>

      <MenuManagementModal
        ref="MenuManagementModal"
        v-bind:modalTitle="modalTitle"
        v-bind:isCategoryModal="isCategoryModal"
        v-bind:isItemModal="isItemModal"
        v-bind:isSelectionModal="isSelectionModal"
        v-bind:isToppingsModal="isToppingsModal"
      />
      <MenuToppings v-if="isToppingsClicked"  @toppingsEvent="addNewToppings" />

      <MenuModifier v-if="isModifierClicked" />

      <MenuPrintOption v-if="isPrintOptionClicked" />

      <div class="row bottomOption">
        <div class="col-md-3" style="text-align: center;">
          <div class="row" style="background: #FFFFFF; padding-top: 18px; padding-bottom: 18px; margin-left: 1px;">
            <div class="col-md-12">
              <button type="button" class="btn menuMgmtBtn" @click="addNewSelection()">Selection</button>
            </div>
          </div>

        </div>
        <div class="col-md-3" style="text-align: center;">
          <div class="row" style="background: #FFFFFF; padding-top: 18px; padding-bottom: 18px; margin-left: 1px;">
            <div class="col-md-12">
              <button type="button" class="btn menuMgmtBtn" @click="showToppingsDiv()">Toppings</button>
            </div>
          </div>

        </div>
        <div class="col-md-3" style="text-align: center;">
          <div class="row" style="background: #FFFFFF; padding-top: 18px; padding-bottom: 18px; margin-left: 1px;">
            <div class="col-md-12">
              <button type="button" class="btn menuMgmtBtn" @click="showModifierDiv()">Modifier</button>
            </div>
          </div>

        </div>
        <div class="col-md-3" style="text-align: center;">
          <div class="row" style="background: #FFFFFF; padding-top: 18px; padding-bottom: 18px; margin-left: 1px; margin-right: 1px;">
            <div class="col-md-12">
              <button type="button" class="btn menuMgmtBtn" @click="showPrintOptionDiv()">Print Option Title</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Card from 'src/components/Cards/Card.vue'
import ContentHeader from "src/components/ContentHeader";
import MenuManagementModal from './MenuManagementModal.vue'
import MenuToppings from './MenuToppings.vue'
import MenuModifier from './MenuModifier.vue'
import MenuPrintOption from './MenuPrintOption.vue'

export default {
  components: {
    Card,
    ContentHeader,
    MenuManagementModal,
    MenuToppings,
    MenuModifier,
    MenuPrintOption
  },
  data () {
    return {
      setItemCollapse: false,
      showHiddenRow: false,
      categoryOption: false,
      itemOption: false,
      categoryCollapse: false,
      itemCollapse: false,
      showModal: false,
      modalTitle: '',
      isCategoryModal: false,
      isItemModal: false,
      isSelectionModal: false,
      isToppingsModal: false,
      isToppingsClicked: false,
      isModifierClicked: false,
      isPrintOptionClicked: false
    }
  },
  methods: {
    setItemListShow() {
      this.setItemCollapse = !this.setItemCollapse,
      this.showHiddenRow = !this.showHiddenRow
    },
    addNewSelection () {
      this.modalTitle = 'Add New Selection'
      this.isCategoryModal = false
      this.isItemModal = false
      this.isSelectionModal = true
      this.isToppingsModal = false
      // this.bus.$emit('categoryModal')
      this.$refs.MenuManagementModal.categoryItemModal()
    },
    addNewToppings () {
      this.modalTitle = 'Add New Toppings'
      this.isCategoryModal = false
      this.isItemModal = false
      this.isSelectionModal = false
      this.isToppingsModal = true
      // this.bus.$emit('categoryModal')
      this.$refs.MenuManagementModal.categoryItemModal()
    },
    showToppingsDiv () {
      this.isToppingsClicked = true
      this.isModifierClicked = false
      this.isPrintOptionClicked = false
    },
    showModifierDiv () {
      this.isToppingsClicked = false
      this.isModifierClicked = true
      this.isPrintOptionClicked = false
    },
    showPrintOptionDiv () {
      this.isToppingsClicked = false
      this.isModifierClicked = false
      this.isPrintOptionClicked = true
    },
  }
}
</script>
<style scoped>
.delete-btn{
  width: 27px;
  height: 27px;
  background: #FFF0ED;
  box-shadow: 0px 6px 10px rgba(95, 95, 95, 0.18);
  border-radius: 2px;
}
.edit-btn{
  width: 27px;
  height: 27px;
  background: #787878;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.18);
  border-radius: 2px;
}
.cross-btn{
  width: 27px;
  height: 27px;
  border-radius: 2px;
  padding: 0 5px;
  background-color: #FFF0ED;
}
.tick-btn{
  width: 27px;
  height: 27px;
  border-radius: 2px;
  padding: 0 5px;
  background-color: #00BC09;
}
.plus-btn{
  width: 27px;
  height: 27px;
  border-radius: 2px;
  padding: 0 5px;
}
.arrow-down{
  width: 27px;
  height: 27px;
  color: #E317E3;
  background: #FFEFFF;
  border-radius: 2px;
  cursor: pointer;
}
.arrow-up{
  width: 27px;
  height: 27px;
  color: #52575C;
  background: #ECECEC;
  border-radius: 2px;
  cursor: pointer;
}
.content{padding: 10px 0}
table{width: 96%; margin: 0 auto}
table thead tr{padding: 15px; background-color: #FFF5FF}
table thead tr th{
  padding: 15px 0;
  font-size: 16px;
  color: #E317E3;
}
input, select{background-color: #F1F1F1}
.main-row td{font-weight: 700}
.bottomOption {
  position: absolute;
  bottom: 7%;
  width: 100%;
}
.menuMgmtBtn {
  background: #FFF5FF;
  border: 1px solid #E317E3;
  color: #E317E3;
}
ul li{list-style-type: none}
/*.hidden-row{display: none}*/
</style>
