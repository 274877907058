<template>
    <div class="row">
        <div class="col-md-6">
            <div class="header" style="position: relative; margin-bottom: 7rem">

                <div class="row " style="margin-top: 20px; margin-left: 28px; margin-bottom: 26px; color: #FE750F;">
                    <div class="col-md-6" style="padding-top: 5px;">Modifier</div>
                    <div class="col-md-6" style="text-align: center;">
                        <button type="button" class="btn btn-sm headerBtnStyle" @click="addNewModifier()">Add New Modifier</button>
                    </div>
                </div>

                <div class="row" style="background: #F0F0F0; margin-left: 2px; margin-right: 2px; border-top: 1px solid #FFD8BC;">
                    <div class="col-md-12">
                        <div style="padding-top: 18px; padding-bottom: 18px; padding-left: 40px;">
                            <span> Title </span>
                            <span style="float: right; padding-right: 127px;">Action </span>
                        </div>
                    </div>
                </div>

                <div class="row" style="background: #FFFFFF; margin-left: 2px; margin-right: 2px;">
                    <div class="col-md-12">
                        <div style="padding-top: 18px; padding-bottom: 18px; padding-left: 40px;" v-for="(item, index) in modifierDataList">
                            <span> {{ item.label }} </span>
                            <span style="float: right; padding-right: 127px;"><i class="fa fa-edit" style="cursor: pointer;" @click="editItem(item.id)"></i> &nbsp; <i class="fa fa-trash" style="color: #F4522D; cursor: pointer;" @click="deleteItem(item.id)"></i> </span>
                        </div>
                    </div>
                </div>

                <div class="row" style="background: #FFFFFF; margin-left: 2px; margin-right: 2px;" v-if="isNewAdd">
                    <div class="col-md-12">
                        <div style="padding-top: 18px; padding-bottom: 18px; padding-left: 40px;">
                            <input type="text" v-model="newName">
                            <span style="float: right; padding-right: 127px;"><i class="fa fa-check-square" style="cursor: pointer; color: #F4522D;" @click="addItem()"></i> &nbsp; <i class="fa fa-times" style="cursor: pointer; color: #F4522D;" @click="cancel()"></i> </span>
                        </div>
                    </div>
                </div>
                <div class="row" style="background: #FFFFFF; margin-left: 2px; margin-right: 2px;" v-if="isEditItem">
                    <div class="col-md-12">
                        <div style="padding-top: 18px; padding-bottom: 18px; padding-left: 40px;">
                            <input type="text" v-model="editName">
                            <span style="float: right; padding-right: 127px;"><i class="fa fa-check-square" style="cursor: pointer; color: #F4522D;" @click="updateItem()"></i> &nbsp; <i class="fa fa-times" style="cursor: pointer; color: #F4522D;" @click="cancelEdit()"></i> </span>
                        </div>
                    </div>
                </div>

            </div>

        </div>
        <div class="col-md-6">

        </div>
    </div>
</template>

<script>
import axios from 'axios'
import logout from './../../mixins/logoutMixins.js'

export default {
    data () {
        return {
            isNewAdd: false,
            newName: '',
            restaurantId: parseInt(localStorage.getItem('restaurantId')),
            modifierDataList: [],
            editName: '',
            isEditItem: false,
            editItemId: '',
            jwtToken: this.$cookies.get('token') || null
        }
    },
    created () {
        this.modifierList()
    },
    methods: {
        addNewModifier () {
            this.isNewAdd = true
        },
        cancel () {
            this.isNewAdd = false
        },
        addItem () {
            let url = process.env.VUE_APP_API_URL+`/api/modifier/create`

            let dataToSubmit = {
                "restaurantId": this.restaurantId,
                "label": this.newName,
                "resourceRef": "toppings-1",
                "refCode": 3000,
                "imgURL": "",
                "iconURL": "",
                "description": "",
                "categoryId": 0,
                "itemId": 0,
                "selectionId": 0,
                "toppingsId": 3000,
                "modifierId": 0,
                "mealDealId": 0,
                "setId": 0,
                "printOptionId": 1,
                "printOptionTitle": "",
                "terminalOption": "",
                "status": "Active",
                "basePrice": "1.00",
                "toppingsCategory": "Top1",
                "mealAssignFor": "",
                "toppingsCategoryId": 1,
                "baseQty": 1,
                "minQty": 0,
                "maxQty": 0,
                "dataType": "Modifier",
                "mealDealType": "",
                "displayOrder": 1,
                "createdAt": "3 Sep 2020 11:05:30",
                "updatedAt": "30 Sep 2020 11:05:30",
                "children": []
            }

            // request header
            let requestHeader = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.jwtToken}`,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Headers": "*",
                'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            }

            axios
            .post(url, dataToSubmit,
            {
                headers: requestHeader
            })
            .then((data)=> {
                console.log(data);

                if (data.status == 200) {
                    this.$toastr.s("Created Successfully", "Success");

                    this.isNewAdd = false

                    this.modifierList()
                }

                if (data.status === 403) {
                    let logoutResponse = this.logout()
                    if (logoutResponse === 1) {
                        this.$router.push({ path: '/login' })
                    }
                }
            })
        },
        editItem (val) {
            this.editItemId = val
            this.isEditItem = true

            for (var i = 0; i < this.modifierDataList.length; i++) {
                if (this.modifierDataList[i].id == val) {
                    this.editName = this.modifierDataList[i].label
                }
            }
        },
        cancelEdit () {
            this.editItemId = ''
            this.isEditItem = false
        },
        updateItem () {
            console.log('updateItem called');

            let url = process.env.VUE_APP_API_URL+`/api/modifier/update`

            let dataToSubmit = {
                "id": this.editItemId,
                "restaurantId": this.restaurantId,
                "label": this.editName,
                "resourceRef": "toppings-1",
                "refCode": 3000,
                "imgURL": "",
                "iconURL": "",
                "description": "",
                "categoryId": 0,
                "itemId": 0,
                "selectionId": 0,
                "toppingsId": 3000,
                "modifierId": 0,
                "mealDealId": 0,
                "setId": 0,
                "printOptionId": 1,
                "printOptionTitle": "",
                "terminalOption": "",
                "status": "Active",
                "basePrice": "1.00",
                "toppingsCategory": "Top1",
                "mealAssignFor": "",
                "toppingsCategoryId": 1,
                "baseQty": 1,
                "minQty": 0,
                "maxQty": 0,
                "dataType": "Modifier",
                "mealDealType": "",
                "displayOrder": 1,
                "createdAt": "3 Sep 2020 11:05:30",
                "updatedAt": "30 Sep 2020 11:05:30",
                "children": []
            }

            // request header
            let requestHeader = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.jwtToken}`,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Headers": "*",
                'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            }

            axios
            .post(url, dataToSubmit,
            {
                headers: requestHeader
            })
            .then((data)=> {
                console.log(data);

                if (data.status == 200) {
                    this.$toastr.s("Updated Successfully", "Success");

                    this.editItemId = ''
                    this.isEditItem = false
                    this.modifierList()
                }

                if (data.status === 403) {
                    let logoutResponse = this.logout()
                    if (logoutResponse === 1) {
                        this.$router.push({ path: '/login' })
                    }
                }
            })
        },
        deleteItem (val){
            if (confirm('Are you sure to delete this modifier')) {

                let url = process.env.VUE_APP_API_URL+`/api/modifier/delete?id=${val}`

                // request header
                let requestHeader = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.jwtToken}`,
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": true,
                    "Access-Control-Allow-Headers": "*",
                    'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                }
                let dataToSubmit = {}

                axios
                .post(url, dataToSubmit,
                {
                    headers: requestHeader
                })
                .then((data)=> {
                    console.log(data);

                    if (data.status == 200) {
                        this.$toastr.s("Deleted Successfully", "Success");

                        this.modifierList()
                    }

                    if (data.status === 403) {
                        let logoutResponse = this.logout()
                        if (logoutResponse === 1) {
                            this.$router.push({ path: '/login' })
                        }
                    }
                })
            }
        },
        modifierList () {
            console.log('Modifier option list...');
            let url = process.env.VUE_APP_API_URL+`/api/modifier?restaurantId=${this.restaurantId}`

            // request header
            let requestHeader = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.jwtToken}`
            }

            axios
            .get(url,
            {
                headers: requestHeader
            })
            .then((data)=> {
                console.log(data);

                if (data.status === 403) {
                    let logoutResponse = this.logout()
                    if (logoutResponse === 1) {
                        this.$router.push({ path: '/login' })
                    }
                }

                this.modifierDataList = data.data
            })
        }
    }
}
</script>

<style scoped>
.header {
    position: absolute;
    width: 100%;
    height: auto;

    background: #FFEDE3;
    border: 1px solid #FFD8BC;
    box-sizing: border-box;
    border-radius: 5px;
}

.headerText {
    position: absolute;
    width: 200px;
    height: 19px;
    left: 28px;
    top: 20px;

    color: #FE750F;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
}

.InfoTable {
    position: absolute;
    width: 100%;
    height: auto;
    top: 65px;

    background: #F0F0F0;
}

.headerBtnStyle {
    background: #FFF5FF;
    border: 1px solid #BD31B5;
    color: #FE7611;
}

</style>
