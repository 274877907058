<template>
  <div class="content">
    <div class="container" style="padding-left: 10px">
      <card class="strpied-tabled-with-hover"
            body-classes="table-responsive"
      >
        <content-header>
          <p class="mb-0" style="color: #FE750F;">All Restaurant</p>
        </content-header>

        <div class="row mt-5 mx-3">
          <div class="col-xl-4 col-md-6 stats-div">
            <stats-card>
              <div slot="header" class="icon-warning">
                <i class="nc-icon nc-chart text-warning py-4"></i>
              </div>
              <div slot="content">
                <div class="py-4 pr-5">
                  <p class="card-category mb-3">Order Delivered</p>
                  <h4 class="card-title">2375</h4>
                </div>
              </div>
            </stats-card>
          </div>

          <div class="col-xl-4 col-md-6 stats-div">
            <stats-card>
              <div slot="header" class="icon-success">
                <i class="nc-icon nc-light-3 text-success py-4"></i>
              </div>
              <div slot="content">
                <div class="py-4 pr-5">
                  <p class="card-category mb-3">Order Received</p>
                  <h4 class="card-title">1,345</h4>
                </div>
              </div>
            </stats-card>
          </div>

          <div class="col-xl-4 col-md-6 stats-div">
            <stats-card>
              <div slot="header" class="icon-danger">
                <i class="nc-icon nc-vector text-danger py-4"></i>
              </div>
              <div slot="content">
                <div class="py-4 pr-5">
                  <p class="card-category mb-3">Net Earning</p>
                  <h4 class="card-title">23</h4>
                </div>
              </div>
            </stats-card>
          </div>

        </div>
        <div class="row mx-3">
          <div class="col-md-8">
            <chart-card :chart-data="lineChart.data"
                        :chart-options="lineChart.options"
                        :responsive-options="lineChart.responsiveOptions">
              <template slot="header">
                <h4 class="card-title">Users Behavior</h4>
                <p class="card-category">24 Hours performance</p>
              </template>
              <template slot="footer">
                <div class="legend">
                  <i class="fa fa-circle text-info"></i> Open
                  <i class="fa fa-circle text-danger"></i> Click
                  <i class="fa fa-circle text-warning"></i> Click Second Time
                </div>
                <hr>
                <div class="stats">
                  <i class="fa fa-history"></i> Updated 3 minutes ago
                </div>
              </template>
            </chart-card>
          </div>

          <div class="col-md-4">
            <chart-card :chart-data="pieChart.data" chart-type="Pie">
              <template slot="header">
                <h4 class="card-title">Email Statistics</h4>
                <p class="card-category">Last Campaign Performance</p>
              </template>
              <template slot="footer">
                <div class="legend">
                  <i class="fa fa-circle text-info"></i> Open
                  <i class="fa fa-circle text-danger"></i> Bounce
                  <i class="fa fa-circle text-warning"></i> Unsubscribe
                </div>
                <hr>
                <div class="stats">
                  <i class="fa fa-clock-o"></i> Campaign sent 2 days ago
                </div>
              </template>
            </chart-card>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import ChartCard from 'src/components/Cards/ChartCard.vue'
import StatsCard from 'src/components/Cards/StatsCard.vue'
import LTable from 'src/components/Table.vue'
import ContentHeader from 'src/components/ContentHeader'

export default {
  components: {
    LTable,
    ChartCard,
    StatsCard,
    ContentHeader
  },
  data () {
    return {
      editTooltip: 'Edit Task',
      deleteTooltip: 'Remove',
      pieChart: {
        data: {
          labels: ['40%', '20%', '40%'],
          series: [40, 20, 40]
        }
      },
      lineChart: {
        data: {
          labels: ['9:00AM', '12:00AM', '3:00PM', '6:00PM', '9:00PM', '12:00PM', '3:00AM', '6:00AM'],
          series: [
            [287, 385, 490, 492, 554, 586, 698, 695],
            [67, 152, 143, 240, 287, 335, 435, 437],
            [23, 113, 67, 108, 190, 239, 307, 308]
          ]
        },
        options: {
          low: 0,
          high: 800,
          showArea: false,
          height: '245px',
          axisX: {
            showGrid: false
          },
          lineSmooth: true,
          showLine: true,
          showPoint: true,
          fullWidth: true,
          chartPadding: {
            right: 50
          }
        },
        responsiveOptions: [
          ['screen and (max-width: 640px)', {
            axisX: {
              labelInterpolationFnc (value) {
                return value[0]
              }
            }
          }]
        ]
      },
      barChart: {
        data: {
          labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          series: [
            [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
            [412, 243, 280, 580, 453, 353, 300, 364, 368, 410, 636, 695]
          ]
        },
        options: {
          seriesBarDistance: 10,
          axisX: {
            showGrid: false
          },
          height: '245px'
        },
        responsiveOptions: [
          ['screen and (max-width: 640px)', {
            seriesBarDistance: 5,
            axisX: {
              labelInterpolationFnc (value) {
                return value[0]
              }
            }
          }]
        ]
      },
      tableData: {
        data: [
          {title: 'Sign contract for "What are conference organizers afraid of?"', checked: false},
          {title: 'Lines From Great Russian Literature? Or E-mails From My Boss?', checked: true},
          {
            title: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
            checked: true
          },
          {title: 'Create 4 Invisible User Experiences you Never Knew About', checked: false},
          {title: 'Read "Following makes Medium better"', checked: false},
          {title: 'Unfollow 5 enemies from twitter', checked: false}
        ]
      }
    }
  }
}
</script>
<style scoped>
.content{padding: 10px 0}
.stats-div .card-stats{background: #FFF5FF;}
.card-body .col-7 .numbers .py-4{line-height: 2.3rem}
</style>
