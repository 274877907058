<template>
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="header">
                        <span class="headerText">
                            Menu Management - Hook Items at Set
                        </span>
                    </div>

                    <div class="" style="background-color: #FFFFFF; padding-top: 10%; padding-left: 31px; padding-right: 31px; padding-bottom: 31px;">
                        <form>
                            <div class="row" style="padding-top: 31px;">

                                <div class="col-md-3">
                                    <div class="form-group">
                                        <input type="input" class="form-control" readonly v-model="itemLabel" style="background: #FFF5FF">
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                      <input type="text" class="form-control" v-model="baseQty" placeholder="Base quantity">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group" >
                                        <select class="form-control" id="exampleFormControlSelect1" v-model="itemIdSelected">
                                            <option selected>Select Item</option>
                                            <option v-for="(item, index) in allItems" :key="index" :value="item.id+'/'+item.label"> {{item.label}} </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <ul class="list-group">
                                        <li class="list-group-item" style="cursor: pointer; border-style: none !important; background: #F5F5F5; margin-bottom: 13px;" v-for="(item, index) in fixedItemSet">
                                            <span>
                                                <div style="display: inline-block; border: 1px solid #FFD8BC; border-radius: 0px 5px; padding: 2px; color: #FE750F;">{{ item.baseQty }}</div>
                                                &nbsp;
                                                <div style="display: inline-block;">{{ item.label }}</div>
                                                <i class="fa fa-chevron-down" style="float: right; color: black; display: block;" :id="'itemShowOption'+item.setItemId" @click="itemExpands(item.setItemId)"></i>
                                                <i class="fa fa-chevron-up" style="float: right; color: black; display: none;" :id="'itemHideOption'+item.setItemId" @click="itemExpands(item.setItemId)"></i>

                                                <div style="text-align: center; background-color: #FFF6EE; padding: 11px; display: none;" :id="'itemOption'+item.setItemId">
                                                    <span> <button type="button" class="btn-sm deleteBtn" @click="deleteSetItem(item.setItemId)"><i class="fa fa-trash"></i> Delete</button> </span>
                                                </div>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import axios from 'axios'
import logout from './../../../mixins/logoutMixins.js'

export default {

    props: ['categoryId', 'itemId', 'itemLabel', 'itemDataList'],
    data () {
        return {
            restaurantId: parseInt(localStorage.getItem('restaurantId')),
            itemIdSelected: 0,
            fixedItemSet: [],
            allItems: [],
            baseQty: '',
            jwtToken: this.$cookies.get('token') || null
        }
    },
    created () {
        this.assignedFixedItemSet()
        this.getOnlyIntems()
    },
    methods: {
        chooseItems (val1, val2) {
            let url = process.env.VUE_APP_API_URL+`/api/menu/create`

            // request header
            let requestHeader = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.jwtToken}`,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Headers": "*",
                'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            }

            let dataToSubmit = {
                "restaurantId": this.restaurantId,
                "label": val2,
                "resourceRef": "",
                "refCode": 0,
                "imgURL": "",
                "iconURL": "",
                "description": "",
                "categoryId": this.categoryId,
                "itemId": 0,
                "selectionId": 0,
                "toppingsId": 0,
                "modifierId": 0,
                "mealDealId": this.itemId,
                "setId": 0,
                "setItemId": val1,
                "printOptionId": 1,
                "printOptionTitle": "Starter",
                "terminalOption": "",
                "status": "Active",
                "basePrice": "15.00",
                "toppingsCategory": "",
                "mealAssignFor": "Kitchen",
                "toppingsCategoryId": 0,
                "baseQty": this.baseQty,
                "minQty": 0,
                "maxQty": 0,
                "dataType": "SetItem",
                "mealDealType": "Fixed",
                "displayOrder": 0,
                "createdAt": "3 Sep 2020 11:05:30",
                "updatedAt": "30 Sep 2020 11:05:30",
                "children": []
            }

            axios
            .post(url, dataToSubmit,
            {
                headers: requestHeader
            })
            .then((data)=> {
                console.log(data);

                if (data.status == 200) {
                    this.$toastr.s("Created Successfully", "Success");
                }

                this.assignedFixedItemSet()
            })
        },
        assignedFixedItemSet() {
            let url = process.env.VUE_APP_API_URL+`/api/menu/item?restaurantId=${this.restaurantId}&id=${this.itemId}`

            // request header
            let requestHeader = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.jwtToken}`
            }

            axios
            .get(url,
            {
                headers: requestHeader
            })
            .then((data)=> {
                console.log('assignedFixedItemSet ....')
                console.log(data);

                if (data.status === 403) {
                    let logoutResponse = this.logout()
                    if (logoutResponse === 1) {
                        this.$router.push({ path: '/login' })
                    }
                }

                this.fixedItemSet = []

                // this.fixedItemSet = data.data

                for (var i = 0; i < data.data.children.length; i++) {
                    let obj = {
                        'setId': data.data.children[i].setId,
                        'setItemId': data.data.children[i].setItemId,
                        'label': data.data.children[i].label,
                        'baseQty': data.data.children[i].baseQty
                    }
                    console.log(obj);
                    this.fixedItemSet.push(obj)
                }

                console.log(this.fixedItemSet);
            })
        },
        getOnlyIntems() {
            let url = process.env.VUE_APP_API_URL+`/api/menu/items?restaurantId=${this.restaurantId}`

            // request header
            let requestHeader = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.jwtToken}`
            }

            axios
            .get(url,
            {
                headers: requestHeader
            })
            .then((data)=> {
                console.log(data);

                if (data.status === 403) {
                    let logoutResponse = this.logout()
                    if (logoutResponse === 1) {
                        this.$router.push({ path: '/login' })
                    }
                }

                this.allItems = data.data
            })
        },
        itemExpands (item) {
            let idShow = 'itemShowOption'+item
            let idHide = 'itemHideOption'+item
            let idOption = 'itemOption'+item

            if (this.isItemExpanded) {
                let x = document.getElementById(idShow)
                let y = document.getElementById(idHide)
                let z = document.getElementById(idOption)

                x.style.display = "block"
                y.style.display = "none"
                z.style.display = "none"

                this.isItemExpanded = !this.isItemExpanded
            }
            else {
                let x = document.getElementById( idShow)
                let y = document.getElementById(idHide)
                let z = document.getElementById(idOption)

                x.style.display = "none"
                y.style.display = "block"
                z.style.display = "block"

                this.isItemExpanded = !this.isItemExpanded
            }
        },
        deleteSetItem (val) {
            let url = process.env.VUE_APP_API_URL+`/api/menu/delete`

            // request header
            let requestHeader = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.jwtToken}`,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Headers": "*",
                'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            }

            let dataToSubmit = {
                "id": val,
                "restaurantId": this.restaurantId,
                "printOptionTitle": "Starter",
                "toppingsCategoryId": 0,
                "terminalOption": "",
                "modifierId": 0,
                "displayOrder": 0,
                "description": "",
                "baseQty": 1,
                "printOptionId": 1,
                "createdAt": "3 Sep 2020 11:05:30",
                "mealAssignFor": "Kitchen",
                "children": [],
                // "setId": this.idOfSetItem,
                // "setItemId": 4109,
                "setTitle": "",
                "iconURL": "",
                "toppingsId": 0,
                "basePrice": "",
                "updatedAt": "30 Sep 2020 11:05:30",
                "selectionId": 0,
                "dataType": "SetItem",
                "label": "",
                "resourceRef": "",
                "imgURL": "",
                "itemId": 0,
                "mealDealId": this.itemId,
                "toppingsCategory": "",
                "maxQty": 0,
                "mealDealType": "Fixed",
                "refCode": 0,
                "categoryId": this.categoryId,
                "status": "Active",
                "minQty": 0,
                "total": ""
            }

            if (confirm('Are you sure to delete this set item?')) {
                axios
                .post(url, dataToSubmit,
                {
                    headers: requestHeader
                })
                .then((data)=> {
                    console.log(data);

                    if (data.status == 200) {
                        this.$toastr.s("Deleted Successfully", "Success");
                    }

                    if (data.status === 403) {
                        let logoutResponse = this.logout()
                        if (logoutResponse === 1) {
                            this.$router.push({ path: '/login' })
                        }
                    }

                    this.assignedFixedItemSet()
                })
            }
        }
    },
    watch: {
        itemIdSelected (val) {
          if (this.baseQty == '') {
            this.$toastr.w("Please enter base quantity first!", "Warning");
            return false;
          }
            console.log('selected value item has changed to : '+val)
            let data = val.split("/")
            console.log(data[0] +' - '+ data[1]);

            this.chooseItems (data[0], data[1])
        }
    }
}
</script>

<style scoped>
.header {
    position: absolute;
    width: 97%;
    height: 69px;

    background: #FFEDE3;
    border: 1px solid #FFD8BC;
    box-sizing: border-box;
    border-radius: 0px;
}

.headerText {
    position: absolute;
    width: 400px;
    height: 19px;
    left: 28px;
    top: 20px;

    color: #FE750F;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
}

.deleteBtn {
    background-color: #FF646F;
    border: 1px solid #FF646F;
    color: white;
}
.requiredCls {
  color: red;
}
</style>
