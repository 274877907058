<template>
    <div class="row">
        <div class="col-md-12">

            <div class="btn-group" role="group" aria-label="Basic example" style="float: right; margin-right: 2%; margin-top: 2%;">
                <button type="button" class="btn" style="background: #FFF5FF; color: #FE7612; border-color: #BD31B5;"><i class="fa fa-filter"></i></button>
                <button type="button" class="btn" style="background: #FFF5FF; color: #FE7612; border-color: #BD31B5;">Today</button>
            </div>

            <div style="background-color: #FFFFFF; padding-top: 2%; padding-left: 15px; padding-right: 15px; padding-bottom: 31px;">
                <table class="table">
                    <thead>
                        <tr style="font-weight: bold;">
                            <th>Customer Name</th>
                            <th>Transaction Date</th>

                            <th>Amount</th>
                            <th>Paid By</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in history">
                            <td>{{restaurantTitle}}</td>
                            <td>{{item.paymentDate}}</td>
                            <td>{{item.paymentAmount}}</td>
                            <td>{{item.paymentType}}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
</template>

<script>
import BaseSearch from "../Inputs/BaseSearch";
import axios from 'axios'

export default {
    props: ['restaurantId', 'restaurantTitle'],
    data () {
        return {
            history: []
        }
    },
    created () {
        this.getData ()

    },
    methods: {
        getData () {
            let url = process.env.VUE_APP_API_URL+`/api/payment?restaurantId=${this.restaurantId}`

            axios
            .post(url,
            {
                headers: {
                    Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
                }
            })
            .then((data) => {
                console.log(data);
                this.history = data.data
            })
        }
    }
}
</script>

<style scoped>

</style>
