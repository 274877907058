<template>
  <div class="content ">
    <div class="container" style="padding-left: 10px">
      <div class="row">
        <div class="col-12">
          <card class="strpied-tabled-with-hover"
                body-classes="table-responsive"
          >
          <content-header>
              <span class="mb-0" style="color: #FE750F;">{{title}}</span>
              <span style="float: right;">
                  <button type="button" class="btn btn-sm" style="background: #FFEDE3; color: #FE750F; border-color: #FE750F;" v-if="!isSubcriptionList && (isSubcriptionCreate || isSubcriptionHistory)" @click="showSubscriptionList">Back to List</button>&nbsp;
                  <button type="button" class="btn btn-sm" style="background: #FFEDE3; color: #FE750F; border-color: #FE750F;" v-if="!isSubcriptionList && isSubcriptionCreate && !isSubcriptionHistory" @click="showSubscriptionHistory">View History</button>&nbsp;
                  <button type="button" class="btn btn-sm" style="background: #FFEDE3; color: #FE750F; border-color: #FE750F;" v-if="!isSubcriptionList && !isSubcriptionCreate && isSubcriptionHistory" @click="showSubscriptionCreate">Back to Subcription Info</button>&nbsp;
              </span>
          </content-header>

            <div class="mx-4 mt-4" v-if="isSubcriptionList && !isSubcriptionCreate && !isSubcriptionHistory">
                <base-search class="w-full max-w-md mr-4" />

                <div class="btn-group" role="group" aria-label="Basic example" style="float: right;">
                    <button type="button" class="btn" style="background: #FFF5FF; color: #FE7612; border-color: #BD31B5;"><i class="fa fa-filter"></i></button>
                    <button type="button" class="btn" style="background: #FFF5FF; color: #FE7612; border-color: #BD31B5;">Today</button>
                </div>

                <div style="background-color: #FFFFFF; padding-top: 2%; padding-left: 15px; padding-right: 15px; padding-bottom: 31px;">
                    <table class="table">
                        <thead>
                            <tr style="font-weight: bold;">
                                <th>Restaurant Name</th>
                                <th>Owner Name</th>

                                <th>Subscription End</th>
                                <th>Subcription Type</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in ownerDataList" :key="index">
                                <td>{{item.restaurantName}}</td>
                                <td>{{item.owner.firstName+' '+item.owner.middleName+' '+item.owner.lastName}}</td>
                                <td></td>
                                <td></td>
                                <td>
                                    <button type="button" class="btn btn-sm" style="background: #FE7612; color: white; border-color: #FE7612;" @click="viewDetails(item.id, item.restaurantName)">View Details</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

            <Create v-if="!isSubcriptionList && isSubcriptionCreate && !isSubcriptionHistory"
            v-bind:restaurantSubscription="restaurantSubscription"
            v-bind:restaurantId="restaurantId"
            />
            <PaymentHistory v-if="!isSubcriptionList && !isSubcriptionCreate && isSubcriptionHistory"
            v-bind:restaurantId="restaurantId"
            v-bind:restaurantTitle="restaurantTitle"
            />

          </card>

        </div>

      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import Card from 'src/components/Cards/Card.vue'
import ContentHeader from "../components/ContentHeader";
import BaseSearch from "../components/Inputs/BaseSearch";
import Create from '../components/Subcription/create';
import PaymentHistory from '../components/Subcription/paymentHistory';

export default {
    components: {
        Card,
        ContentHeader,
        BaseSearch,
        Create,
        PaymentHistory
    },
    data () {
        return {
            restaurantId: 0,
            ownerDataList: [],
            title: 'Subcription',
            isSubcriptionList: true,
            isSubcriptionCreate: false,
            isSubcriptionHistory: false,
            restaurantSubscription: [],
            restaurantTitle: ''
        }
    },
    created () {
        this.ownerList()
    },
    methods: {
        ownerList () {
            let url = process.env.VUE_APP_API_URL+'/api/restaurant'

            axios
            .get(url,
            {
                headers: {
                    Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
                }
            })
            .then((data) => {
                this.ownerDataList = data.data

                console.log(data.data[0].owner);
            })
        },
        viewDetails (val, name) {
            this.restaurantId = val
            let url = process.env.VUE_APP_API_URL+`/api/subscription?restaurantId=${val}`

            axios
            .post(url,
            {
                headers: {
                    Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
                }
            })
            .then((data) => {
                console.log(data);

                this.restaurantSubscription = data.data
                this.title = name
                this.restaurantTitle = name
                this.isSubcriptionList = false
                this.isSubcriptionCreate = true
                this.isSubcriptionHistory = false
            })
        },
        showSubscriptionList () {
            this.title = 'Subcription'
            this.isSubcriptionList = true
            this.isSubcriptionCreate = false
            this.isSubcriptionHistory = false
        },
        showSubscriptionHistory () {
            this.title = 'Payment History'
            this.isSubcriptionList = false
            this.isSubcriptionCreate = false
            this.isSubcriptionHistory = true
        },
        showSubscriptionCreate () {
            this.title = 'Subcription'
            this.isSubcriptionList = false
            this.isSubcriptionCreate = true
            this.isSubcriptionHistory = false
        }
    }
}
</script>
<style scoped>
.content{padding: 10px 0}
</style>
