<template>

    <div class="content">
        <div class="container">
            <div class="row">

                <div class="col-md-12" >
                    <div class="header">
                        <span class="headerText">
                            Business List
                        </span>
                        <span class="headerBtn" style="float: right;">
                            <button type="button" class="btn headerBtnStyle" @click="addBusiness()">Add New Business</button>
                        </span>
                    </div>

                    <div style="background-color: #FFFFFF; padding-top: 10%; padding-left: 15px; padding-right: 15px; padding-bottom: 31px;">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Logo</th>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Phone</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in businessList" :key="index">
                                    <td><img :src="item.imgUrl" alt="Logo" class="logo" width="40" height="40"></td>
                                    <td>{{item.name}}</td>
                                    <td>{{item.restaurantType}}</td>
                                    <td>{{item.phone}}</td>
                                    <td>
                                        <i class="fa fa-edit" @click="editItem(item.id)" style="cursor: pointer;"></i> &nbsp;
                                        <!-- <i class="fa fa-plus" style="cursor: pointer; color: #F4522D;" @click="goToMenuManagement(item.id)"></i>
                                        <i class="fa fa-picture-o" style="cursor: pointer;" @click="goToContentManagement(item.id)"></i>
                                        <i class="fa fa-trash" @click="deleteOwner(item.id)" style="cursor: pointer; color: #F4522D;"></i> &nbsp; -->
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import ContentHeader from "@/components/ContentHeader"
import axios from 'axios'

export default {
    components: {
      ContentHeader
    },
    data () {
        return {
            businessList: []
        }
    },
    created () {
        this.getBusinessList()
    },
    methods: {
        deleteOwner(val) {
            if (confirm('Are you sure to delete this owner?')) {
                let url = process.env.VUE_APP_API_URL+`/api/restaurant/delete?id=${val}`

                axios
                .get(url,
                {
                    headers: {
                        Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
                    }
                })
                .then((data)=> {
                    console.log(data);

                    if (data.status == 200) {
                        this.$toastr.s("deleted Successfully", "Success");

                        this.ownerList()
                    }
                })
            }

        },
        getBusinessList () {
            let url = process.env.VUE_APP_API_URL+'/api/business'

            axios
            .post(url,
            {
                headers: {
                    Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
                }
            })
            .then((data) => {
                console.log(data);
                this.businessList = data.data
            })
        },
        editItem(val) {
            let editableData = []
            for (var i = 0; i < this.ownerDataList.length; i++) {
                if (this.ownerDataList[i].id == val) {
                    editableData = JSON.stringify(this.ownerDataList[i])

                    console.log(editableData);
                }
            }

            localStorage.setItem('editableData', editableData)

            window.location = '/admin/create-owner'
        },
        addBusiness () {
            this.$router.push('/admin/booking/create')
        },
        goToContentManagement (val) {
            localStorage.setItem('restaurantId', val)
            this.$router.push('/admin/content-management')
        }
    }
}
</script>

<style scoped>
.header {
    position: absolute;
    width: 97%;
    height: 69px;

    background: #FFEDE3;
    border: 1px solid #FFD8BC;
    box-sizing: border-box;
    border-radius: 0px;
}

.headerText {
    position: absolute;
    width: 200px;
    height: 19px;
    left: 28px;
    top: 20px;

    color: #FE750F;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
}

.headerBtn {
    padding-top: 12px;
    padding-right: 44px;
}

.headerBtnStyle {
    background: #FFF5FF;
    border: 1px solid #BD31B5;
    color: #FE7611;
}
.table > thead > tr > th {
    color: black;
    font-size: .8rem;
    font-weight: bold;
}
.table > thead > tr > th:last-child, .table > tbody > tr > th:last-child, .table > tfoot > tr > th:last-child, .table > thead > tr > td:last-child, .table > tbody > tr > td:last-child, .table > tfoot > tr > td:last-child {
    width: auto;
}
.logo{border-radius: 50%;}
</style>
