<template>
  <div class="content ">
    <div class="container" style="padding-left: 10px">
      <!--chart-->
      <div class="row">
        <div class="col-md-12">
          <card class="strpied-tabled-with-hover"
                body-classes="table-responsive"
          >
            <content-header>
              <p class="mb-0" style="color: #FE750F;">All Restaurant</p>
            </content-header>

            <chart-card :chart-data="lineChart.data"
                      :chart-options="lineChart.options"
                      :responsive-options="lineChart.responsiveOptions">
              <template slot="header">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6">
                      <h4 class="card-title">All Overview</h4>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 time-sort">
                    <a href="">All Time</a>
                    <a href="">This Year</a>
                    <a href="">This Week</a>
                    <a href="">Today</a>
                  </div>
                </div>
              </template>
              <template slot="chartCategories">
                <div class="legend">
                  <i class="fa fa-circle txt-grn"></i><span class="pl-2">Restaurant</span>
                  <i class="fa fa-circle txt-violet ml-4"></i><span class="pl-2">Order</span>
                  <i class="fa fa-circle txt-blue ml-4"></i><span class="pl-2">Customer</span>
                </div>
              </template>
          </chart-card>
          </card>
        </div>

      </div>

      <!--table-->
      <div class="row">
        <div class="col-12">
          <card class="strpied-tabled-with-hover"
                body-classes="table-responsive"
          >
            <div class="mx-4 mt-4">
              <BaseSearch class="w-full max-w-md mr-4" :placeholder="'Search User'" />
              <table class="table mt-5">
                <thead>
                  <tr>
                    <th>Restaurant Name</th>
                    <th>Owner Name</th>
                    <th>Phone</th>
                    <th>Subscription End</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in table1.data" :key="index">
                  <td>{{item['restaurant_name']}}</td>
                  <td>{{item['owner_name']}}</td>
                  <td>{{item['phone']}}</td>
                  <td>{{item['subscription_end']}}</td>
                  <td>
                    <a @click="resReport(item['id'])" class="btn btn-orange">View Details</a>
<!--                    <router-link to="{'/report/restaurant/1'}">-->
<!--View Details-->
<!--                    </router-link>-->
                  </td>
                </tr>
                </tbody>
              </table>

            </div>
          </card>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import ChartCard from 'src/components/Cards/ChartCard.vue'
import LTable from 'src/components/Table.vue'
import Card from 'src/components/Cards/Card.vue'
import ContentHeader from "src/components/ContentHeader";
import BaseSearch from "src/components/Inputs/BaseSearch";

const tableData = [{
  id: 1,
  restaurant_name: 'Dakota Rice',
  owner_name: '$36.738',
  phone: 'Niger',
  subscription_end: 'Oud-Turnhout'
},
  {
    id: 2,
    restaurant_name: 'Minerva Hooper',
    owner_name: '$23,789',
    phone: 'Curaçao',
    subscription_end: 'Sinaai-Waas'
  },
  {
    id: 3,
    restaurant_name: 'Sage Rodriguez',
    owner_name: '$56,142',
    phone: 'Netherlands',
    subscription_end: 'Baileux'
  },
  {
    id: 4,
    restaurant_name: 'Philip Chaney',
    owner_name: '$38,735',
    phone: 'Korea, South',
    subscription_end: 'Overland Park'
  },
  {
    id: 5,
    restaurant_name: 'Doris Greene',
    owner_name: '$63,542',
    phone: 'Malawi',
    subscription_end: 'Feldkirchen in Kärnten'
  }]
export default {
  components: {
    ChartCard,
    LTable,
    Card,
    ContentHeader,
    BaseSearch
  },
  data () {
    return {
      table1: {
        data: [...tableData]
      },
      lineChart: {
        data: {
          labels: ['9:00AM', '12:00AM', '3:00PM', '6:00PM', '9:00PM', '12:00PM', '3:00AM', '6:00AM'],
          series: [
            [287, 385, 490, 492, 554, 586, 698, 695],
            [67, 152, 143, 240, 287, 335, 435, 437],
            [23, 113, 67, 108, 190, 239, 307, 308]
          ]
        },
        options: {
          low: 0,
          high: 800,
          showArea: false,
          height: '245px',
          axisX: {
            showGrid: false
          },
          lineSmooth: true,
          showLine: true,
          showPoint: true,
          fullWidth: true,
          chartPadding: {
            right: 50
          }
        },
        responsiveOptions: [
          ['screen and (max-width: 640px)', {
            axisX: {
              labelInterpolationFnc (value) {
                return value[0]
              }
            }
          }]
        ]
      },
    }
  },
  methods: {
    resReport(id) {
      console.log(id)
      this.$router.push({ name: 'RestaurantReport', params: { id: id } })
    }
  }
}
</script>
<style scoped>
.content{padding: 10px 0}
.txt-grn{color: #1DE9B6}
.txt-violet{color: #A389D4;}
.txt-blue{color: #04A9F5}
.btn-orange{
  color: #FFFFFF;
  border: unset;
  background: #FE7612;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.18);
  border-radius: 2px;
}
</style>
