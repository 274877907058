<template>
    <div class="content">
        <div class="container" style="padding-left: 10px">

            <div class="row">

                <div class="col-md-12" >
                  <content-header>
                    <p class="mb-0" style="color: #FE750F;">Create Booking</p>
                    <!-- <p class="mb-0" style="color: #FE750F;" v-if="isEditClicked">Edit Booking</p> -->
                  </content-header>
                  <div style="background-color: #FFFFFF; padding-left: 31px; padding-right: 31px; padding-bottom: 31px;">

                      <div class="row" style="padding-top: 49px;">
                          <div class="col-md-12">
                              <form>
                                  <div class="row">

                                      <div class="col-md-4">
                                          <div class="form-group">
                                            <div class="uploadDiv">
                                                <img src="/img/upload.png" alt=""> Upload Your Logo
                                                <input type="file" @change="logoSelected" accept="image/*" class="uploadBtn"/>
                                            </div>
                                          </div>
                                          <div class="form-group">
                                              <label for="type" :class="{requiredCls : isTypeGiven}">Type</label>
                                              <input type="text" class="form-control" id="type" v-model="type" placeholder="Enter type">
                                          </div>

                                          <div class="form-group">
                                              <label for="name" :class="{requiredCls : isNameGiven}">Name</label>
                                              <input type="text" class="form-control" id="name" v-model="name" placeholder="Enter name">
                                          </div>

                                          <div class="form-group">
                                              <label for="phone" :class="{requiredCls : isPhoneGiven}">Phone</label>
                                              <input type="text" class="form-control" id="phone" v-model="phone" placeholder="Enter phone">
                                          </div>
                                      </div>

                                      <div class="col-md-4">
                                          <div class="form-group">
                                              <label for="houseNo" :class="{requiredCls : isHouseGiven}">House Name</label>
                                              <input type="text" class="form-control" id="houseNo" v-model="houseName" placeholder="Enter house name">
                                          </div>
                                          <div class="form-group">
                                              <label for="doorNo" :class="{requiredCls : isDoorGiven}">Door No</label>
                                              <input type="text" class="form-control" id="doorNo" v-model="doorNo" placeholder="Enter door no">
                                          </div>
                                          <div class="form-group">
                                              <label for="street" :class="{requiredCls : isStreetGiven}">Street</label>
                                              <input type="text" class="form-control" id="street" v-model="street" placeholder="Enter street">
                                          </div>
                                      </div>

                                      <div class="col-md-4">
                                          <div class="form-group">
                                              <label for="city" :class="{requiredCls : isCityGiven}">City</label>
                                              <input type="text" class="form-control" id="city" v-model="city" placeholder="Enter city">
                                          </div>
                                          <div class="form-group">
                                              <label for="postCode" :class="{requiredCls : isPostCodeGiven}">Post code </label>
                                              <input type="text" class="form-control" id="postCode"  v-model="postCode" placeholder="Enter post code">
                                          </div>
                                          
                                          <div class="form-group">
                                              <label for="website" :class="{requiredCls : isWebsiteGiven}">Website</label>
                                              <input type="email" class="form-control" id="website" v-model="website" placeholder="Enter website URL">
                                          </div>
                                      </div>

                                      <div class="col-md-8">
                                          <h4>Opening Hours</h4>
                                          
                                          <div class="row weekDays">
                                              <div class="col-md-6">
                                                  <div class="row">
                                                    <div class="col-md-6">
                                                        <p>Monday</p>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label class="switch">
                                                            <input type="checkbox" v-model="isMonAvailable" @click="monAvailable">
                                                            <span class="slider round"></span>
                                                        </label><span class="openClose">{{ isMonAvailable == true ? 'Open' : 'Close' }}</span>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div class="col-md-6">
                                                  <div class="row 1stOpenClose">
                                                    <div class="col-md-4">
                                                        <TimePicker
                                                        format="HH:mm"
                                                        placeholder="From"
                                                        @change="changeMonStartOne"
                                                        ></TimePicker>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <TimePicker
                                                        format="HH:mm"
                                                        placeholder="To"
                                                        @change="changeMonEndOne"
                                                        ></TimePicker>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <a class="addHrs" @click="addMonHours">Add Hours</a>
                                                    </div>
                                                  </div>
                                                  <div v-if="addMon2ndHrs" class="row secondOpenClose">
                                                    <div class="col-md-4">
                                                        <TimePicker
                                                        format="HH:mm"
                                                        placeholder="From"
                                                        @change="changeMonStartTwo"
                                                        ></TimePicker>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <TimePicker
                                                        format="HH:mm"
                                                        placeholder="To"
                                                        @change="changeMonEndTwo"
                                                        ></TimePicker>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <a class="removeHrs" @click="removeMonHours">Remove</a>
                                                    </div>
                                                  </div>
                                              </div>
                                          </div>

                                          <div class="row weekDays">
                                              <div class="col-md-6">
                                                  <div class="row">
                                                    <div class="col-md-6">
                                                        <p>Tuesday</p>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label class="switch">
                                                            <input type="checkbox" v-model="isTueAvailable" @click="tueAvailable">
                                                            <span class="slider round"></span>
                                                        </label><span class="openClose">{{ isTueAvailable == true ? 'Open' : 'False' }}</span>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div class="col-md-6">
                                                  <div class="row 1stOpenClose">
                                                    <div class="col-md-4">
                                                        <TimePicker
                                                        format="HH:mm"
                                                        placeholder="From"
                                                        @change="changeTueStartOne"
                                                        ></TimePicker>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <TimePicker
                                                        format="HH:mm"
                                                        placeholder="To"
                                                        @change="changeTueEndOne"
                                                        ></TimePicker>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <a class="addHrs" @click="addTueHours">Add Hours</a>
                                                    </div>
                                                  </div>
                                                  <div v-if="addTue2ndHrs" class="row secondOpenClose">
                                                    <div class="col-md-4">
                                                        <TimePicker
                                                        format="HH:mm"
                                                        placeholder="From"
                                                        @change="changeTueStartTwo"
                                                        ></TimePicker>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <TimePicker
                                                        format="HH:mm"
                                                        placeholder="To"
                                                        @change="changeTueEndTwo"
                                                        ></TimePicker>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <a class="removeHrs" @click="removeTueHours">Remove</a>
                                                    </div>
                                                  </div>
                                              </div>
                                          </div>

                                          <div class="row weekDays">
                                              <div class="col-md-6">
                                                  <div class="row">
                                                    <div class="col-md-6">
                                                        <p>Wednesday</p>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label class="switch">
                                                            <input type="checkbox" v-model="isWedAvailable" @click="wedAvailable">
                                                            <span class="slider round"></span>
                                                        </label><span class="openClose">{{ isWedAvailable == true ? 'Open' : 'Close' }}</span>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div class="col-md-6">
                                                  <div class="row 1stOpenClose">
                                                    <div class="col-md-4">
                                                        <TimePicker
                                                        format="HH:mm"
                                                        placeholder="From"
                                                        @change="changeWedStartOne"
                                                        ></TimePicker>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <TimePicker
                                                        format="HH:mm"
                                                        placeholder="To"
                                                        @change="changeWedEndOne"
                                                        ></TimePicker>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <a class="addHrs" @click="addWedHours">Add Hours</a>
                                                    </div>
                                                  </div>
                                                  <div v-if="addWed2ndHrs" class="row secondOpenClose">
                                                    <div class="col-md-4">
                                                        <TimePicker
                                                        format="HH:mm"
                                                        placeholder="From"
                                                        @change="changeWedStartTwo"
                                                        ></TimePicker>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <TimePicker
                                                        format="HH:mm"
                                                        placeholder="To"
                                                        @change="changeWedEndTwo"
                                                        ></TimePicker>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <a class="removeHrs" @click="removeWedHours">Remove</a>
                                                    </div>
                                                  </div>
                                              </div>
                                          </div>

                                          <div class="row weekDays">
                                              <div class="col-md-6">
                                                  <div class="row">
                                                    <div class="col-md-6">
                                                        <p>Thursday</p>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label class="switch">
                                                            <input type="checkbox" v-model="isThurAvailable" @click="thurAvailable">
                                                            <span class="slider round"></span>
                                                        </label><span class="openClose">{{ isThurAvailable == true ? 'Open' : 'Close' }}</span>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div class="col-md-6">
                                                  <div class="row 1stOpenClose">
                                                    <div class="col-md-4">
                                                        <TimePicker
                                                        format="HH:mm"
                                                        placeholder="From"
                                                        @change="changeThurStartOne"
                                                        ></TimePicker>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <TimePicker
                                                        format="HH:mm"
                                                        placeholder="To"
                                                        @change="changeThurEndOne"
                                                        ></TimePicker>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <a class="addHrs" @click="addThurHours">Add Hours</a>
                                                    </div>
                                                  </div>
                                                  <div v-if="addThur2ndHrs" class="row secondOpenClose">
                                                    <div class="col-md-4">
                                                        <TimePicker
                                                        format="HH:mm"
                                                        placeholder="From"
                                                        @change="changeThurStartTwo"
                                                        ></TimePicker>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <TimePicker
                                                        format="HH:mm"
                                                        placeholder="To"
                                                        @change="changeThurEndTwo"
                                                        ></TimePicker>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <a class="removeHrs" @click="removeThurHours">Remove</a>
                                                    </div>
                                                  </div>
                                              </div>
                                          </div>

                                          <div class="row weekDays">
                                              <div class="col-md-6">
                                                  <div class="row">
                                                    <div class="col-md-6">
                                                        <p>Friday</p>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label class="switch">
                                                            <input type="checkbox" v-model="isFriAvailable" @click="friAvailable">
                                                            <span class="slider round"></span>
                                                        </label><span class="openClose">{{ isFriAvailable == true ? 'Open' : 'Close' }}</span>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div class="col-md-6">
                                                  <div class="row 1stOpenClose">
                                                    <div class="col-md-4">
                                                        <TimePicker
                                                        format="HH:mm"
                                                        placeholder="From"
                                                        @change="changeFriStartOne"
                                                        ></TimePicker>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <TimePicker
                                                        format="HH:mm"
                                                        placeholder="To"
                                                        @change="changeFriEndOne"
                                                        ></TimePicker>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <a class="addHrs" @click="addFriHours">Add Hours</a>
                                                    </div>
                                                  </div>
                                                  <div v-if="addFri2ndHrs" class="row secondOpenClose">
                                                    <div class="col-md-4">
                                                        <TimePicker
                                                        format="HH:mm"
                                                        placeholder="From"
                                                        @change="changeFriStartTwo"
                                                        ></TimePicker>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <TimePicker
                                                        format="HH:mm"
                                                        placeholder="To"
                                                        @change="changeFriEndTwo"
                                                        ></TimePicker>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <a class="removeHrs" @click="removeFriHours">Remove</a>
                                                    </div>
                                                  </div>
                                              </div>
                                          </div>

                                          <div class="row weekDays">
                                              <div class="col-md-6">
                                                  <div class="row">
                                                    <div class="col-md-6">
                                                        <p>Saturday</p>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label class="switch">
                                                            <input type="checkbox" v-model="isSatAvailable" @click="satAvailable">
                                                            <span class="slider round"></span>
                                                        </label><span class="openClose">{{ isSatAvailable == true ? 'Open' : 'Close' }}</span>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div class="col-md-6">
                                                  <div class="row 1stOpenClose">
                                                    <div class="col-md-4">
                                                        <TimePicker
                                                        format="HH:mm"
                                                        placeholder="From"
                                                        @change="changeSatStartOne"
                                                        ></TimePicker>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <TimePicker
                                                        format="HH:mm"
                                                        placeholder="To"
                                                        @change="changeSatEndOne"
                                                        ></TimePicker>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <a class="addHrs" @click="addSatHours">Add Hours</a>
                                                    </div>
                                                  </div>
                                                  <div v-if="addSat2ndHrs" class="row secondOpenClose">
                                                    <div class="col-md-4">
                                                        <TimePicker
                                                        format="HH:mm"
                                                        placeholder="From"
                                                        @change="changeSatStartTwo"
                                                        ></TimePicker>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <TimePicker
                                                        format="HH:mm"
                                                        placeholder="To"
                                                        @change="changeSatEndTwo"
                                                        ></TimePicker>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <a class="removeHrs" @click="removeSatHours">Remove</a>
                                                    </div>
                                                  </div>
                                              </div>
                                          </div>

                                          <div class="row weekDays">
                                              <div class="col-md-6">
                                                  <div class="row">
                                                    <div class="col-md-6">
                                                        <p>Sunday</p>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label class="switch">
                                                            <input type="checkbox" v-model="isSunAvailable" @click="sunAvailable">
                                                            <span class="slider round"></span>
                                                        </label><span class="openClose">{{ isSunAvailable == true ? 'Open' : 'Close' }}</span>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div class="col-md-6">
                                                  <div class="row 1stOpenClose">
                                                    <div class="col-md-4">
                                                        <TimePicker
                                                        format="HH:mm"
                                                        placeholder="From"
                                                        @change="changeSunStartOne"
                                                        ></TimePicker>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <TimePicker
                                                        format="HH:mm"
                                                        placeholder="To"
                                                        @change="changeSunEndOne"
                                                        ></TimePicker>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <a class="addHrs" @click="addSunHours">Add Hours</a>
                                                    </div>
                                                  </div>
                                                  <div v-if="addSun2ndHrs" class="row secondOpenClose">
                                                    <div class="col-md-4">
                                                        <TimePicker
                                                        format="HH:mm"
                                                        placeholder="From"
                                                        @change="changeSunStartTwo"
                                                        ></TimePicker>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <TimePicker
                                                        format="HH:mm"
                                                        placeholder="To"
                                                        @change="changeSunEndTwo"
                                                        ></TimePicker>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <a class="removeHrs" @click="removeSunHours">Remove</a>
                                                    </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>

                                  <br>
                                  <button type="button" class="btn submitBtn" @click="uploadLogo">Submit</button>
                                  <img v-if="showLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
                              </form>
                          </div>


                      </div>

                  </div>

                </div>

            </div>

        </div>
    </div>

</template>

<script>
import axios from 'axios'
import ContentHeader from "@/components/ContentHeader";

import { TimePicker } from 'ant-design-vue';

export default {
    components: {
      ContentHeader,
      TimePicker
    },
    data () {
        return {
            format: "d MMMM yyyy",

            // Booking info
            logo: null,
            type: '',
            name: '',
            phone: '',
            houseName: '',
            doorNo: '',
            street: '',
            city: '',
            postCode: '',
            website: '',
            imgUrl: '',
            imgUrlPublicId: '',

            //hours
            isSatAvailable: true,
            isSunAvailable: true,
            isMonAvailable: true,
            isTueAvailable: true,
            isWedAvailable: true,
            isThurAvailable: true,
            isFriAvailable: true,

            monHrsStartOne: '',
            monHrsEndOne: '',
            monHrsStartTwo: '',
            monHrsEndTwo: '',
            addMon2ndHrs: false,
            
            tueHrsStartOne: '',
            tueHrsEndOne: '',
            tueHrsStartTwo: '',
            tueHrsEndTwo: '',
            addTue2ndHrs: false,

            wedHrsStartOne: '',
            wedHrsEndOne: '',
            wedHrsStartTwo: '',
            wedHrsEndTwo: '',
            addWed2ndHrs: false,

            thurHrsStartOne: '',
            thurHrsEndOne: '',
            thurHrsStartTwo: '',
            thurHrsEndTwo: '',
            addThur2ndHrs: false,

            friHrsStartOne: '',
            friHrsEndOne: '',
            friHrsStartTwo: '',
            friHrsEndTwo: '',
            addFri2ndHrs: false,

            satHrsStartOne: '',
            satHrsEndOne: '',
            satHrsStartTwo: '',
            satHrsEndTwo: '',
            addSat2ndHrs: false,

            sunHrsStartOne: '',
            sunHrsEndOne: '',
            sunHrsStartTwo: '',
            sunHrsEndTwo: '',
            addSun2ndHrs: false,
            
            // required fields
            isTypeGiven: false,
            isNameGiven: false,
            isPhoneGiven: false,
            isHouseGiven: false,
            isDoorGiven: false,
            isStreetGiven: false,
            isCityGiven: false,
            isPostCodeGiven: false,
            isWebsiteGiven: false,

            showLoading: false,

        }
    },
    created () {

    },
    methods: {
        monAvailable(){
            this.isMonAvailable = !this.isMonAvailable
        },
        tueAvailable(){
            this.isTueAvailable = !this.isTueAvailable
        },
        wedAvailable(){
            this.isWedAvailable = !this.isWedAvailable
        },
        thurAvailable(){
            this.isThurAvailable = !this.isThurAvailable
        },
        friAvailable(){
            this.isFriAvailable = !this.isFriAvailable
        },
        satAvailable(){
            this.isSatAvailable = !this.isSatAvailable
        },
        sunAvailable(){
            this.isSunAvailable = !this.isSunAvailable
        },
        changeMonStartOne(time, timeString) { this.monHrsStartOne = timeString; },
        changeMonEndOne(time, timeString) { this.monHrsEndOne = timeString; },
        changeMonStartTwo(time, timeString) { this.monHrsStartTwo = timeString; },
        changeMonEndTwo(time, timeString) { this.monHrsEndTwo = timeString; },
        addMonHours(){ this.addMon2ndHrs = true },
        removeMonHours(){
            this.addMon2ndHrs = false
            this.monHrsStartTwo = ''
            this.monHrsEndTwo = ''
        },
        changeTueStartOne(time, timeString) { this.tueHrsStartOne = timeString; },
        changeTueEndOne(time, timeString) { this.tueHrsEndOne = timeString; },
        changeTueStartTwo(time, timeString) { this.tueHrsStartTwo = timeString; },
        changeTueEndTwo(time, timeString) { this.tueHrsEndTwo = timeString; },
        addTueHours(){ this.addTue2ndHrs = true },
        removeTueHours(){
            this.addTue2ndHrs = false
            this.tueHrsStartTwo = ''
            this.tueHrsEndTwo = ''
        },
        changeWedStartOne(time, timeString) { this.wedHrsStartOne = timeString; },
        changeWedEndOne(time, timeString) { this.wedHrsEndOne = timeString; },
        changeWedStartTwo(time, timeString) { this.wedHrsStartTwo = timeString; },
        changeWedEndTwo(time, timeString) { this.wedHrsEndTwo = timeString; },
        addWedHours(){ this.addWed2ndHrs = true },
        removeWedHours(){
            this.addWed2ndHrs = false
            this.wedHrsStartTwo = ''
            this.wedHrsEndTwo = ''
        },
        changeThurStartOne(time, timeString) { this.thurHrsStartOne = timeString; },
        changeThurEndOne(time, timeString) { this.thurHrsEndOne = timeString; },
        changeThurStartTwo(time, timeString) { this.thurHrsStartTwo = timeString; },
        changeThurEndTwo(time, timeString) { this.thurHrsEndTwo = timeString; },
        addThurHours(){ this.addThur2ndHrs = true },
        removeThurHours(){
            this.addThur2ndHrs = false
            this.thurHrsStartTwo = ''
            this.thurHrsEndTwo = ''
        },
        changeFriStartOne(time, timeString) { this.friHrsStartOne = timeString; },
        changeFriEndOne(time, timeString) { this.friHrsEndOne = timeString; },
        changeFriStartTwo(time, timeString) { this.friHrsStartTwo = timeString; },
        changeFriEndTwo(time, timeString) { this.friHrsEndTwo = timeString; },
        addFriHours(){ this.addFri2ndHrs = true },
        removeFriHours(){
            this.addFri2ndHrs = false
            this.friHrsStartTwo = ''
            this.friHrsEndTwo = ''
        },
        changeSatStartOne(time, timeString) { this.satHrsStartOne = timeString; },
        changeSatEndOne(time, timeString) { this.satHrsEndOne = timeString; },
        changeSatStartTwo(time, timeString) { this.satHrsStartTwo = timeString; },
        changeSatEndTwo(time, timeString) { this.satHrsEndTwo = timeString; },
        addSatHours(){ this.addSat2ndHrs = true },
        removeSatHours(){
            this.addSat2ndHrs = false
            this.satHrsStartTwo = ''
            this.satHrsEndTwo = ''
        },
        changeSunStartOne(time, timeString) { this.sunHrsStartOne = timeString; },
        changeSunEndOne(time, timeString) { this.sunHrsEndOne = timeString; },
        changeSunStartTwo(time, timeString) { this.sunHrsStartTwo = timeString; },
        changeSunEndTwo(time, timeString) { this.sunHrsEndTwo = timeString; },
        addSunHours(){ this.addSun2ndHrs = true },
        removeSunHours(){
            this.addSun2ndHrs = false
            this.sunHrsStartTwo = ''
            this.sunHrsEndTwo = ''
        },
        logoSelected(e){
            this.logo = e.target.files[0]
        },
        submit() {
            // return false
            if (!this.type) {
                this.isTypeGiven = true
                return false
            }
            if (!this.name) {
                this.isNameGiven = true
                return false
            }
            if (!this.phone) {
                this.isPhoneGiven = true
                return false
            }
            if (!this.houseName) {
                this.isHouseGiven = true
                return false
            }
            if (!this.doorNo) {
                this.isDoorGiven = true
                return false
            }
            if (!this.street) {
                this.isStreetGiven = true
                return false
            }
            if (!this.city) {
                this.isCityGiven = true
                return false
            }
            if (!this.postCode) {
                this.isPostCodeGiven = true
                return false
            }
            if (!this.website) {
                this.isWebsiteGiven = true
                return false
            }
            // request url
            let url = process.env.VUE_APP_API_URL+'/api/business/create'

            // request data
            let dataToSubmit = {
                "imgUrl": this.imgUrl,
                "imgUrlPublicId": this.imgUrlPublicId,
                "createdId": "",
                "name": this.name,
                "door": this.doorNo,
                "house": this.houseName,
                "street": this.street,
                "city": this.city,
                "postCode": this.postCode,
                "address": "",
                "phone": this.phone,
                "website": this.website,
                "email": "mortuza@gamil.com",
                "lat": "",
                "long": "",
                "restaurantType": this.type,
                "ratting": "",
                "status": "Active",
                "aboutTxt": "this is our new restaurant",
                "promoCode": "",
                "promoDiscountAmount": "",
                "offerMsg": "",
                "openingHours": [
                    {
                        "weekDay": "Saturday",
                        "isAvailable": this.isSatAvailable,
                        "startTimeSlotOne": this.satHrsStartOne,
                        "endTimeSlotOne": this.satHrsEndOne,
                        "startTimeSlotTwo": this.satHrsStartTwo,
                        "endTimeSlotTwo": this.satHrsEndTwo
                    },
                    {
                        "weekDay": "Sunday",
                        "isAvailable": this.isSunAvailable,
                        "startTimeSlotOne": this.sunHrsStartOne,
                        "endTimeSlotOne": this.sunHrsEndOne,
                        "startTimeSlotTwo": this.sunHrsStartTwo,
                        "endTimeSlotTwo": this.sunHrsEndTwo
                    },
                    {
                        "weekDay": "Monday",
                        "isAvailable": this.isMonAvailable,
                        "startTimeSlotOne": this.monHrsStartOne,
                        "endTimeSlotOne": this.monHrsEndOne,
                        "startTimeSlotTwo": this.monHrsStartTwo,
                        "endTimeSlotTwo": this.monHrsEndTwo
                    },
                    {
                        "weekDay": "Tuesday",
                        "isAvailable": this.isTueAvailable,
                        "startTimeSlotOne": this.tueHrsStartOne,
                        "endTimeSlotOne": this.tueHrsEndOne,
                        "startTimeSlotTwo": this.tueHrsStartTwo,
                        "endTimeSlotTwo": this.tueHrsEndTwo
                    },
                    {
                        "weekDay": "Wednesday",
                        "isAvailable": this.isWedAvailable,
                        "startTimeSlotOne": this.wedHrsStartOne,
                        "endTimeSlotOne": this.wedHrsEndOne,
                        "startTimeSlotTwo": this.wedHrsStartTwo,
                        "endTimeSlotTwo": this.wedHrsEndTwo
                    },
                    {
                        "weekDay": "Thursday",
                        "isAvailable": this.isThurAvailable,
                        "startTimeSlotOne": this.thurHrsStartOne,
                        "endTimeSlotOne": this.thurHrsEndOne,
                        "startTimeSlotTwo": this.thurHrsStartTwo,
                        "endTimeSlotTwo": this.thurHrsEndTwo
                    },
                    {
                        "weekDay": "Friday",
                        "isAvailable": this.isFriAvailable,
                        "startTimeSlotOne": this.friHrsStartOne,
                        "endTimeSlotOne": this.friHrsEndOne,
                        "startTimeSlotTwo": this.friHrsStartTwo,
                        "endTimeSlotTwo": this.friHrsEndTwo
                    },
                ]
            }
            // request header
            let requestHeader = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.VUE_APP_TOKEN}`,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Headers": "*",
                'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            }

            // send request
            axios
            .post(url, dataToSubmit, {
                headers: requestHeader
            })
            .then((data) => {
                if (data.status == 200) {
                    this.showLoading = false
                    this.$toastr.s("Successfully Created.", "Success");
                    setTimeout(()=> {window.location = '/admin/booking'}, 1000)
                }
            })
        },
        uploadLogo() {
            this.showLoading = true
            const dataToSubmit = new FormData()
            dataToSubmit.append('file', this.logo || '')
            // request url
            let url = process.env.VUE_APP_API_URL+'/api/business/upload'

            // send request
            axios
                .post(url, dataToSubmit, {
                headers: this.requestHeader
                })
                .then((data) => {
                    if (data.status == 200) {
                        this.imgUrl = data.data.imgUrl
                        this.imgUrlPublicId = data.data.publicId
                        this.submit()
                    }
                })
        }
    }
}
</script>

<style scoped>
.content{padding: 10px 0}

.line {
    position: relative;
    width: 100%;
    height: 0px;
    top: 25px;

    border: 1px solid #FFE9FF;
}

.uploadDiv {
  position: relative;
  overflow: hidden;
  color: black;
  border: none;
}
.uploadBtn {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}

.submitBtn {
    background-color: #E317E3;
    color: white;
    border-color:  #E317E3;
}

.requiredCls {
    color: red;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  height: 26px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 6px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.weekDays{margin: 1rem 0;}
.weekDays > .col-md-6:nth-child(1){margin-top: 1.1rem;}
.openClose{padding-left: 10px;}
/* .hrs-from, .hrs-to{border:unset !important; border-bottom: 1px solid !important; border-radius: unset !important;} */
.secondOpenClose{margin-top: .5rem}
.ant-time-picker{width: unset !important}
.addHrs{cursor: pointer; color: #2196F3;}
.removeHrs{cursor: pointer; color: red;}
</style>
