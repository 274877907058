<template>
  <div class="wrapper">
    <side-bar>
      <mobile-menu slot="content"></mobile-menu>

      <ul style="margin-top: 21px; list-style-type: none; cursor: pointer; padding-inline-start: 30px !important;" v-if="ownerMgmt">
        <li @click="ownerManagement()">
          <img src="/img/clock.png" alt="" style="padding-bottom: 4px; padding-right: 10px;">
          <b>Owner Management</b> <i class="fa fa-chevron-up" style="float: right; padding-right: 15%" ></i>
        </li>

        <sidebar-link to="/admin/create-owner">
          Create Owner
        </sidebar-link>
        <sidebar-link to="/admin/owner-list">
          Owner List
        </sidebar-link>
        <sidebar-link to="/admin/menu-management" class="notClickable" v-if="isMenuManagement">
            <img src="/img/menu.png" alt="" style="padding-bottom: 4px; padding-right: 10px;">
            <p>Menu Management</p>
        </sidebar-link>
<!--        <sidebar-link to="#">-->
<!--          Owner List-->
<!--        </sidebar-link>-->
      </ul>

      <ul style="margin-top: 21px; list-style-type: none; cursor: pointer; padding-inline-start: 30px !important;" v-if="!ownerMgmt">
        <li @click="ownerManagement()">
          <img src="/img/clock.png" alt="" style="padding-bottom: 4px; padding-right: 10px;">
          <b>Owner Management</b><i class="fa fa-chevron-down" style="float: right; padding-right: 15%" ></i>
        </li>
      </ul>
      <!-- <sidebar-link to="/admin/menu-management" class="notClickable">
        <img src="/img/menu.png" alt="" style="padding-bottom: 4px; padding-right: 10px;">
        <p>Menu Management</p>
      </sidebar-link> -->
      <sidebar-link to="/admin/report">
        <img src="/img/report.png" alt="" style="padding-bottom: 4px; padding-right: 10px;">
        <p>Report</p>
      </sidebar-link>
      <sidebar-link to="/admin/subscription">
        <img src="/img/subcription.png" alt="" style="padding-bottom: 4px; padding-right: 10px;">
        <p>Subscription</p>
      </sidebar-link>
      <!-- <sidebar-link to="/admin/booking">
        <img src="/img/subcription.png" alt="" style="padding-bottom: 4px; padding-right: 10px;">
        <p>Booking</p>
      </sidebar-link> -->
      <!-- <sidebar-link to="/admin/hook-items">
        <i class="nc-icon nc-notes"></i>
        <p>Hook Item Set</p>
      </sidebar-link> -->
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click="toggleSidebar">

      </dashboard-content>

      <!-- <content-footer></content-footer> -->
    </div>
  </div>
</template>
<script>
  import TopNavbar from './TopNavbar.vue'
  import ContentFooter from './ContentFooter.vue'
  import DashboardContent from './Content.vue'
  import MobileMenu from './MobileMenu.vue'
  export default {
    components: {
      TopNavbar,
      ContentFooter,
      DashboardContent,
      MobileMenu
    },
    data () {
      return {
        ownerMgmt: false,
        isMenuManagement: false
      }
    },
    methods: {
      ownerManagement () {
        console.log('ok')
        this.ownerMgmt = !this.ownerMgmt
      },
      toggleSidebar () {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      }
    },
    watch: {
      $route (to, form) {
        // console.log(to.name)
        // console.log(to.path)
        // console.log(form)

        if (to.name == 'MenuManagement' && to.path == '/admin/menu-management') {
          this.isMenuManagement = true
        }
        else {
          this.isMenuManagement = false
        }
      }
    }
  }

</script>
<style>
.notClickable > a {
    pointer-events: none;
    cursor: default;
}
</style>
<style scoped>
.wrapper{background-color: #E5E5E5;}
.main-panel{background-color: #E5E5E5}


</style>
