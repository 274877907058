<template>

  <div class="content">
    <div class="container" style="padding-left: 10px">
      <div class="row">

        <div class="col-md-12" >
          <content-header>
            <p class="mb-0" style="color: #FE750F;">Update Web Content</p>
          </content-header>

          <div class="" style="background-color: #FFFFFF;padding-top: 2%">
            <h2 class="text-center mt-0" style="border-bottom: 2px solid;">Image</h2>
            <!--header one-->
            <div style="background: rgb(209 201 218)">
              <h3 class="pl-3">Header One</h3>
              <div class="row">
                <div v-for="(item,i) in headerOneImages"
                     :key="i"
                     class="col-md-4 text-center my-2">
                  <img :src="item.src" width="300" alt="Image" class="header-image">
                  <i class="fa fa-times position-absolute header-delete"
                     @click="deleteImage(item.publicId, headerTypeOne)" aria-hidden="true"></i>
                </div>
                <div class="col-md-12">
                  <form @submit.prevent="submitHeaderOne">
                    <div class="row">
                      <div class="col-md-4 text-center p-4">
                        <img src="/img/upload.png" alt=""> Upload New Image
                        <input type="file" @change="headerOneImageSelected" accept="image/*" class="uploadBtn"/>
                      </div>
                      <div class="col-md-4 text-left p-4">
                        <button class="btn submitBtn">Upload</button>
                        <img v-if="showHeaderOneLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!--header two-->
            <div style="background: rgb(214 243 219)">
              <h3 class="pl-3 mt-4">Header Two</h3>
              <div class="row">
                <div v-for="(item,i) in headerTwoImages"
                     :key="i"
                     class="col-md-4 text-center my-2">
                  <img :src="item.src" width="300" alt="Image" class="header-image">
                  <i class="fa fa-times position-absolute header-delete"
                     @click="deleteImage(item.publicId, headerTypeTwo)" aria-hidden="true"></i>
                </div>
                <div class="col-md-12">
                  <form @submit.prevent="submitHeaderTwo">
                    <div class="row">
                      <div class="col-md-4 text-center p-4">
                        <img src="/img/upload.png" alt=""> Upload New Image
                        <input type="file" @change="headerTwoImageSelected" accept="image/*" class="uploadBtn"/>
                      </div>
                      <div class="col-md-4 text-left p-4">
                        <button class="btn submitBtn">Upload</button>
                        <img v-if="showHeaderTwoLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
                      </div>
                    </div>
                  </form>
                </div>

              </div>
            </div>
            <!--header three-->
            <div style="background: rgb(224 208 222)">
              <h3 class="pl-3 mt-4">Header Three</h3>
              <div class="row px-2">
              <div v-for="(item,i) in headerThreeImages"
                   :key="i"
                   class="col-md-3 text-center my-2">
                <img :src="item.src" width="100%" alt="Image" class="header-image">
                <i class="fa fa-times position-absolute header-delete text-right"
                   @click="deleteImage(item.publicId, headerTypeThree)" aria-hidden="true"></i>
              </div>
              <div class="col-md-12">
                <form @submit.prevent="submitHeaderThree">
                  <div class="row">
                    <div class="col-md-4 text-center p-4">
                      <img src="/img/upload.png" alt=""> Upload New Image
                      <input type="file" @change="headerThreeImageSelected" accept="image/*" class="uploadBtn"/>
                    </div>
                    <div class="col-md-4 text-left p-4">
                      <button class="btn submitBtn">Upload</button>
                      <img v-if="showHeaderThreeLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
                    </div>
                  </div>
                </form>
              </div>

            </div>
            </div>

            <h2 class="text-center mt-5" style="border-bottom: 2px solid;">Content</h2>
            <h3 class="content-heading">Hours</h3>
            <div class="row pl-5">
              <div class="col-md-2" style="max-width: ">
                <input type="text" class="form-control" v-model="openingHrs" placeholder="00:00">
              </div>-
              <div class="col-md-2">
                <input type="text" class="form-control" v-model="closingHrs" placeholder="00:00">
              </div>
            </div>
            <h3 class="content-heading">Latitude/Longitude</h3>
            <div class="row pl-5">
              <div class="col-md-2" style="max-width: ">
                <input type="text" class="form-control" v-model="latitude" placeholder="latitude">
              </div>
              <div class="col-md-2">
                <input type="text" class="form-control" v-model="longitude" placeholder="longitude">
              </div>
            </div>
            <h3 class="content-heading">Stripe keys</h3>
            <div class="row pl-5">
              <div class="col-md-12">
                <label for="">Secret key</label>
                <input type="text" class="form-control" v-model="stripeSecretKey" placeholder="Secret key">
              </div>
              <div class="col-md-12 mt-3">
                <label for="">Publish key</label>
                <input type="text" class="form-control" v-model="stripePublishKey" placeholder="Publish key">
              </div>
            </div>
            <h3 class="content-heading">Colors</h3>
            <div class="row pl-5">
              <div class="col-md-12">
                <label for="">Header</label>
                <input type="text" class="form-control" v-model="headerColor" placeholder="Header color">
              </div>
              <div class="col-md-12 mt-3">
                <label for="">Footer</label>
                <input type="text" class="form-control" v-model="footerColor" placeholder="Footer color">
              </div>
            </div>
            <h3 class="content-heading">About Us</h3>
            <div class="row">
              <div class="col-md-12 px-5">
                <vue-editor id="aboutUs" v-model="aboutUs" />
              </div>
            </div>
            <h3 class="content-heading mt-5">Welcome Message</h3>
            <div class="row">
              <div class="col-md-12 px-5">
                <vue-editor v-model="welcomeMsg" />
              </div>
            </div>
            <h3 class="content-heading mt-5">Privacy Policy</h3>
            <div class="row">
              <div class="col-md-12 px-5">
                <vue-editor v-model="privacyPolicy" />
              </div>
            </div>
            <h3 class="content-heading mt-5">Terms & Condition</h3>
            <div class="row">
              <div class="col-md-12 px-5">
                <vue-editor v-model="termsCondition" />
              </div>
            </div>
            <h3 class="content-heading mt-5">Allergy Information</h3>
            <div class="row">
              <div class="col-md-12 px-5">
                <vue-editor v-model="allergyInformation" />
              </div>
            </div>

            <div class="col-md-12 p-5">
              <button class="btn submitBtn" @click="updateWebConfig">Upload</button>
              <img v-if="showWebConfigLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import ContentHeader from "@/components/ContentHeader"
import axios from 'axios'
import logout from './../../mixins/logoutMixins.js'

export default {
  components: {
    ContentHeader,
  },
  data () {
    return {
      headerOne: {
        image: null,
      },
      headerTwo: {
        image: null,
      },
      headerThree: {
        image: null,
      },
      headerOneImages: [],
      headerTwoImages: [],
      headerThreeImages: [],
      openingHrs: '',
      closingHrs: '',
      latitude: '',
      longitude: '',
      stripeSecretKey: '',
      stripePublishKey: '',
      headerColor: '',
      footerColor: '',
      aboutUs: '',
      welcomeMsg: '',
      privacyPolicy: '',
      termsCondition: '',
      allergyInformation: '',
      requestHeader: null,
      headerTypeOne: 'HeaderOne',
      headerTypeTwo: 'HeaderTwo',
      headerTypeThree: 'HeaderThree',
      showHeaderOneLoading: false,
      showHeaderTwoLoading: false,
      showHeaderThreeLoading: false,
      showWebConfigLoading: false,
      jwtToken: ''
    }
  },
  created() {
    this.jwtToken = this.$cookies.get('token') || null
    this.webConfig()
  },
  methods: {
    headerOneImageSelected(e){
      this.headerOne.image = e.target.files[0]
    },
    headerTwoImageSelected(e){
      this.headerTwo.image = e.target.files[0]
    },
    headerThreeImageSelected(e){
      this.headerThree.image = e.target.files[0]
    },
    submitHeaderOne() {
      this.showHeaderOneLoading = true

      const dataToSubmit = new FormData()
      dataToSubmit.append('file', this.headerOne.image || '')
      dataToSubmit.append('imageType', this.headerTypeOne)
      dataToSubmit.append('restaurantId', localStorage.getItem('restaurantId') || '')
      // request url
      let url = process.env.VUE_APP_API_URL+'/api/webConfig/upload'

      // send request
      axios
        .post(url, dataToSubmit, {
          headers: this.requestHeader
        })
        .then((data) => {
          if (data.status == 200) {
            this.showHeaderOneLoading = false
            this.$toastr.s("Successfully Created.", "Success");
            setTimeout(()=> {this.$router.go()}, 1000)
          }
        })
    },
    submitHeaderTwo() {
      this.showHeaderTwoLoading = true
      const dataToSubmit = new FormData()
      dataToSubmit.append('file', this.headerTwo.image || '')
      dataToSubmit.append('imageType', this.headerTypeTwo)
      dataToSubmit.append('restaurantId', localStorage.getItem('restaurantId') || '')
      // request url
      let url = process.env.VUE_APP_API_URL+'/api/webConfig/upload'

      // send request
      axios
        .post(url, dataToSubmit, {
          headers: this.requestHeader
        })
        .then((data) => {
          if (data.status == 200) {
            this.showHeaderTwoLoading = false
            this.$toastr.s("Successfully Created.", "Success");
            setTimeout(()=> {this.$router.go()}, 1000)
          }
        })
    },
    submitHeaderThree() {
      this.showHeaderThreeLoading = true
      const dataToSubmit = new FormData()
      dataToSubmit.append('file', this.headerThree.image || '')
      dataToSubmit.append('imageType', this.headerTypeThree)
      dataToSubmit.append('restaurantId', localStorage.getItem('restaurantId') || '')
      // request url
      let url = process.env.VUE_APP_API_URL+'/api/webConfig/upload'

      // send request
      axios
        .post(url, dataToSubmit, {
          headers: this.requestHeader
        })
        .then((data) => {
          if (data.status == 200) {
            this.showHeaderThreeLoading = false
            this.$toastr.s("Successfully Created.", "Success");
            setTimeout(()=> {this.$router.go()}, 1000)
          }
        })
    },
    updateWebConfig(){
      this.showWebConfigLoading = true

      // request data
      let dataToSubmit = {
        "restaurantId": localStorage.getItem('restaurantId'),
        "welcomeTxt": this.welcomeMsg,
        "aboutUs": this.aboutUs,
        "privacyPolicy": this.privacyPolicy,
        "termsCondition": this.termsCondition,
        "lat": this.latitude,
        "long": this.longitude,
        "stripeSecretKey": this.stripeSecretKey,
        "stripePublishKey": this.stripePublishKey,
        "headerColor": this.headerColor,
        "footerColor": this.footerColor,
        "openingTime": this.openingHrs,
        "closingTime": this.closingHrs,
        "allergyInformation": this.allergyInformation
      }
      // request url
      let url = process.env.VUE_APP_API_URL+'/api/webConfig/update'

      // send request
      axios
        .post(url, dataToSubmit, {
          headers: this.requestHeader
        })
        .then((data) => {
          if (data.status == 200) {
            this.showWebConfigLoading = false
            this.$toastr.s("Successfully Updated.", "Success");
            setTimeout(()=> {this.$router.go()}, 1000)
          }
        })
    },
    deleteImage(publicId, headerType){
      if (confirm('Please confirm to delete this image.')){
        // request url
        let url = process.env.VUE_APP_API_URL+'/api/webConfig/deleteImage?publicId='+publicId+'&restaurantId='+parseInt(localStorage.getItem('restaurantId'))+'&imageType='+headerType

        // send request
        axios
          .post(url, {
            headers: this.requestHeader
          })
          .then((data) => {
            if (data.status == 200) {
              this.$toastr.s("Successfully Deleted.", "Success");
              setTimeout(()=> {this.$router.go()}, 1000)
            }
          })
      }
    },
    webConfig(){
      // request header
      this.requestHeader = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.jwtToken}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Headers": "*",
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      }
      // request url
      let url = process.env.VUE_APP_API_URL+'/api/webConfig?restaurantId='+localStorage.getItem('restaurantId')
      // send request
      axios
        .get(url,
          {
            headers: {
              Authorization: `Bearer ${this.jwtToken}`,
            }
          })
        .then((result) => {
          if (result.status === 403) {
              let logoutResponse = this.logout()
              if (logoutResponse === 1) {
                  this.$router.push({ path: '/login' })
              }
          }
          const data = result.data
          if (typeof data.headerOneImages != 'undefined'){
            for (var i=0; i<data.headerOneImages.length; i++){
              this.headerOneImages.push({'src': data.headerOneImages[i].url, 'publicId': data.headerOneImages[i].publicId})
            }
          }
          if (typeof data.headerTwoImages != 'undefined') {
            for (var j=0; j<data.headerTwoImages.length; j++){
              this.headerTwoImages.push({'src': data.headerTwoImages[j].url, 'publicId': data.headerTwoImages[j].publicId})
            }
          }
          if (typeof data.headerThreeImages != 'undefined') {
            for (var k=0; k<data.headerThreeImages.length; k++){
              this.headerThreeImages.push({'src': data.headerThreeImages[k].url, 'publicId': data.headerThreeImages[k].publicId})
            }
          }
          this.openingHrs = data.openingTime
          this.closingHrs = data.closingTime
          this.latitude = data.lat
          this.longitude = data.long
          this.headerColor = data.headerColor
          this.footerColor = data.footerColor
          this.aboutUs = data.aboutUs
          this.welcomeMsg = data.welcomeTxt
          this.privacyPolicy = data.privacyPolicy
          this.termsCondition = data.termsCondition
          this.allergyInformation = data.allergyInformation
          this.stripeSecretKey = data.stripeSecretKey
          this.stripePublishKey = data.stripePublishKey
        })
    }
  }
}
</script>

<style scoped>
.uploadBtn {
  cursor: pointer;
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}
.submitBtn {
  background-color: #E317E3;
  color: white;
  border-color:  #E317E3;
}
.content-heading{padding-left: 2rem; color: #38ACE2}
.header-delete{width: 40px; height:40px; top: 0; cursor:pointer; font-size: 1.5rem;right: 20px; color: red;}
</style>
