<template>
    <div>
        <div class="modal-vue">

            <!-- overlay -->
            <div class="overlay" v-if="showCategoryItemModal" @click="showCategoryItemModal = false"></div>

            <!-- modal -->
            <div class="modal-own" v-if="showCategoryItemModal">
                <h5>{{modalTitle}}</h5>
                <form>
                    <div class="form-group" v-if="!isToppingsModal">
                        <label for="exampleInputEmail1">Title</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" v-model="title" aria-describedby="emailHelp" placeholder="Enter title">
                    </div>
                    <div class="form-group" v-if="!isToppingsModal">
                        <label for="exampleFormControlTextarea1" >Description</label>
                        <textarea class="form-control" id="exampleFormControlTextarea1" v-model="description"></textarea>
                    </div>
                    <div class="form-group" v-if="(isSelectionModal || isItemModal) && !isToppingsModal">
                        <label for="exampleInputEmail1">Base Price</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" v-model="basePrice" aria-describedby="emailHelp" placeholder="Enter Price">
                    </div>

                  <div class="row" v-if="(isSelectionModal || isItemModal) && !isToppingsModal">
                    <div class="col-md-4 py-4">
                        <input type="checkbox" v-model="isSetDiffPrice" @click="setDiffPrice()"> Different price
                    </div>
                    <div class="col-md-4">
                      <div v-if="isSetDiffPrice" class="form-group">
                        <label>In price</label>
                        <input type="text" class="form-control" v-model="inPrice" placeholder="In price">
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div v-if="isSetDiffPrice" class="form-group">
                        <label>Out price</label>
                        <input type="text" class="form-control" v-model="outPrice" placeholder="Out price">
                      </div>
                    </div>
                  </div>

                    <div class="row" v-if="isCategoryModal && !isToppingsModal">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="exampleFormControlSelect1">Send Terminal</label>
                                <select class="form-control" id="exampleFormControlSelect1" v-model="terminalSelected">
                                    <option v-for="(item, index) in assignOption" :key="index" :value="item.identity"> {{item.name}} </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="exampleFormControlSelect1">Send Status</label>
                                <select class="form-control" id="exampleFormControlSelect1" v-model="sendStatusSelected">
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="!isToppingsModal">
                        <div class="col-md-4">
                            <div class="form-group" v-if="isItemModal">
                                <label for="exampleFormControlSelect1">Print Option</label>
                                <select class="form-control" id="exampleFormControlSelect1" @change="chooseItems()" v-model="printIdSelected">
                                    <option v-for="(item, index) in printOptionDataList" :key="index" :value="item.id"> {{item.title}} </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group" v-if="isItemModal || isSelectionModal">
                                <label for="exampleFormControlSelect1" >Assign to</label>
                                <select class="form-control" id="exampleFormControlSelect1" v-model="assignSelected">
                                    <option v-for="(item, index) in assignOption" :key="index" :value="item.identity"> {{item.name}} </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group" v-if="isItemModal || isSelectionModal">
                                <label for="exampleFormControlSelect1">Status</label>
                                <select class="form-control" id="exampleFormControlSelect1" v-model="statusSelected">
                                    <option>Active</option>
                                    <option>Inactive</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row" v-if="isToppingsModal">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="exampleFormControlSelect1">Category</label>
                                <input type="text" class="form-control" v-model="toppingsCategory">
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Toppings Title</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1" v-model="toppingsTitle" aria-describedby="emailHelp" placeholder="Enter title">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Base Price</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1" v-model="toppingsBasePrice" aria-describedby="emailHelp" placeholder="Enter price">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button type="button" class="btn submitBtn" v-if="!isToppingsModal && !isCategoryModal && !isItemModal && !isSelectionModal">Submit</button>&nbsp;
                    <button type="button" class="btn submitBtn" v-if="isCategoryModal || isItemModal || isSelectionModal" @click="createCategory()">Submit</button>&nbsp;
                    <button type="button" class="btn submitBtn" v-if="isToppingsModal" @click="createToppings()">Submit</button>&nbsp;
                    <button type="button" class="btn cancelBtn" @click="cancelCustomModal()">Cancel</button>
                </form>
            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios'
import logout from './../../mixins/logoutMixins.js'

export default {
    props: ['modalTitle', 'isCategoryModal', 'isItemModal', 'isSelectionModal', 'isToppingsModal', 'categoryDataList', 'itemDataList', 'printOptionDataList'],
    data () {
        return {
            showCategoryItemModal: false,
            title: '',
            description: '',
            basePrice: 0,
            toppingsCategory: '',
            toppingsTitle: '',
            toppingsBasePrice: '',
            toppingsEditId: '',
            isToppingsModalEditable: true,
            restaurantId: parseInt(localStorage.getItem('restaurantId')),
            categoryIdSelected: 0,
            itemIdSelected: 0,
            itemAccordingToCategory: [],
            editCategoryId: 0,
            editItemId: 0,
            assignOption: [
                {
                    'identity': 'Kitchen',
                    'name': 'Kitchen'
                },
                {
                    'identity': 'Express',
                    'name': 'Express'
                }
            ],
            assignSelected: '',
            terminalSelected: '',
            addFixedMealDeal: false,
            addCustomMealDeal: false,
            addGeneralMealDeal: false,
            printIdSelected: 0,
            statusSelected: '',
            sendStatusSelected: '',
            isSetDiffPrice: false,
            inPrice: '',
            inPriceG: '',
            outPrice: '',
            outPriceG: '',
            jwtToken: this.$cookies.get('token') || null
        }
    },
    methods: {
        chooseItems () {
            console.log(this.categoryIdSelected);

            this.itemAccordingToCategory = []

            for (var i = 0; i < this.itemDataList.length; i++) {
                if (this.itemDataList[i].categoryId == this.categoryIdSelected) {
                    let itemObj = {id: this.itemDataList[i].id, label: this.itemDataList[i].label}

                    this.itemAccordingToCategory.push(itemObj)
                }
            }
        },
        cancelCustomModal () {
            this.showCategoryItemModal = false

            // if user cancel the toppings.
            this.isToppingsModalEditable = false

            this.toppingsEditId = ''
            this.toppingsTitle = ''
            this.toppingsCategory = ''
            this.toppingsBasePrice = ''

            // if user cancel the category/item/selection
            this.title = ''
            this.description = ''
            this.basePrice = ''
            this.categoryIdSelected = 0
            this.itemIdSelected = 0

            this.editCategoryId = 0
            this.editItemId = 0

            this.statusSelected = ''
            this.sendStatusSelected = ''
            this.printIdSelected = ''
            this.assignSelected = ''
            this.terminalSelected = ''
        },
        editToppingsInfo () {
            let editableToppingsData = localStorage.getItem('editableToppingsData')

            editableToppingsData = JSON.parse(editableToppingsData)

            this.isToppingsModalEditable = true

            this.toppingsEditId = editableToppingsData.id
            this.toppingsTitle = editableToppingsData.title
            this.toppingsCategory = editableToppingsData.category
            this.toppingsBasePrice = editableToppingsData.price
        },
        categoryItemModal(general, fixed, custom) {
            this.showCategoryItemModal = true

            this.addFixedMealDeal = fixed
            this.addCustomMealDeal = custom
            this.addGeneralMealDeal = general
        },
        editCategoryInfo (val, title, description, basePrice, priceDifference, inPrice, outPrice, categoryId, itemId, general, fixed, custom, terminal, print, assign, status) {
            this.itemAccordingToCategory = []
          console.log(typeof priceDifference)
            this.editCategoryId = val
            this.title = title
            this.description = description
            this.basePrice = basePrice == '' ? 0 : basePrice
            this.categoryIdSelected = categoryId

            this.addFixedMealDeal = fixed
            this.addCustomMealDeal = custom
            this.addGeneralMealDeal = general

            this.isSetDiffPrice = priceDifference == 'Yes' ? true : false
            this.inPrice = inPrice
            this.outPrice = outPrice
            this.inPriceG = inPrice
            this.outPriceG = outPrice

            this.statusSelected = status
            this.sendStatusSelected = status
            this.printIdSelected = print
            this.assignSelected = assign
            this.terminalSelected = terminal

            console.log('statusSelected : ' + status + ' | sendStatusSelected : ' + status + ' | printIdSelected : ' + print + ' | assignSelected : ' + assign + ' | terminal : ' + terminal)

            if (itemId > 0) {
                for (var i = 0; i < this.itemDataList.length; i++) {
                    if (this.itemDataList[i].categoryId == categoryId) {
                        let itemObj = {id: this.itemDataList[i].id, label: this.itemDataList[i].label}

                        this.itemAccordingToCategory.push(itemObj)
                    }
                }

                setTimeout(() => {
                    this.itemIdSelected = itemId
                    this.editItemId = itemId
                }, 500)
            }
        },
        selctedCategory (val) {
            this.categoryIdSelected = val
        },
        createCategory () {
            console.log('this.categoryIdSelected : '+this.categoryIdSelected)
            console.log('this.itemIdSelected : '+this.itemIdSelected)
            console.log('this.assignSelected : '+this.assignSelected)

            let url = process.env.VUE_APP_API_URL+`/api/menu/create`
            let editUrl = process.env.VUE_APP_API_URL+`/api/menu/update`

            // request header
            let requestHeader = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.jwtToken}`,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Headers": "*",
                'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            }

            // create category
            if (this.isCategoryModal == true) {
                if (this.editCategoryId == 0) {
                    let dataToSubmit = {
                        // "id": 2,
                        "restaurantId": this.restaurantId,
                        "label": this.title,
                        "resourceRef": "",
                        "refCode": 0,
                        "imgURL": "",
                        "iconURL": "",
                        "description": this.description,
                        "categoryId": 0,
                        "itemId": 0,
                        "selectionId": 0,
                        "toppingsId": 0,
                        "modifierId": 0,
                        "mealDealId": 0,
                        "setId": 0,
                        "printOptionId": 1,
                        "printOptionTitle": "",
                        "terminalOption": "",
                        "status": this.sendStatusSelected,
                        "basePrice": "",
                        "toppingsCategory": "",
                        "mealAssignFor": this.terminalSelected,
                        "toppingsCategoryId": 0,
                        "baseQty": 1,
                        "minQty": 0,
                        "maxQty": 0,
                        "dataType": "Category",
                        "mealDealType": "",
                        "displayOrder": 2,
                        "createdAt": "3 Sep 2020 11:05:30",
                        "updatedAt": "30 Sep 2020 11:05:30",
                        "children": []
                    }

                    axios
                    .post(url, dataToSubmit,
                    {
                        headers: requestHeader
                    })
                    .then((data)=> {
                        console.log(data);

                        // this.$emit("toppingsSubmitted")

                        if (data.status == 200) {

                          // this.showCategoryItemModal = true

                            this.title = ''
                            this.description = ''
                            this.terminalSelected = ''
                            this.sendStatusSelected = ''

                            this.$toastr.s("Category created Successfully", "Success");
                            console.log('categoryList calling..');
                            this.$emit('categoryAndItem')
                        }

                        if (data.status === 403) {
                            let logoutResponse = this.logout()
                            if (logoutResponse === 1) {
                                this.$router.push({ path: '/login' })
                            }
                        }
                    })
                }
                else {
                    let dataToSubmit = {
                        "id": this.editCategoryId,
                        "restaurantId": this.restaurantId,
                        "label": this.title,
                        "resourceRef": "",
                        "refCode": 0,
                        "imgURL": "",
                        "iconURL": "",
                        "description": this.description,
                        "categoryId": 0,
                        "itemId": 0,
                        "selectionId": 0,
                        "toppingsId": 0,
                        "modifierId": 0,
                        "mealDealId": 0,
                        "setId": 0,
                        "printOptionId": 1,
                        "printOptionTitle": "",
                        "terminalOption": "",
                        "status": this.sendStatusSelected,
                        "basePrice": "",
                        "toppingsCategory": "",
                        "mealAssignFor": this.terminalSelected,
                        "toppingsCategoryId": 0,
                        "baseQty": 1,
                        "minQty": 0,
                        "maxQty": 0,
                        "dataType": "Category",
                        "mealDealType": "",
                        "displayOrder": 2,
                        "createdAt": "3 Sep 2020 11:05:30",
                        "updatedAt": "30 Sep 2020 11:05:30",
                        "children": []
                    }

                    axios
                    .post(editUrl, dataToSubmit,
                    {
                        headers: requestHeader
                    })
                    .then((data)=> {
                        console.log(data);

                        // this.$emit("toppingsSubmitted")

                        if (data.status == 200) {
                            this.$toastr.s("Category edited Successfully", "Success");

                            this.showCategoryItemModal = false

                            this.title = ''
                            this.description = ''

                            console.log('categoryList calling..');
                            this.$emit('categoryAndItem')
                        }

                        if (data.status === 403) {
                            let logoutResponse = this.logout()
                            if (logoutResponse === 1) {
                                this.$router.push({ path: '/login' })
                            }
                        }
                    })
                }

            }

            // item create
            if (this.isItemModal) {
                if (this.editCategoryId == 0) {
                    let dataToSubmit = {
                        // "id": 2,
                        "restaurantId": this.restaurantId,
                        "label": this.title,
                        "resourceRef": "",
                        "refCode": 0,
                        "imgURL": "",
                        "iconURL": "",
                        "description": this.description,
                        "categoryId": localStorage.getItem('selectedCategoryId'),
                        "itemId": 0,
                        "selectionId": 0,
                        "toppingsId": 0,
                        "modifierId": 0,
                        "mealDealId": 0,
                        "setId": 0,
                        "printOptionId": this.printIdSelected,
                        "printOptionTitle": "",
                        "terminalOption": "",
                        "status": this.statusSelected,
                        "basePrice": this.basePrice,
                        "toppingsCategory": "",
                        "mealAssignFor": this.assignSelected,
                        "toppingsCategoryId": 0,
                        "baseQty": 1,
                        "minQty": 0,
                        "maxQty": 0,
                        "dataType":  this.addGeneralMealDeal ? "Item" : "MealDeal",
                        "mealDealType": this.addGeneralMealDeal ? "" : this.addFixedMealDeal ? "Fixed" : "Custom",
                        "displayOrder": 2,
                        "createdAt": "3 Sep 2020 11:05:30",
                        "updatedAt": "30 Sep 2020 11:05:30",
                        "priceDifference": this.isSetDiffPrice === true ? "Yes" : "No",
                        "inPrice": parseInt(this.inPrice),
                        "outPrice": parseInt(this.outPrice),
                        "children": []
                    }
                    axios
                    .post(url, dataToSubmit,
                    {
                        headers: requestHeader
                    })
                    .then((data)=> {
                        console.log(data);

                        // this.$emit("toppingsSubmitted")

                        if (data.status == 200) {

                          // this.showCategoryItemModal = false

                            this.title = ''
                            this.description = ''
                            this.printIdSelected = 0
                            this.statusSelected = ''
                            this.assignSelected = ''
                            this.categoryIdSelected = 0
                            this.isSetDiffPrice = false
                            this.inPrice = this.outPrice = ''

                            this.$toastr.s("Item created Successfully", "Success");
                            console.log('categoryList calling..');
                            this.$emit('categoryAndItem')
                        }

                        if (data.status === 403) {
                            let logoutResponse = this.logout()
                            if (logoutResponse === 1) {
                                this.$router.push({ path: '/login' })
                            }
                        }
                    })
                }
                else {
                    console.log(this.editCategoryId+' | '+this.restaurantId+' | '+this.title+' | '+this.description+' | '+this.categoryIdSelected+' | '+this.basePrice);
                    let dataToSubmit = {
                        "id": this.editCategoryId,
                        "restaurantId": this.restaurantId,
                        "label": this.title,
                        "resourceRef": "",
                        "refCode": 0,
                        "imgURL": "",
                        "iconURL": "",
                        "description": this.description,
                        "categoryId": localStorage.getItem('selectedCategoryId'),
                        "itemId": 0,
                        "selectionId": 0,
                        "toppingsId": 0,
                        "modifierId": 0,
                        "mealDealId": 0,
                        "setId": 0,
                        "printOptionId": this.printIdSelected,
                        "printOptionTitle": "",
                        "terminalOption": "",
                        "status": this.statusSelected,
                        "basePrice": parseFloat(this.basePrice),
                        "toppingsCategory": "",
                        "mealAssignFor": this.assignSelected,
                        "toppingsCategoryId": 0,
                        "baseQty": 1,
                        "minQty": 0,
                        "maxQty": 0,
                        "dataType":  this.addGeneralMealDeal ? "Item" : "MealDeal",
                        "mealDealType": this.addGeneralMealDeal ? "" : this.addFixedMealDeal ? "Fixed" : "Custom",
                        "displayOrder": 2,
                        "createdAt": "3 Sep 2020 11:05:30",
                        "updatedAt": "30 Sep 2020 11:05:30",
                        "priceDifference": this.isSetDiffPrice === true ? "Yes" : "No",
                        "inPrice": this.inPrice,
                        "outPrice": this.outPrice,
                        "children": []
                    }

                    axios
                    .post(editUrl, dataToSubmit,
                    {
                        headers: requestHeader
                    })
                    .then((data)=> {
                        console.log(data);

                        // this.$emit("toppingsSubmitted")

                        if (data.status == 200) {
                            this.$toastr.s("Item edited Successfully", "Success");

                            this.showCategoryItemModal = false

                            this.title = ''
                            this.description = ''
                            this.categoryIdSelected = 0
                            this.isSetDiffPrice = false
                            this.inPrice = this.outPrice = ''

                            console.log('categoryList calling..');
                            this.$emit('categoryAndItem')
                        }

                        if (data.status === 403) {
                            let logoutResponse = this.logout()
                            if (logoutResponse === 1) {
                                this.$router.push({ path: '/login' })
                            }
                        }
                    })
                }

            }

            // selection create
            if (this.isSelectionModal) {
                if (this.editCategoryId == 0 && this.editItemId == 0) {
                    let dataToSubmit = {
                        // "id": 2,
                        "restaurantId": this.restaurantId,
                        "label": this.title,
                        "resourceRef": "",
                        "refCode": 0,
                        "imgURL": "",
                        "iconURL": "",
                        "description": this.description,
                        "categoryId": localStorage.getItem('selectedCategoryId'),
                        "itemId": localStorage.getItem('selectedItemId'),
                        "selectionId": 0,
                        "toppingsId": 0,
                        "modifierId": 0,
                        "mealDealId": 0,
                        "setId": 0,
                        "printOptionId": 1,
                        "printOptionTitle": "",
                        "terminalOption": "",
                        "status": this.statusSelected,
                        "basePrice": this.basePrice,
                        "toppingsCategory": "",
                        "mealAssignFor": this.assignSelected,
                        "toppingsCategoryId": 0,
                        "baseQty": 1,
                        "minQty": 0,
                        "maxQty": 0,
                        "dataType": "Selection",
                        "mealDealType": "",
                        "displayOrder": 2,
                        "createdAt": "3 Sep 2020 11:05:30",
                        "updatedAt": "30 Sep 2020 11:05:30",
                        "priceDifference": this.isSetDiffPrice === true ? "Yes" : "No",
                        "inPrice": this.inPrice,
                        "outPrice": this.outPrice,
                        "children": []
                    }

                    axios
                    .post(url, dataToSubmit,
                    {
                        headers: requestHeader
                    })
                    .then((data)=> {
                        console.log(data);

                        // this.$emit("toppingsSubmitted")

                        if (data.status == 200) {
                            this.$toastr.s("Selection created Successfully", "Success");

                            // this.showCategoryItemModal = false
                            this.title = ''
                            this.description = ''
                            this.statusSelected = ''
                            this.assignSelected = ''
                            this.categoryIdSelected = 0
                            this.isSetDiffPrice = false
                            this.inPrice = this.outPrice = ''

                            console.log('categoryList calling..');
                            this.$emit('categoryAndItem')
                        }

                        if (data.status === 403) {
                            let logoutResponse = this.logout()
                            if (logoutResponse === 1) {
                                this.$router.push({ path: '/login' })
                            }
                        }
                    })
                }
                else {
                    let dataToSubmit = {
                        "id": this.editCategoryId,
                        "restaurantId": this.restaurantId,
                        "label": this.title,
                        "resourceRef": "",
                        "refCode": 0,
                        "imgURL": "",
                        "iconURL": "",
                        "description": this.description,
                        "categoryId": this.categoryIdSelected,
                        "itemId": this.itemIdSelected,
                        "selectionId": 0,
                        "toppingsId": 0,
                        "modifierId": 0,
                        "mealDealId": 0,
                        "setId": 0,
                        "printOptionId": 1,
                        "printOptionTitle": "",
                        "terminalOption": "",
                        "status": this.statusSelected,
                        "basePrice": this.basePrice,
                        "toppingsCategory": "",
                        "mealAssignFor": this.assignSelected,
                        "toppingsCategoryId": 0,
                        "baseQty": 1,
                        "minQty": 0,
                        "maxQty": 0,
                        "dataType": "Selection",
                        "mealDealType": "",
                        "displayOrder": 2,
                        "createdAt": "3 Sep 2020 11:05:30",
                        "updatedAt": "30 Sep 2020 11:05:30",
                        "priceDifference": this.isSetDiffPrice === true ? "Yes" : "No",
                        "inPrice": this.inPrice,
                        "outPrice": this.outPrice,
                        "children": []
                    }

                    axios
                    .post(editUrl, dataToSubmit,
                    {
                        headers: requestHeader
                    })
                    .then((data)=> {
                        console.log(data);

                        // this.$emit("toppingsSubmitted")

                        if (data.status == 200) {
                            this.$toastr.s("Selection edited Successfully", "Success");

                            this.showCategoryItemModal = false

                            this.title = ''
                            this.description = ''
                            this.categoryIdSelected = 0
                            this.isSetDiffPrice = false
                            this.inPrice = this.outPrice = ''

                            console.log('categoryList calling..');
                            this.$emit('categoryAndItem')
                        }

                        if (data.status === 403) {
                            let logoutResponse = this.logout()
                            if (logoutResponse === 1) {
                                this.$router.push({ path: '/login' })
                            }
                        }
                    })
                }

            }

            setTimeout(() => {
                this.itemAccordingToCategory = []

                this.editCategoryId = 0
                this.editItemId = 0
                this.title = ''
                this.description = ''
                this.basePrice = 0
                this.categoryIdSelected = 0
                this.itemIdSelected = 0
            }, 500)
        },
        createToppings () {

            let url = process.env.VUE_APP_API_URL+`/api/topping/create`
            let editUrl = process.env.VUE_APP_API_URL+`/api/topping/update`

            // request header
            let requestHeader = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.jwtToken}`,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Headers": "*",
                'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            }

            if (this.isToppingsModalEditable) {
                let dataToSubmit = {
                    "id": this.toppingsEditId,
                    "restaurantId": this.restaurantId,
                    "label": this.toppingsTitle,
                    "resourceRef": "toppings-1",
                    "refCode": 3000,
                    "imgURL": "",
                    "iconURL": "",
                    "description": "",
                    "categoryId": 0,
                    "itemId": 0,
                    "selectionId": 0,
                    "toppingsId": 3000,
                    "modifierId": 0,
                    "mealDealId": 0,
                    "setId": 0,
                    "printOptionId": 1,
                    "printOptionTitle": "",
                    "terminalOption": "",
                    "status": "Active",
                    "basePrice": this.toppingsBasePrice,
                    "toppingsCategory": this.toppingsCategory,
                    "mealAssignFor": "",
                    // "toppingsCategoryId": 1,
                    "baseQty": 1,
                    "minQty": 0,
                    "maxQty": 0,
                    "dataType": "Toppings",
                    "mealDealType": "",
                    "displayOrder": 1,
                    "createdAt": "3 Sep 2020 11:05:30",
                    "updatedAt": "30 Sep 2020 11:05:30",
                    "children": []
                }

                axios
                .post(editUrl, dataToSubmit,
                {
                    headers: requestHeader
                })
                .then((data)=> {
                    console.log(data);

                    this.$emit("toppingsSubmitted")

                    if (data.status == 200) {
                        this.$toastr.s("Created Successfully", "Success");

                        this.showCategoryItemModal = false

                        this.isToppingsModalEditable = false

                        this.toppingsEditId = ''
                        this.toppingsTitle = ''
                        this.toppingsCategory = ''
                        this.toppingsBasePrice = ''

                        localStorage.removeItem('editableToppingsData')
                    }

                    if (data.status === 403) {
                        let logoutResponse = this.logout()
                        if (logoutResponse === 1) {
                            this.$router.push({ path: '/login' })
                        }
                    }
                })
            }
            else {
                let dataToSubmit = {
                    // "id": this.toppingsEditId,
                    "restaurantId": this.restaurantId,
                    "label": this.toppingsTitle,
                    "resourceRef": "toppings-1",
                    "refCode": 3000,
                    "imgURL": "",
                    "iconURL": "",
                    "description": "",
                    "categoryId": 0,
                    "itemId": 0,
                    "selectionId": 0,
                    "toppingsId": 3000,
                    "modifierId": 0,
                    "mealDealId": 0,
                    "setId": 0,
                    "printOptionId": 1,
                    "printOptionTitle": "",
                    "terminalOption": "",
                    "status": "Active",
                    "basePrice": this.toppingsBasePrice,
                    "toppingsCategory": this.toppingsCategory,
                    "mealAssignFor": "",
                    "toppingsCategoryId": 1,
                    "baseQty": 1,
                    "minQty": 0,
                    "maxQty": 0,
                    "dataType": "Toppings",
                    "mealDealType": "",
                    "displayOrder": 1,
                    "createdAt": "3 Sep 2020 11:05:30",
                    "updatedAt": "30 Sep 2020 11:05:30",
                    "children": []
                }

                axios
                .post(url, dataToSubmit,
                {
                    headers: requestHeader
                })
                .then((data)=> {
                    console.log(data);

                    this.$emit("toppingsSubmitted")

                    if (data.status == 200) {
                        this.$toastr.s("Created Successfully", "Success");

                        this.showCategoryItemModal = false

                        this.toppingsEditId = ''
                        this.toppingsTitle = ''
                        this.toppingsCategory = ''
                        this.toppingsBasePrice = ''
                    }

                    if (data.status === 403) {
                        let logoutResponse = this.logout()
                        if (logoutResponse === 1) {
                            this.$router.push({ path: '/login' })
                        }
                    }
                })
            }
        },
        setDiffPrice() {
          this.isSetDiffPrice = !this.isSetDiffPrice
          if (this.isSetDiffPrice === false) {
              this.inPrice = this.outPrice = '';
          }else{
              this.inPrice = this.inPriceG
              this.outPrice = this.outPriceG;
          }
        }
    }
}
</script>

<style scoped>
.modal-vue .overlay {
    position: absolute;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
}

.modal-vue .modal-own {
    position: absolute;
    width: 500px;
    z-index: 9999;
    margin: 0 auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 10px;
    top: 15%;
    left: 30%;
}

.modal-vue .close{
    position: absolute;
    top: 10px;
    right: 10px;
}

h5 {
    color: #FE7611 !important;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
}

.submitBtn {
    background-color: #E317E3;
    color: white;
    border-color:  #E317E3;
}

.cancelBtn {
    background-color: #797979;
    color: white;
    border-color:  #797979;
}
</style>
