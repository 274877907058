<template>
    <input class="px-6 py-1"
           autocomplete="off" type="search" name="search" :placeholder="placeholder"
           :value="value" @input="$emit('input', $event.target.value)">
</template>
<script>
export default {
  props: {
    value: String,
    placeholder: String,
    maxWidth: {
      type: Number,
      default: 300,
    },
  },
}
</script>
<style scoped>
input[type=search]{
  padding-left: 30px;
  background: #FFFFFF url(/img/search.png) no-repeat 9px center;
  background-size: 15px;
  border: 1px solid #BD31B5;
}
</style>
